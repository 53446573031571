import {
   addFileOrFolderToTree,
   removeNodeByPath,
   updateFileContentInTree,
   updateFileTreeStructure,
   updateTreeWithNewName,
} from '../../pages/VisionBoard/helpers/FileUpdater'

export const fileTreeReducer = (state, action) => {
   switch (action.type) {
      case 'UPDATE_FILE':
         const { filePath, fileContent } = action.payload
         return updateFileTreeStructure(state, filePath, fileContent)

      case 'CLEAR_TREE':
         return [] // Clear the tree by returning an empty array

      case 'RENAME_FILE_OR_FOLDER':
         const { currentNodePath, newFileName } = action.payload
         return updateTreeWithNewName(state, currentNodePath, newFileName)

      case 'REMOVE_NEW_FILE_OR_FOLDER':
         const { nodePathToRemove } = action.payload
         return removeNodeByPath(state, nodePathToRemove)
      case 'CHANGE_FILE_TREE':
         return action.payload

      case 'UPDATE_FILE_CONTENT':
         const { targetFilePath, newContent } = action.payload
         return updateFileContentInTree(state, targetFilePath, newContent)

      case 'ADD_FILE_OR_FOLDER':
         const { parentPath, newNode } = action.payload
         return addFileOrFolderToTree(state, parentPath, newNode)

      default:
         return state
   }
}
