import React, { useEffect, useState } from 'react'
import {
   Autocomplete,
   Button,
   CircularProgress,
   debounce,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Table,
   TableBody,
   TableCell,
   TableRow,
   TextField,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { AccountCircle, AddBoxOutlined, AddOutlined, CheckCircleOutline, DeleteOutline } from '@mui/icons-material'
import styled from 'styled-components'
import { UserRoleType } from '../TeamManagement'
import { ROLES } from './AddTeamDialog'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'
import { useUserApiClient } from '../../../Configs/API/createAxiosConfig'
import { clearUserSearch, dispatchSearchUsersData, setSearchUsersLoading } from '../../../Configs/ReduxSlice/usersSlice'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'

type viewUsersTenants = {
   open: boolean
   closeModal: any
   data: UserRoleType[]
   edit: boolean
   teamId: number
}

const ManageTeamUsers = (props: viewUsersTenants) => {
   const teneantAPIData = useAppSelector((state) => state.tenants.teneantAPIData)
   const searchUsersAPIData = useAppSelector((state) => state.users.searchUsers)
   const teamsService = useUserApiClient()
   const dispatch = useAppDispatch()

   const [usersData, setusersData] = useState([...props.data])
   const [apierror, setError] = useState<string | null>(null)

   const { user } = useAuth0()

   const handleUserRoleChange = (index: number, value: string) => {
      const newUserRoles: UserRoleType[] = usersData.map((user, ind) =>
         index === ind ? { ...user, role: value } : user
      )
      setusersData(newUserRoles)
   }

   const handleRemoveUserRole = (index: number) => {
      const newUserRoles = usersData.filter((_, i) => i !== index)
      setusersData(newUserRoles)
   }

   const handleAddUserRole = (e: any) => {
      setusersData([...usersData, { userName: '', role: 'VIEWER', userId: '', email: '', picture: '' }])
   }

   const sumbitUpdateUserToTeam = async () => {
      try {
         // setLoading(true)
         const response = await teamsService.addUserFromTeam(props.teamId, usersData)
         if (response.status == 200) {
            console.log('res', response.data)
            // dispatch(addUserFromTeam(response.data))
         } else {
            setError('Failed to save Team. Please try again later.')
         }
      } catch (err) {
         setError('Failed to save Team. Please try again later.')
      } finally {
         // setLoading(false)
      }
   }

   const handleSearchUsers = async (e: any) => {
      const searchText = String(e?.target?.value)
      if (searchText.length > 3) {
         searchUsers(searchText)
      }
   }

   const handleUserNameChange = (e: any, value: any, index: number) => {
      const newUserRoles: any[] = [...usersData]
      newUserRoles[index] = {
         userName: value.name,
         userId: value.userId,
         email: value.email,
         picture: value.picture,
         role: newUserRoles[index].role, // retain the role
      }
      setusersData(newUserRoles)
      dispatch(clearUserSearch(true))
   }

   const searchUsers = debounce(async (str: string) => {
      dispatch(setSearchUsersLoading(true))
      try {
         const response = await teamsService.searchUsers(str)
         if (response.status == 200) {
            dispatch(setSearchUsersLoading(false))
            dispatch(dispatchSearchUsersData(response.data))
         } else {
            setError('Failed to search users. Please try again later.')
         }
      } catch (err) {
         console.error('Error search users', err)
         setError('Failed to search users. Please try again later.')
      } finally {
         dispatch(setSearchUsersLoading(false))
      }
   }, 1000)

   return (
      <Dialog
         open={props.open}
         onClose={props.closeModal}
         PaperProps={{
            style: { minWidth: '650px', maxHeight: '70vh', borderRadius: 10, padding: '8px 1vw' },
            component: 'form',
         }}
         scroll={'paper'}
      >
         <DialogTitle>Users</DialogTitle>
         <DialogContent style={{ padding: '8px 2vw' }}>
            <Table aria-label="simple table">
               <TableBody>
                  {usersData?.map((item: UserRoleType, index) => (
                     <TableRow key={item.id}>
                        <TableCell align="left">
                           {item.picture ? (
                              <img
                                 src={item.picture}
                                 alt="User Picture"
                                 width="40"
                                 height="40"
                                 style={{ borderRadius: '50%' }}
                              />
                           ) : (
                              <AccountCircle sx={{ marginBottom: '-6px', width: '40px', height: '40px' }} />
                           )}
                        </TableCell>
                        <TableCell align="left">
                           <div>
                              {!item.userId ? (
                                 <Autocomplete
                                    id="asynchronous-demo"
                                    getOptionLabel={(option: any) => option?.name}
                                    onChange={(e, value) => handleUserNameChange(e, value, index)}
                                    options={searchUsersAPIData?.data}
                                    renderOption={(props, option) => (
                                       <li {...props}>
                                          <img
                                             src={option.picture}
                                             alt=""
                                             width="24"
                                             height="24"
                                             style={{ marginRight: '8px' }}
                                          />
                                          <div>
                                             <div>{option.name}</div>
                                             <div style={{ color: 'gray' }}>{option.email}</div>
                                          </div>
                                       </li>
                                    )}
                                    renderInput={(params) => (
                                       <TextField
                                          {...params}
                                          label="Search users"
                                          onChange={handleSearchUsers}
                                          InputProps={{
                                             ...params.InputProps,
                                             endAdornment: (
                                                <React.Fragment>
                                                   {usersData.length - 1 === index && searchUsersAPIData.loading ? (
                                                      <CircularProgress color="inherit" size={20} />
                                                   ) : null}
                                                   {params.InputProps.endAdornment}
                                                </React.Fragment>
                                             ),
                                          }}
                                       />
                                    )}
                                 />
                              ) : (
                                 <>
                                    {item.userName} {item.userId === user?.sub && <span>(You)</span>}
                                 </>
                              )}
                           </div>
                           <div style={{ color: 'gray' }}>{item.email}</div>
                        </TableCell>
                        <TableCell align="left">
                           {item.userId ? (
                              item.role
                           ) : (
                              <FormControl>
                                 <InputLabel>Role</InputLabel>
                                 <Select
                                    label="Role"
                                    value={item.role}
                                    onChange={(e) => handleUserRoleChange(index, e.target.value)}
                                    renderValue={(selected) => selected}
                                 >
                                    {ROLES.map((role) => (
                                       <MenuItem key={role} value={role}>
                                          {role}
                                       </MenuItem>
                                    ))}
                                 </Select>
                              </FormControl>
                           )}
                        </TableCell>
                        {props.edit && (
                           <TableCell>
                              <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                 <div>
                                    <IconButton color="error" onClick={() => handleRemoveUserRole(index)}>
                                       <DeleteOutline />
                                    </IconButton>
                                 </div>
                                 {index === usersData.length - 1 && (
                                    <div>
                                       <IconButton color="primary" onClick={handleAddUserRole}>
                                          <AddBoxOutlined />
                                       </IconButton>
                                    </div>
                                 )}
                              </div>
                           </TableCell>
                        )}
                     </TableRow>
                  ))}
               </TableBody>
            </Table>
         </DialogContent>

         <DialogActions sx={{ margin: '10px 20px 10px 20px' }}>
            <Button color="primary" variant="outlined" onClick={props.closeModal}>
               {props.edit ? 'Cancel' : 'Close'}
            </Button>
            {props.edit && (
               <Button color="primary" variant="contained" onClick={sumbitUpdateUserToTeam}>
                  Save
               </Button>
            )}
         </DialogActions>
      </Dialog>
   )
}

const IconContainer = styled.div`
   width: 40px;
   height: 40px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
`

export default ManageTeamUsers
