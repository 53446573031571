// cardsConfig.ts
export interface CardItem {
   label: string
   icon: string
   available: boolean
}

export const cardsConfig: CardItem[] = [
   {
      label: 'React',
      icon: '/static/react.svg',
      available: true,
   },
   {
      label: 'Vue',
      icon: '/static/vue.svg',
      available: false,
   },
   {
      label: 'Angular',
      icon: '/static/angular.svg',
      available: false,
   },
   {
      label: 'Node JS',
      icon: '/static/nodejs.svg',
      available: true,
   },
   {
      label: 'Spring Boot',
      icon: '/static/springboot.svg',
      available: true,
   },
   {
      label: 'Helm',
      icon: '/static/helm.svg',
      available: true,
   },
]
