import { AxiosInstance } from 'axios'

export const applicationApiUse = (api: AxiosInstance) => {
   return {
      getApplications: getApplications(api),
      deleteApplication: deleteApplication(api),
      addApplications: addApplications(api),
      addIntegration: addIntegration(api),
      refreshIac: refreshIac(api),
      validateHelmChart: validateHelmChart(api),
      getApplicationPipelines: getApplicationPipelines(api),
      getActiveFEAndBEApplications: getActiveFEAndBEApplications(api),
   }
}

const getApplications = (api: AxiosInstance) => async (req?: any) => {
   return api.get('/applications', req)
}

const getApplicationPipelines = (api: AxiosInstance) => async (req?: any) => {
   return api.get('/applications?appType=FRONTEND&appType=BACKEND', req)
}

const getActiveFEAndBEApplications = (api: AxiosInstance) => async (req?: any) => {
   return api.get('/applications?appType=FRONTEND&appType=BACKEND&appStatus=ACTIVE', req)
}

const deleteApplication = (api: AxiosInstance) => async (appId: number) => {
   return api.delete(`/applications/${appId}`)
}
const addApplications = (api: any) => async (req?: any) => {
   return api.post('/applications', req)
}
const addIntegration = (api: any) => async (req?: any) => {
   return api.post('/applications/integration', req)
}
const refreshIac = (api: AxiosInstance) => async (appId: number, req?: any) => {
   return api.put(`/applications/${appId}/iac`, req)
}

const validateHelmChart = (api: AxiosInstance) => async (repoUrl: string, chartName: string, chartVersion: string) => {
   return api.get('/helm-repos/validate-chart', {
      params: {
         repoUrl: repoUrl,
         chartName: chartName,
         chartVersion: chartVersion
      }
   })
}
