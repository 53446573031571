import GetStarted from "./GetStarted/GetStarted";

function Home() {


  return (
   <GetStarted />
  )
}
export default Home;
