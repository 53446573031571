import React, { useEffect, useState } from 'react'
import { PageContainer, PageContainerApp, PageSubTitle, PageTitle, TableContainerDefault } from '../Devops/CommonStyles'
import { Button, Table, TableBody, TableCell, TableContainer, TableRow, TableHead, Grid } from '@mui/material'
import { AddBoxOutlined } from '@mui/icons-material'
import { TopContainer, Title, Subtitle } from '../Applications/ApplicationStyles'
import AddTeamDialog, { singleTenantInTeamType } from './Extras/AddTeamDialog'
import { useUserApiClient } from '../../Configs/API/createAxiosConfig'
import { useAppDispatch } from '../../Configs/store/config/useAppDispatch'
import { dispatchteamsAPIData } from '../../Configs/ReduxSlice/teamSlice'
import TeamInTable from './Extras/SingleTeamTableRow'
import { useAppSelector } from '../../Configs/store/config/useAppSelector'
import SimpleLoadingScreen from '../../components/Common/SimpleLoadingScreen'
import useAlert from '../../components/Extras/Alerts/useAlert'

export interface UserRoleType {
   userName: string
   role: string
   id?: number
   email?: string
   picture?: string
   userId: string
}

export interface TeamType {
   id: number
   name: string
   tenants: singleTenantInTeamType[]
   users: UserRoleType[]
}

const UserManagement = () => {
   const [openModal, setOpenModal] = useState(false)
   const [teamsLoading, setteamsLoading] = useState<boolean>(false)
   const [apierror, setError] = useState<string | null>(null)

   const teamsService = useUserApiClient()
   const dispatch = useAppDispatch()
   const teamsAPIData = useAppSelector((state) => state.teams.teamsAPIData)
   const { showAlert } = useAlert()

   const handleOpenModal = () => {
      setOpenModal(true)
   }

   const fetchTeams = async () => {
      try {
         setteamsLoading(true)
         const response = await teamsService.getTeams()
         if (response.status == 200) {
            dispatch(dispatchteamsAPIData(response.data))
            setteamsLoading(false)
         } else {
            setError('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to fetch tenants', { extraInfo: 'Please try again later' })
      } finally {
         setteamsLoading(false)
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Teams'
      fetchTeams()
   }, [])

   if (teamsLoading) {
      return <SimpleLoadingScreen />
   }

   return (
      <PageContainer>
         <PageContainerApp>
            <PageTitle>Teams</PageTitle>
            <PageSubTitle>Create teams and manage their associated tenants and users.</PageSubTitle>
            {teamsAPIData?.length === 0 ? (
               <NoTeams openModal={handleOpenModal} />
            ) : (
               <>
                  <Button
                     variant="contained"
                     color="primary"
                     style={{
                        marginRight: '20px',
                        marginTop: '-80px',
                        float: 'right',
                     }}
                     onClick={handleOpenModal}
                  >
                     Add Team
                  </Button>
                  <TableContainer component={TableContainerDefault} sx={{ marginTop: '50px' }}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                           {teamsAPIData.map((team) => (
                              <TeamInTable team={team} />
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </>
            )}
            {openModal && <AddTeamDialog open={openModal} closeModal={setOpenModal} />}
         </PageContainerApp>
      </PageContainer>
   )
}

const NoTeams: React.FC<{ openModal: () => void }> = ({ openModal }) => (
   <div style={{ maxWidth: '900px' }}>
      <TopContainer>
         <Title>No Teams</Title>
         <Subtitle>Get started by creating a new team.</Subtitle>
         <Button variant="contained" style={{ padding: '6px 16px' }} onClick={openModal}>
            <AddBoxOutlined style={{ marginRight: '6px' }} />
            Add Team
         </Button>
      </TopContainer>
   </div>
)

export default UserManagement
