import React, { useEffect, useState, useCallback } from 'react'
import { PageContainer, PageContainerApp, PageSubTitle, PageTitle } from '../../Devops/CommonStyles'
import { OpenInNew, CheckCircle, Cancel } from '@mui/icons-material'
import {
   Button,
   Grid,
   Card,
   CardContent,
   Typography,
   List,
   ListItem,
   ListItemText,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
} from '@mui/material'
import styled from 'styled-components'
import { useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import ErrorScreen from '../../../components/Common/ErrorScreen'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import useAlert from '../../../components/Extras/Alerts/useAlert'

// Define types for the data
type Activity = {
   id: number
   appName: string
   time: string
   action: string
   status: string
}

type FailingApp = {
   id: number
   name: string
   failures: number
}

type DashboardData = {
   totalDeployments: number
   successfulDeployments: number
   failedDeployments: number
   recentActivity: Activity[]
   topFailingApps: FailingApp[]
}

type Tenant = {
   id: string
   tenantName: string
   tenantStatus: string
}

const DeploymentsDashboard: React.FC = () => {
   const [tenants, setTenants] = useState<Tenant[]>([])
   const [selectedTenant, setSelectedTenant] = useState<string>('')
   const [dashboardData, setDashboardData] = useState<DashboardData | null>(null)
   const [loadingTenants, setLoadingTenants] = useState<boolean>(false)
   const [loadingDashboard, setLoadingDashboard] = useState<boolean>(false)
   const [error, setError] = useState<string | null>(null)
   const [filterStatus, setFilterStatus] = useState<string | null>(null)
   const tenantService = useTenantApiClient()
   const dispatch = useAppDispatch()
   const { showAlert } = useAlert()

   const fetchTenants = async () => {
      setLoadingTenants(true)
      setError(null)

      try {
         const response = await tenantService.getTenants()
         console.log('response.status - tenant', response.status, response)

         if (response.status === 200) {
            const tenantData = response.data || []
            setTenants(tenantData)
            dispatch(dispatchTenantsAPIData(tenantData))

            if (response.data.length > 0) {
               setSelectedTenant(tenantData[0].id)
               fetchDashboardData(tenantData[0].id)
            }
         } else {
            setError('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetching Applications', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.log('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to load tenants', { extraInfo: 'Please try again later' })
      } finally {
         setLoadingTenants(false)
      }
   }

   // Fetch dashboard data when the selected tenant changes
   const fetchDashboardData = async (tenantId = selectedTenant) => {
      console.log('kube', tenantId)

      setLoadingDashboard(true)
      setError(null)

      try {
         const response = await tenantService.getKubeWorkloads(tenantId)
         setDashboardData(response.data)
      } catch (err) {
         console.error('Error fetching dashboard data:', err)
         setError('Failed to load dashboard data. Please try again later.')
         showAlert('Failed to load workloads', { extraInfo: 'Please try again later' })
      } finally {
         setLoadingDashboard(false)
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Kubernetes Workloads'
      fetchTenants()
   }, [])

   const handleChangeTenant = (event: any) => {
      setSelectedTenant(event.target.value as string)
      fetchDashboardData(event.target.value)
   }

   const handleArgoCDClick = () => {
      const selectedTenantName = tenants.find((tenant) => tenant.id === selectedTenant)?.tenantName || ''
      window.open(`https://argocd.colate.io/applications?proj=${selectedTenantName}`)
   }

   const handleFilterStatus = (status: string | null) => {
      setFilterStatus(status)
   }

   const filteredActivity = filterStatus
      ? dashboardData?.recentActivity.filter(
           (activity) =>
              (filterStatus === 'Passed' && activity.action === 'Deployed') ||
              (filterStatus === 'Failed' && activity.action === 'Failed')
        )
      : dashboardData?.recentActivity

   if (loadingTenants || loadingDashboard) {
      return <SimpleLoadingScreen />
   }

   if (error) {
      return <ErrorScreen message={error} />
   }

   return (
      <PageContainer>
         <PageContainerApp>
            <PageTitle>Kubernetes Workloads</PageTitle>
            <PageSubTitle>
               Overview of recent Kubernetes workloads managed by ArgoCD. Click on the button below to view more
               detailed information in the ArgoCD dashboard.
            </PageSubTitle>
            <Grid
               container
               spacing={2}
               alignItems="center"
               justifyContent="space-between"
               style={{ marginBottom: '20px' }}
            >
               <Grid item>
                  <Button variant="contained" color="primary" onClick={handleArgoCDClick}>
                     ArgoCD Dashboard <OpenInNew style={{ marginLeft: '8px' }} />
                  </Button>
               </Grid>
               <Grid item>
                  <FormControl fullWidth style={{ minWidth: '400px' }}>
                     <InputLabel id="tenant-select-label">Select Tenant</InputLabel>
                     <Select
                        labelId="tenant-select-label"
                        value={selectedTenant}
                        onChange={handleChangeTenant}
                        label="Select Tenant"
                     >
                        {tenants
                           .filter((tenant) => tenant.tenantStatus === 'ACTIVE')
                           .map((tenant) => (
                              <MenuItem key={tenant.id} value={tenant.id}>
                                 {tenant.tenantName}
                              </MenuItem>
                           ))}
                     </Select>
                  </FormControl>
               </Grid>
            </Grid>
            {dashboardData ? (
               <Grid container spacing={3} sx={{ marginTop: '20px' }}>
                  <Grid item xs={12} sm={6} md={4}>
                     <Card>
                        <CardContent onClick={() => handleFilterStatus(null)} style={{ cursor: 'pointer' }}>
                           <Typography variant="h6">Total</Typography>
                           <Typography variant="h4">{dashboardData.totalDeployments}</Typography>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <Card>
                        <CardContent onClick={() => handleFilterStatus('Passed')} style={{ cursor: 'pointer' }}>
                           <Typography variant="h6">Successful</Typography>
                           <Typography variant="h4" color="success.main">
                              {dashboardData.successfulDeployments}
                           </Typography>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                     <Card>
                        <CardContent onClick={() => handleFilterStatus('Failed')} style={{ cursor: 'pointer' }}>
                           <Typography variant="h6">Failed</Typography>
                           <Typography variant="h4" color="error.main">
                              {dashboardData.failedDeployments}
                           </Typography>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                     <Card>
                        <CardContent style={{ height: '300px', overflowY: 'auto' }}>
                           <Typography variant="h6">Recent Activity</Typography>
                           <List>
                              {filteredActivity?.map((activity) => (
                                 <ListItem key={activity.id}>
                                    <ListItemText
                                       primary={`${activity.appName} - ${activity.action}`}
                                       secondary={activity.time}
                                    />
                                    <StatusWrapper status={activity.status}>
                                       {activity.action === 'Deployed' ? (
                                          <CheckCircle fontSize="small" />
                                       ) : (
                                          <Cancel fontSize="small" />
                                       )}
                                       <StatusLabel>{activity.action === 'Deployed' ? 'Passed' : 'Failed'}</StatusLabel>
                                    </StatusWrapper>
                                 </ListItem>
                              ))}
                           </List>
                        </CardContent>
                     </Card>
                  </Grid>
                  <Grid item xs={12} sm={6} md={6}>
                     <Card>
                        <CardContent style={{ height: '300px', overflowY: 'auto' }}>
                           <Typography variant="h6">Top Failing Workloads</Typography>
                           <List>
                              {dashboardData.topFailingApps.map((app) => (
                                 <ListItem key={app.id}>
                                    <ListItemText primary={app.name} secondary={`Failures: ${app.failures}`} />
                                 </ListItem>
                              ))}
                           </List>
                        </CardContent>
                     </Card>
                  </Grid>
               </Grid>
            ) : null}
         </PageContainerApp>
      </PageContainer>
   )
}

export default DeploymentsDashboard

const StatusWrapper = styled.div<{ status: string }>`
   display: flex;
   align-items: center;
   background-color: ${({ status }) => (status === 'Healthy' ? '#d4edda' : '#f8d7da')};
   color: ${({ status }) => (status === 'Healthy' ? '#155724' : '#721c24')};
   padding: 4px 8px;
   border-radius: 4px;
   font-weight: 500;
   justify-content: center;
   cursor: pointer;
`

const StatusLabel = styled.span`
   margin-left: 4px;
`
