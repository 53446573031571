import {
   Alert,
   AlertColor,
   AlertTitle,
   Snackbar,
   SnackbarOrigin,
} from '@mui/material'

type SnakBarType = {
   open: boolean | undefined
   type: AlertColor
   alertText: string
   onClose: (() => void) | undefined
   alertTitle: string
   anchorOrigin?: SnackbarOrigin
   autoHideDuration?: number
}
const SnackbarNotification = (props: SnakBarType) => (
   <Snackbar
      anchorOrigin={
         props.anchorOrigin || { vertical: 'bottom', horizontal: 'center' }
      }
      open={props.open}
      autoHideDuration={props.autoHideDuration || 6000}
      onClose={props.onClose}
   >
      <Alert severity={props.type || 'error'} sx={{ width: '40vw' }}>
         {props.alertTitle && <AlertTitle>{props.alertTitle}</AlertTitle>}
         {props.alertText}
      </Alert>
   </Snackbar>
)

export default SnackbarNotification
