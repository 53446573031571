import gitlabSaasIcon from '/static/images/gitlab.png'
import gitlabSelfIcon from '/static/images/gitlab.png'
import githubIcon from '/static/images/github.png'
import bitbucketIcon from '/static/images/bitbucket.png'

export const listOfRepositoriesToDisplay = {
   GITLAB_SAAS: {
      name: 'Gitlab Cloud',
      icon: gitlabSaasIcon,
      description:
         'A feature-rich DevOps platform with AI-powered tools and advanced CI/CD capabilities, may require a subscription.',
      availability: true,
   },
   GITLAB_SELF: {
      name: 'GitLab Enterprise Edition',
      icon: gitlabSelfIcon,
      description: 'COCREATE Managed GitLab, exclusive for COCREATE users, offering essential features only.',
      availability: true,
   },
   GITHUB: {
      name: 'GitHub',
      icon: githubIcon,
      description: 'Popular for open-source projects and integrates well with GitLab CI for seamless CI/CD pipelines.',
      availability: false,
   },
   BITBUCKET: {
      name: 'Bitbucket Cloud',
      icon: bitbucketIcon,
      description: 'Ideal for professional teams, offering deep integration with tools such as Jira, Trello etc.',
      availability: false,
   },
}
