import React from 'react'
import styled from 'styled-components'
import { Typography, Button, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import { TooltipRenderProps } from 'react-joyride'
import { theme } from '../../../styles/theme'

const TooltipContainer = styled.div`
    background-color: white;
    background: ${theme.colors.tutorialBackground}
    border-radius: 8px;
    padding: 16px;
    max-width: 300px;
    position: relative;
`

const TooltipHeader = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 8px;
`

const TooltipActions = styled.div`
   display: flex;
   justify-content: space-between;
   margin-top: 16px;
`

const CustomTooltip = ({
   step,
   skipProps,
   isLastStep,
   primaryProps,
   closeProps,
}: TooltipRenderProps) => (
   <TooltipContainer>
      <TooltipHeader>
         <Typography style={{ paddingLeft: '10px' }} variant="h6">
            {step.title}
         </Typography>
         <IconButton style={{}} size="small" {...closeProps}>
            <CloseIcon />
         </IconButton>
      </TooltipHeader>
      <Typography
         style={{ padding: '10px 40px 10px 10px' }}
         variant="body1"
         color={theme.colors.font1}
      >
         {step.content}
      </Typography>
      <TooltipActions>
         {isLastStep ? (
            <Button {...primaryProps} color="secondary">
               Close
            </Button>
         ) : (
            <>
               <Button {...skipProps} color="info">
                  Skip Tutorial
               </Button>
               <Button {...primaryProps} color="secondary">
                  Next
               </Button>
            </>
         )}
      </TooltipActions>
   </TooltipContainer>
)

export default CustomTooltip
