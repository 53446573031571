import React from 'react'
import { TopContainer, Title, Subtitle } from './ApplicationStyles'
import { Button, Grid } from '@mui/material'
import { AddOutlined } from '@mui/icons-material'
import { cardsConfig } from '../GetStarted/cardsConfig'
import CardComponent from '../GetStarted/CardConponent'
import CreateApplicationDialog from './CreateApplicationDialog/CreateApplicationDialog'

const NoApplications = () => {
   const [openApplicationDialog, setOpenApplicationDialog] = React.useState(false)
   const [technology, setTechnology] = React.useState('')

   const openAppDialog = (tech: string) => {
      setTechnology(tech)
      setOpenApplicationDialog(true)
   }
   return (
      <div style={{ maxWidth: '900px' }}>
         <TopContainer>
            <Title>No Applications</Title>
            <Subtitle>Get started by creating a new Application.</Subtitle>
            <Button variant="contained" style={{ padding: '6px 16px' }} onClick={() => setOpenApplicationDialog(true)}>
               <AddOutlined style={{ marginRight: '6px' }} />
               Create Application
            </Button>
         </TopContainer>
         <Grid container spacing={6} style={{ padding: '1px 40px' }}>
            {cardsConfig.map((card: any) => (
               <Grid item xs={12} sm={6} md={4} lg={4} key={card.label} style={{ background: 'transparent' }}>
                  <CardComponent card={card} onClickFn={openAppDialog} />
               </Grid>
            ))}
         </Grid>
         {openApplicationDialog && (
            <CreateApplicationDialog
               openApplicationDialog={openApplicationDialog}
               setOpenApplicationDialog={setOpenApplicationDialog}
               technology={technology?.toUpperCase()?.replace(' ', '')}
            />
         )}
      </div>
   )
}

export default NoApplications
