import React, { useEffect, useState } from 'react'
import { PageContainer, PageContainerApp, PageSubTitle, PageTitle, TableContainerDefault } from '../Devops/CommonStyles'
import { theme } from '../../styles/theme'
import styled from 'styled-components'
import NoApplications from './NoApplications'
import { MoreHoriz, OpenInNew, Refresh, Delete } from '@mui/icons-material'
import { useApplicationApiClient } from '../../Configs/API/createAxiosConfig'
import SimpleLoadingScreen from '../../components/Common/SimpleLoadingScreen'
import ErrorScreen from '../../components/Common/ErrorScreen'
import { Tooltip, IconButton } from '@mui/material';
import { ContentCopy } from '@mui/icons-material'
import {
   Button,
   CircularProgress,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Menu,
   MenuItem,
   ListItemIcon,
   ListItemText,
} from '@mui/material'
import CreateApplicationDialog from './CreateApplicationDialog/CreateApplicationDialog'
import ReviewModal from './ReviewModal/ReviewModal'
import { dispatchApplicationsAPIData } from '../../Configs/ReduxSlice/ApplicationSlice'
import { toCamelCase, truncateString } from '../../components/helpers/stringFns'
import MoreInfoMenu from '../../components/Common/MoreInfoMenu'
import { useAppSelector } from '../../Configs/store/config/useAppSelector'
import { useAppDispatch } from '../../Configs/store/config/useAppDispatch'
import useAlert from '../../components/Extras/Alerts/useAlert'
import { useAuth0 } from '@auth0/auth0-react'
import BackendApplication from './BackendApplication';
import KubeNativeApplication from './KubeNativeApplication';
import FrontendApplication from './FrontendApplication';

type appAttributes = {
   appUrl: string
   status: string
   appProjectId: number
   webhookToken: string
   repositoryUrl: string
   iacPipelineUrl: string
   cloudfrontDistributionId: string
}

export interface applicationType {
   id: number
   clientId: string
   appName: string
   technology: string
   appStatus: string
   tenantId: number
   appType: string
   appProjectId: number | string | null
   webhookToken: string | null
   appAttributes: appAttributes | null
   appBuildAttributes: any | null
}

const Applications = () => {
   const [applications, setApplications] = useState<applicationType[]>([])
   const [loading, setLoading] = useState<boolean>(true)
   const [error, setError] = useState<string | null>(null)
   const [openApplicationDialog, setOpenApplicationDialog] = useState(false)
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
   const [selectedAppId, setSelectedAppId] = useState<number | null>(null)
   const [selectedAppName, setSelectedAppName] = useState<string | null>(null)
   const [isPreparing, setIsPreparing] = useState(false)
   const { showAlert } = useAlert()
   const [reviewModal, setReviewModal] = useState({
      status: false,
      message: '',
      isDelete: false,
   })
   const { user } = useAuth0()
   const [processing, setProcessing] = useState<boolean>(false)
   const { applicationAPIData } = useAppSelector((state) => state.applications)
   const { getApplications, deleteApplication, refreshIac } = useApplicationApiClient()
   const dispatch = useAppDispatch()
   const { getAccessTokenSilently } = useAuth0();
   const [authToken, setAuthToken] = useState<string | null>(null);

   const fetchApplications = async () => {
      try {
         const response = await getApplications()
         if (response.status === 200) {
            setApplications(response.data)
            const preparingApp = response.data?.some((app: applicationType) => app.appStatus === 'PREPARING')
            setIsPreparing(preparingApp)
            dispatch(dispatchApplicationsAPIData(response.data))
         } else {
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetching Applications', {
               header: response?.response?.data?.message,
            })

            setError('Failed to load Applications. Please try again later.')
         }
      } catch (error) {
         console.error('Error fetching Applications', error)
         setError('Failed to load Applications. Please try again later.')
         showAlert('Failed to load Applications', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   const handleDelete = async () => {
      if (selectedAppId !== null) {
         setProcessing(true)
         try {
            const response = await deleteApplication(selectedAppId)
            if (response.status === 200 || response.status === 201) {
               setApplications(applications.filter((app) => app.id !== selectedAppId))
               handleCloseReviewModal()
            } else
               showAlert(response?.response?.data?.errorMessage || 'Failed to Delete Applications', {
                  header: response?.response?.data?.message,
               })
         } catch (error) {
            console.error('Error deleting application', error)
            setError('Failed to delete application. Please try again later.')
            showAlert('Failed to Delete Application', { extraInfo: 'Please try again later' })
         } finally {
            setProcessing(false)
         }
      }
   }

   const handleRetryIac = async (appId: number) => {
      setProcessing(true)
      try {
         const response = await refreshIac(appId) // Use the passed appId
         if (response.status === 200 || response.status === 201) {
            const updatedApplications = applications.map((app) =>
               app.id === appId ? { ...app, appStatus: 'RETRYING' } : app
            )
            setApplications(updatedApplications)
            handleCloseMenu() // Close the menu after retry
         } else
            showAlert(response?.response?.data?.errorMessage || 'Failed to refresh Applications', {
               header: response?.response?.data?.message,
            })
      } catch (error) {
         console.error('Error performing retry IAC operation', error)
         setError('Failed to retry application IAC. Please try again later.')
         showAlert('Failed to refresh Application', { extraInfo: 'Please try again later' })
      } finally {
         setProcessing(false) // Reset the processing state
      }
   }

   const handleClickMenu = (event: React.MouseEvent<HTMLElement>, appId: number, appName: string) => {
      setAnchorEl(event.currentTarget)
      setSelectedAppId(appId)
      setSelectedAppName(appName)
   }

   const handleCloseMenu = () => {
      setAnchorEl(null)
      // setSelectedAppId(null)
   }

   const handleOpenReviewModal = (isDelete: boolean) => {
      if (selectedAppId !== null && selectedAppName !== null) {
         setReviewModal({
            status: true,
            message: isDelete
               ? `Are you sure you want to delete the application "${selectedAppName}"? This action cannot be undone.`
               : 'Some other action message',
            isDelete,
         })
      } else {
         console.log('No app selected')
      }
      handleCloseMenu()
   }

   const handleCloseReviewModal = () => {
      setReviewModal({ status: false, message: '', isDelete: false })
      setSelectedAppId(null)
      setSelectedAppName(null)
   }

   useEffect(() => {
      document.title = 'COCREATE | Applications'
      if (user?.sub) fetchApplications()
   }, [])

   useEffect(() => {
      setApplications(applicationAPIData)
      checkIfPreparing(applicationAPIData)
   }, [applicationAPIData])

   useEffect(() => {
     const fetchToken = async () => {
       try {
         const token = await getAccessTokenSilently();
         setAuthToken(token);
       } catch (e) {
         console.error('Error fetching Auth0 token', e);
       }
     };
     fetchToken();
   }, [getAccessTokenSilently]);

   const checkIfPreparing = (applicationAPIData: applicationType[]) => {
      const preparingApp = applicationAPIData?.some((app: applicationType) => app.appStatus === 'PREPARING')
      if (preparingApp) {
         const interval = setTimeout(() => {
            console.log('Calling api again after 30 secs')

            fetchApplications()
         }, 30000)

         return () => clearTimeout(interval)
      } else return ''
   }

   if (loading) {
      return <SimpleLoadingScreen />
   }

   if (error) {
      return <ErrorScreen message={error} />
   }

   const SpinnerWrapper = styled.span`
      display: inline-flex;
      align-items: center;
      margin-left: 5px;
   `

   const displayStatus = (appStatus: string) => {
      const camelCasedStatus = toCamelCase(appStatus)

      switch (appStatus) {
         case 'PREPARING':
            return (
               <span>
                  {camelCasedStatus}
                  <SpinnerWrapper>
                     <CircularProgress size={20} />
                  </SpinnerWrapper>
               </span>
            )
         default:
            return camelCasedStatus
      }
   }

   const getTechnologyImage = (technology: string) => {
      const imageName = technology.toLowerCase()
      return `/static/${imageName}.svg`
   }

   return (
      <PageContainer>
         <PageContainerApp>
            {!applications || applications.length === 0 ? (
               <NoApplications />
            ) : (
               <>
                  <PageTitle>Applications</PageTitle>
                  <PageSubTitle>Create frontend, backend and kubernetes native applications.</PageSubTitle>
                  <Button
                     variant="contained"
                     color="primary"
                     style={{
                        marginRight: '20px',
                        marginTop: '-80px',
                        float: 'right',
                     }}
                     onClick={() => setOpenApplicationDialog(true)}
                  >
                     Add Application
                  </Button>
                  <TableContainer sx={{ marginTop: '50px' }} component={TableContainerDefault}>
                     <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableBody>
                           {applications.map((app: applicationType) => (
                              <TableRow
                                 key={app.id}
                                 sx={{
                                    '&:last-child td, &:last-child th': {
                                       border: 0,
                                    },
                                 }}
                              >
                                 <TableCell align="center">
                                    <ApplicationInfoContainer>
                                       <ImageContainer>
                                          <img src={getTechnologyImage(app.technology)} width={55} />
                                       </ImageContainer>
                                       <div className="col">
                                          {app?.appName}
                                          <Technology>{app?.technology}</Technology>
                                       </div>
                                    </ApplicationInfoContainer>
                                 </TableCell>
                                 {/* Use the appropriate component based on appType */}
                                 {app.appType === 'BACKEND' ? (
                                   <BackendApplication app={app} authToken={authToken} />
                                 ) : app.appType === 'KUBE_NATIVE' ? (
                                   <KubeNativeApplication app={app} />
                                 ) : (
                                   <FrontendApplication app={app} />
                                 )}
                                 <TableCell align="center">{displayStatus(app.appStatus)}</TableCell>
                                 <TableCell align="center" style={{ width: '150px' }}>
                                    <Button
                                       style={{
                                          marginRight: '0px',
                                          padding: '4px 2px',
                                          borderRadius: '4px',
                                          color: theme.colors.font2,
                                          border: `1px solid ${theme.colors.boxBorder}`,
                                          width: '30px',
                                          height: '30px',
                                          minWidth: 'unset',
                                       }}
                                       variant="outlined"
                                       color="primary"
                                       onClick={(e) => handleClickMenu(e, app.id, app.appName)}
                                    >
                                       <MoreHoriz />
                                    </Button>
                                    <MoreInfoMenu
                                       anchorEl={anchorEl}
                                       open={selectedAppId === app.id && Boolean(anchorEl)}
                                       handleCloseMenu={handleCloseMenu}
                                       menuItems={[
                                          {
                                             name: ' Retry',
                                             onClickFn: () => handleRetryIac(app.id),
                                             disabled:
                                                app.appStatus == 'ACTIVE' || app.appStatus == 'DEPLOYING'
                                                   ? true
                                                   : false,
                                             icon: <Refresh fontSize="small" />,
                                          },
                                          {
                                             name: 'Delete',
                                             onClickFn: () => handleOpenReviewModal(true),
                                             disabled:
                                                app.appStatus == 'PREPARING' || app.appStatus == 'DEPLOYING'
                                                   ? true
                                                   : false,
                                             icon: <Delete fontSize="small" style={{ color: theme.colors.red }} />,
                                          },
                                       ]}
                                    />
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </>
            )}
            {openApplicationDialog && (
               <CreateApplicationDialog
                  setOpenApplicationDialog={setOpenApplicationDialog}
                  openApplicationDialog={openApplicationDialog}
               />
            )}

            <ReviewModal
               openReviewModal={reviewModal.status}
               setOpenReviewModal={setReviewModal}
               message={reviewModal.message}
               handleAction={reviewModal.isDelete ? handleDelete : undefined}
               colorType={reviewModal.isDelete ? 'error' : 'primary'}
               processing={processing}
            />
         </PageContainerApp>
      </PageContainer>
   )
}

export default Applications

const ApplicationInfoContainer = styled.div`
   display: flex;
   align-items: center;
`

const ApplicationTableDataWithMoreData2 = styled.tr`
   padding: 20px 20px;
   display: flex;
   font-size: 14px;
   align-items: center;
   & .col {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
   }
`

const ApplicationTableDataWithMoreData2nd = styled(TableCell)`
   display: flex !important;
   align-items: center;
`

const Technology = styled.div`
   color: #708090;
   font-size: 12px;
`

export const UrlContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
`

export const UrlItem = styled.div`
   margin: 4px 0;
   display: flex;
   align-items: center;
   white-space: nowrap;
`

export const UrlLabel = styled.span`
   margin-right: 8px;
`

export const UrlLink = styled.span`
   font-size: 12px;
   color: #708090;
   cursor: pointer;
   display: flex;
   align-items: center;
   &:hover {
      text-decoration: underline;
   }
`

const ImageContainer = styled.div`
   background-color: #f0f0f0;
   padding-top: 5px;
   padding-bottom: 5px;
   border-radius: 4px;
   display: flex;
   align-items: center;
   justify-content: center;
   margin-right: 10px;
`
