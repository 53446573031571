export const updateFileTreeStructure = (state, filePath, fileContent) => {
   const parts = filePath.split('/')

   // Copy the current state to avoid direct mutation
   let newFileTree = [...state]
   let current = newFileTree

   // Traverse the parts of the file path
   parts.forEach((part, index) => {
      let node = current.find((item) => item.name === part)

      if (!node) {
         // Create a new node (file or folder)
         node = {
            path: parts.slice(0, index + 1).join('/'),
            name: part,
            type: index === parts.length - 1 ? 'file' : 'folder',
            isOpen: false,
            children: [],
            content: index === parts.length - 1 ? fileContent : '',
         }
         current.push(node)
      }

      if (index === parts.length - 1) {
         node.content = fileContent // Update content for file
      } else {
         current = node.children // Traverse deeper into the tree
      }
   })

   return newFileTree
}
export const updateTreeWithNewName = (nodes, currentNodePath, newFileName) => {
   return nodes.map((node) => {
      if (node.path === currentNodePath) {
         // Create a new object with updated path and name
         return {
            ...node,
            path: node.path.replace(/\/New (File|Folder)$/, `/${newFileName}`),
            name: newFileName,
         }
      } else if (node.type === 'folder' && node.children) {
         // Recursively update children, ensuring immutability
         return {
            ...node,
            children: updateTreeWithNewName(node.children, currentNodePath, newFileName),
         }
      }
      return node // Return unmodified node
   })
}

export const removeNodeByPath = (nodes, currentNodePath) => {
   return nodes
      .map((node) => {
         if (node.type === 'folder' && node.children) {
            // Recursively remove from children
            return {
               ...node,
               children: removeNodeByPath(node.children, currentNodePath),
            }
         }
         return node
      })
      .filter((node) => node.path !== currentNodePath) // Remove the node with the matching path
}

export const updateFileContentInTree = (nodes, filePath, newContent) => {
   return nodes.map((node) => {
      if (node.path === filePath) {
         // Update content immutably
         return {
            ...node,
            content: newContent,
         }
      } else if (node.type === 'folder' && node.children) {
         // Recursively update content in children
         return {
            ...node,
            children: updateFileContentInTree(node.children, filePath, newContent),
         }
      }
      return node
   })
}
export const addFileOrFolderToTree = (nodes, parentPath, newNode) => {
   return nodes.map((node) => {
      if (node.path === parentPath) {
         // Open the folder and add the new node immutably
         return {
            ...node,
            isOpen: true,
            children: node.children ? [...node.children, newNode] : [newNode],
         }
      } else if (node.type === 'folder' && node.children) {
         // Recursively add the new node to the children
         return {
            ...node,
            children: addFileOrFolderToTree(node.children, parentPath, newNode),
         }
      }
      return node
   })
}
