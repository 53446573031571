import {
   Cloud,
   VerifiedUser,
   Build,
   Group,
   Code,
   SettingsEthernet,
   Security,
   FlashOn,
   RocketLaunch,
   KeyboardDoubleArrowRightOutlined
} from '@mui/icons-material'

const steps = [
   {
      key: 'introduction',
      toHighlightSidebar: 'getStarted',
      parts: [
         {
            type: 'image',
            title: 'Welcome to COCREATE',
            description:
               'Follow the interactive tutorial to see how COCREATE can streamline your application development and digital transformation journey with AI.',
            content: '/static/images/welcome-to-cocreate.jpg',
         },
      ],
   },
   {
      key: 'createApplications',
      toHighlightSidebar: 'applications',
      parts: [
         {
            type: 'image',
            title: 'Applications',
            features: [
               {
                 icon: KeyboardDoubleArrowRightOutlined,
                 text: 'Choose from various technologies to build frontend, backend, or Kubernetes applications.',
               },
               { icon: KeyboardDoubleArrowRightOutlined,
                 text: 'Access to a COCREATE-managed Gitlab repository at no extra cost.'
               },
               {
                  icon: KeyboardDoubleArrowRightOutlined,
                  text: 'Automated code generation with full application build and deployment automation.',
               },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Adherence to security best practices throughout the process.' },
            ],
            content: '/static/images/get-started-applications.jpg',
         },
         {
            type: 'video',
            title: 'How To Create An Application',
            description: 'Watch this video tutorial to learn how to create and deploy an application.',
            content: '/static/videos/create-application.mov',
         },
      ],
   },
   {
      key: 'kubernetesWorkloads',
      toHighlightSidebar: 'kube-workloads',
      parts: [
         {
            type: 'image',
            title: 'Kubernetes Workloads',
            features: [
               {
                  icon: KeyboardDoubleArrowRightOutlined,
                  text: 'Utilize GitOps for seamless deployment and management of Kubernetes workloads.',
               },
               {
                  icon: KeyboardDoubleArrowRightOutlined,
                  text: 'Ensure secure and isolated environments for each tenant, maintaining compliance and security standards.',
               },
               {
                  icon: KeyboardDoubleArrowRightOutlined,
                  text: 'Monitor the health and performance of your workloads with built-in observability.',
               },
               {
                  icon: KeyboardDoubleArrowRightOutlined,
                  text: 'Facilitate team collaboration while maintaining strong separation between different projects.',
               },
            ],
            content: '/static/images/get-started-kubernetes.jpg',
         },
         {
            type: 'video',
            title: 'Manage Kubernetes Workloads',
            description: 'Learn how to deploy and manage Kubernetes workloads using ArgoCD and GitOps practices.',
            content: '/static/videos/kubernetes-workloads.mov',
         },
      ],
   },
   {
      key: 'buildsPipelines',
      parts: [
         {
            type: 'image',
            title: 'Builds & Pipelines',
            features: [
               { icon: KeyboardDoubleArrowRightOutlined, text: 'End-to-end DevSecOps automation from code commit to deployment.' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Consistent and repeatable builds with integrated CI/CD pipelines.' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Track your pipeline status, execution times, and success rates in real-time.' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Collaborate with your team, ensuring code quality and deployment efficiency.' },
            ],
            content: '/static/images/get-started-devops.jpg',
         },
         {
            type: 'video',
            title: 'Manage Pipelines',
            description: 'Watch this video to learn how to set up and manage CI/CD pipelines with GitLab CI.',
            content: '/static/videos/pipelines.mov',
         },
      ],
   },
   {
      key: 'configureTenants',
      parts: [
         {
            type: 'image',
            title: 'Tenants',
            features: [
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Fully automated infrastructure setup, with security and auto-scaling enabled' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Ready-to-use, dedicated cloud environment tailored for your needs, pay as you go.' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Built-in observability with comprehensive logging and metrics monitoring.' },
               { icon: KeyboardDoubleArrowRightOutlined, text: 'Customized environments for different projects or teams as needed.' },
            ],
            content: '/static/images/get-started-tenants.jpg',
         },
         {
            type: 'video',
            title: 'How To Create a Tenant',
            description: 'This video provides a step-by-step guide to creating and configuring a tenant.',
            content: '/static/videos/create-tenant.mov',
         },
      ],
   },
]

export default steps
