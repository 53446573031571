import React, { useEffect, useState } from 'react'
import {
   Autocomplete,
   Button,
   Dialog,
   DialogActions,
   DialogContent,
   DialogTitle,
   Grid,
   IconButton,
   Table,
   TableBody,
   TableCell,
   TableRow,
   TextField,
} from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useTenantApiClient, useUserApiClient } from '../../../Configs/API/createAxiosConfig'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import { Person, People, AddOutlined, AddBoxOutlined, DeleteOutline } from '@mui/icons-material'
import styled from 'styled-components'
import { singleTenantInTeamType } from './AddTeamDialog'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'
import { TenantSingleType } from '../../Configurations/Tenants/Tenant'
import { dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import useAlert from '../../../components/Extras/Alerts/useAlert'

type viewUsersTenants = {
   open: boolean
   closeModal: any
   data: singleTenantInTeamType[]
   edit: boolean
   teamId: number
}

const ManageTeamTeanants = (props: viewUsersTenants) => {
   const { user } = useAuth0()
   const tenantService = useTenantApiClient()
   const [tenants, setTenants] = useState<singleTenantInTeamType[]>(props.data)
   const [selectedTenants, setSelectedTenants] = useState<singleTenantInTeamType[]>()
   const teamsService = useUserApiClient()
   const [loading, setLoading] = useState(true)

   const [apierror, setError] = useState<string | null>(null)
   const dispatch = useAppDispatch()
   const teneantAPIData = useAppSelector((state) => state.tenants.teneantAPIData)
   const { showAlert } = useAlert()
   const fetchTenants = async () => {
      try {
         const response = await tenantService.getTenants()
         if (response.status === 200) {
            console.log('Fetched tenants:', response.data)
            // setTenants(response.data)
            dispatch(dispatchTenantsAPIData(response.data))
         } else {
            console.error('Failed to load tenants, response status:', response.status)
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
         }
      } catch (error) {
         console.error('Failed to load tenants, error:', error)
         showAlert('Failed to fetch tenants', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   const handleSelectedTenants = (event: any, value: TenantSingleType[]) => {
      const TenantSelectedIds = value.map((tn) => ({
         tenantId: tn.id,
         tenantName: tn.tenantName,
         clientId: tn.clientId,
      }))
      setSelectedTenants(TenantSelectedIds)
   }

   const handleRemoveTenant = (id: number) => {
      setTenants((prevTenants) => prevTenants?.filter((eachTenant) => eachTenant.tenantId != id))
   }
   const handleAddSingleTenant = () => {
      setTenants((prevTenants) => [
         ...prevTenants,
         {
            tenantId: Math.floor(Math.random() * 10),
            tenantName: '',
            clientId: '',
         },
      ])
   }
   useEffect(() => {
      if (props.open) {
         setLoading(true)
         fetchTenants()
      }
   }, [props.open])

   const getTenantOwnership = (clientId: string | number) => {
      return String(clientId) === user?.sub ? 'Owned By You' : 'Shared With You'
   }

   const renderTenantIcon = (clientId: string | number) => {
      const ownership = getTenantOwnership(clientId)
      return (
         <IconContainer style={{ backgroundColor: '#1976d2' }}>
            {ownership === 'Owned By You' ? (
               <Person style={{ color: 'white' }} />
            ) : (
               <People style={{ color: 'white' }} />
            )}
         </IconContainer>
      )
   }

   const sumbitUpdateTenantsToTeam = async () => {
      try {
         // setLoading(true)
         // const response = await teamsService.addUserFromTeam(props.teamId, tenants)
         // if (response.status == 200) {
         //    console.log('res', response.data)
         //    // dispatch(addUserFromTeam(response.data))
         // } else {
         //    setError('Failed to save Team. Please try again later.')
         // }
      } catch (err) {
         setError('Failed to save Team. Please try again later.')
      } finally {
         // setLoading(false)
      }
   }

   return (
      <Dialog
         open={props.open}
         onClose={props.closeModal}
         PaperProps={{
            style: { minWidth: '500px', maxHeight: '70vh', borderRadius: 10, padding: '8px 1vw' },
            component: 'form',
         }}
         scroll={'paper'}
      >
         <DialogTitle>Tenants</DialogTitle>
         <DialogContent style={{ padding: '8px 2vw' }}>
            {loading ? (
               <SimpleLoadingScreen />
            ) : (
               <Table aria-label="simple table">
                  <TableBody>
                     {tenants?.map((item: singleTenantInTeamType, index) => (
                        <TableRow key={item.tenantId}>
                           <TableCell align="left">{renderTenantIcon(item.clientId)}</TableCell>
                           <TableCell align="left">
                              {item.tenantName ? (
                                 <>
                                    {item.tenantName}{' '}
                                    <span style={{ color: 'gray' }}>({getTenantOwnership(item.clientId)})</span>
                                 </>
                              ) : (
                                 <Autocomplete
                                    multiple
                                    id="tags-outlined"
                                    options={teneantAPIData?.filter(
                                       (eachTenant) => eachTenant.tenantStatus === 'ACTIVE'
                                    )}
                                    getOptionLabel={(option) => option.tenantName}
                                    onChange={handleSelectedTenants}
                                    filterSelectedOptions
                                    noOptionsText={'No tenants found'}
                                    renderInput={(params) => <TextField {...params} label="Search Tenants" />}
                                 />
                              )}
                           </TableCell>
                           {props.edit && (
                              <TableCell>
                                 <div style={{ display: 'flex', alignItems: 'center', padding: 0 }}>
                                    {item.tenantName != '' && (
                                       <div>
                                          <IconButton color="error" onClick={() => handleRemoveTenant(item.tenantId)}>
                                             <DeleteOutline />
                                          </IconButton>
                                       </div>
                                    )}
                                    {item.tenantName != '' && index === tenants.length - 1 && (
                                       <div>
                                          <IconButton color="primary" onClick={handleAddSingleTenant}>
                                             <AddBoxOutlined />
                                          </IconButton>
                                       </div>
                                    )}
                                 </div>
                              </TableCell>
                           )}
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            )}
         </DialogContent>
         <DialogActions sx={{ marginTop: '0.8rem' }}>
            <Button color="primary" variant="outlined" onClick={props.closeModal}>
               {props.edit ? 'Cancel' : 'Close'}
            </Button>
            {props.edit && (
               <Button color="primary" variant="contained" onClick={sumbitUpdateTenantsToTeam}>
                  Save
               </Button>
            )}
         </DialogActions>
      </Dialog>
   )
}

const IconContainer = styled.div`
   width: 40px;
   height: 40px;
   border-radius: 50%;
   display: flex;
   align-items: center;
   justify-content: center;
`

export default ManageTeamTeanants
