import React, { useState } from 'react';
import {
  PageContainer,
  PageContainerApp,
  PageSubTitle,
  PageTitle,
} from '../Devops/CommonStyles';
import {
  Button,
  Grid,
  Card,
  CardContent,
  Typography,
  Tabs,
  Tab,
  TextField,
} from '@mui/material';
import styled from 'styled-components';
import {
  openSourceIntegrations,
  enterpriseIntegrations,
  integrationType,
} from './IntegrationData';
import InstallOpenSourceDialog from './InstallOpenSourceDialog';

const sortIntegrations = (integrations: integrationType[]) => {
  return integrations.sort((a, b) => a.title.localeCompare(b.title));
};

const IntegrationHub: React.FC = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [searchQuery, setSearchQuery] = useState('');
  const [openInstallDialog, setOpenInstallDialog] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState<string>('');

  const sortedEnterpriseIntegrations = sortIntegrations(enterpriseIntegrations);
  const sortedOpenSourceIntegrations = sortIntegrations(openSourceIntegrations);

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value.toLowerCase();
    setSearchQuery(query);

    if (query) {
      const openSourceMatch = sortedOpenSourceIntegrations.some(
        (integration) =>
          integration.title.toLowerCase().includes(query) ||
          integration.description.toLowerCase().includes(query)
      );
      const enterpriseMatch = sortedEnterpriseIntegrations.some(
        (integration) =>
          integration.title.toLowerCase().includes(query) ||
          integration.description.toLowerCase().includes(query)
      );

      if (openSourceMatch) {
        setActiveTab(0);
      } else if (enterpriseMatch) {
        setActiveTab(1);
      }
    }
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setActiveTab(newValue);
  };

  const handleInstallClick = (integration: integrationType) => {
    setSelectedIntegration(integration.title);
    setOpenInstallDialog(true);
  };

  const handleInstall = (name: string, tenantId: string) => {
    console.log(`Installing ${selectedIntegration} with name ${name} on tenant ${tenantId}`);
    // Perform the install action here
  };

  const filteredIntegrations = (): integrationType[] => {
    let integrations: integrationType[] = [];
    if (activeTab === 0) {
      integrations = sortedOpenSourceIntegrations;
    } else if (activeTab === 1) {
      integrations = sortedEnterpriseIntegrations;
    }

    return integrations.filter(
      (integration) =>
        integration.title.toLowerCase().includes(searchQuery) ||
        integration.description.toLowerCase().includes(searchQuery)
    );
  };

  return (
    <PageContainer>
      <PageContainerApp>
        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
          <Grid item xs={12} md={8}>
            <PageTitle>Integration Hub</PageTitle>
          </Grid>
          <Grid item xs={12} md={4}>
            <SearchField
              label="Search Integrations"
              variant="outlined"
              value={searchQuery}
              onChange={handleSearch}
              fullWidth
            />
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <PageSubTitle>Browse through our available integrations for various industries and open-source solutions.</PageSubTitle>
        </Grid>

        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab label="Open Source" />
          <Tab label="Enterprise (Coming Soon)" />
        </Tabs>
        <Grid container alignItems="center" spacing={2} justifyContent="space-between">
          <Grid item xs={12} sx={{ marginTop: '20px' }}>
            <Grid container spacing={3}>
              {filteredIntegrations().map((integration, index) => (
                <Grid item xs={12} sm={6} md={4} key={index}>
                  <Card>
                    <CardContent>
                      <IconContainer>
                        <img
                          src={integration.icon}
                          alt={integration.title}
                          style={{ width: '40px', height: '40px' }}
                        />
                      </IconContainer>
                      <Typography variant="h6">{integration.title}</Typography>
                      <Typography variant="body2" color="textSecondary" paragraph>
                        {integration.description}
                      </Typography>
                      {activeTab === 0 && ( // Show Install button for Open Source tab
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => handleInstallClick(integration)}
                          fullWidth
                        >
                          Install
                        </Button>
                      )}
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </PageContainerApp>

      <InstallOpenSourceDialog
        openInstallDialog={openInstallDialog}
        setOpenInstallDialog={setOpenInstallDialog}
        selectedIntegration={selectedIntegration}
        handleInstall={handleInstall}
      />
    </PageContainer>
  );
};

export default IntegrationHub;

const SearchField = styled(TextField)`
  width: 100%;
`;

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;
`;
