import styled from 'styled-components'
import { theme } from '../../styles/theme'
import Button from '@mui/material/Button'

export const TopContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   height: 30vh;
   text-align: center;
   margin-bottom: 30px;
`

export const Title = styled.h4`
   font-size: 22px;
   font-weight: 500;
   font-style: normal;
   margin-bottom: 4px;
   color: ${theme.colors.dark};
`

export const Subtitle = styled.p`
   font-size: 16px;
   margin-top: 5px;
   margin-bottom: 28px;
   color: ${theme.colors.font2};
`

export const Container = styled.div`
   padding: 20px 4vw;
   background-color: ${theme.colors.white};
`

export const SpaceBottom = styled.div<{ top?: string }>`
   margin-bottom: ${(props) => (props.top ? props.top + 'px' : '40px')};
`

export const CenterAlignedDiv = styled.div`
   display: inline-flex;
   -webkit-align-items: center;
   align-items: center;
`

export const AuthorizeButton = styled(Button)`
   && {
      border-color: ${theme.colors.green};
      color: ${theme.colors.green};
      &:hover {
         background-color: ${theme.colors.lightGreen};
         border-color: ${theme.colors.lightGreen};
      }
      width: 90px;
      height: 25px;
      border-radius: 12px;
      text-transform: none;
      margin-top: 8px;
   }
`

export const BackButton = styled(Button)`
   && {
      color: ${theme.colors.font1};
      border: none;
      &:hover {
         border: none;
      }
      border-radius: 12px;
   }
`

export const CancelFooterButton = styled(Button)`
   && {
      background-color: ${theme.colors.white};
      color: ${theme.colors.black};
      &:hover {
         background-color: ${theme.colors.lightGrey};
      }
   }
`

export const CreateFooterButton = styled(Button)<{
   colorType?: 'primary' | 'secondary' | 'delete'
}>`
   && {
      background-color: ${({ colorType }) => {
         switch (colorType) {
            case 'secondary':
               return theme.colors.grey
            case 'delete':
               return theme.colors.red
            default:
               return theme.colors.black
         }
      }};
      color: ${theme.colors.white};
      &:hover {
         background-color: ${({ colorType }) => {
            switch (colorType) {
               case 'secondary':
                  return theme.colors.lightGrey
               case 'delete':
                  return theme.colors.red
               default:
                  return theme.colors.grey
            }
         }};
      }
   }
`
