import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import CircularProgress from '@mui/material/CircularProgress';
import { SpaceBottom } from '../ApplicationStyles'
import { Button } from '@mui/material';
import styled from 'styled-components';

const modalStyle = (background) => ({
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: background || 'background.paper',
  border: '1px solid #000',
  boxShadow: 24,
  p: 4,
  borderRadius: 3,
});

interface ReviewModalProps {
  openReviewModal: boolean;
  setOpenReviewModal: Function;
  message: String;
  handleAction?: () => void;
  colorType?: 'primary' | 'secondary' | 'error';
  processing?: boolean;
  background?: string; // Optional background color prop
}

const ReviewModal: React.FC<ReviewModalProps> = ({
  openReviewModal,
  setOpenReviewModal,
  message,
  handleAction,
  colorType = 'primary',
  processing = false,
  background, // Accept background color as prop
}) => {
  const handleClose = () => setOpenReviewModal({ status: false, message: '', isDelete: false });

  return (
    <>
      <Modal
        open={openReviewModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle(background)}>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {message}
          </Typography>
          <SpaceBottom />
          <Grid container columnSpacing={2} justifyContent="flex-end">
            <Grid>
              <Button variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
            </Grid>
            <Grid>
              <Button variant="contained" color={colorType} onClick={handleAction} disabled={processing}>
                {processing ? <CircularProgress size={24} /> : 'Continue'}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </>
  );
};

export default ReviewModal;
