export const payloadObjectVerification = (
   payload: any
): [boolean, string] | undefined => {
   for (const [key, value] of Object.entries(payload)) {
      if (
         !payload.hasOwnProperty(key) ||
         payload[key] == undefined ||
         payload[key] == ''
      ) {
         return [true, key]
      }
   }
}
