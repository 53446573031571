import {
    AccountTreeOutlined,
    ApartmentOutlined,
    AppsOutlined,
    BoltOutlined,
    CloudUploadOutlined,
    ConstructionOutlined,
    LanguageOutlined,
    Settings,
    VisibilityOutlined,
    PeopleOutline,
    StorefrontOutlined,
    LightbulbOutlined,
    AutoAwesome,
    SecurityOutlined
} from '@mui/icons-material';

export interface SidebarItem {
    key: string
    label: string
    icon: React.ReactNode
    icon2: React.ReactNode
    route: string
    subItems?: SidebarItem[]
}

export const sidebarConfig: SidebarItem[] = [
    {
        key: 'getStarted',
        label: 'Get Started',
        route: '/Home',
        icon: 'https://img.icons8.com/?size=22&id=999&format=png&color=',
        icon2: <BoltOutlined />,
    },
    {
        key: 'visionBoard',
        label: 'Vision Board',
        icon: 'https://img.icons8.com/?size=22&id=345678&format=png&color=',
        route: 'vision-board',
        icon2: <AutoAwesome />,
    },
    {
        key: 'applications',
        label: 'Applications',
        icon: 'https://img.icons8.com/?size=22&id=74264&format=png&color=',
        route: 'applications',
        icon2: <AppsOutlined />,
    },
    {
        key: 'integrationHub',
        label: 'Integration Hub',
        icon: 'https://img.icons8.com/?size=22&id=12456&format=png&color=',
        route: 'integration-hub',
        icon2: <StorefrontOutlined />,
    },
    {
        key: 'codeBuild',
        label: 'Builds & Pipelines',
        icon: 'https://img.icons8.com/?size=22&id=86973&format=png&color=',
        route: 'code-build',
        icon2: <ConstructionOutlined />,
    },
    {
        key: 'kube-workloads',
        label: 'Kubernetes Workloads',
        icon: 'https://img.icons8.com/?size=22&id=4716&format=png&color=',
        route: 'kube-workloads',
        icon2: <CloudUploadOutlined />,
    },
    {
        key: 'observability',
        label: 'Observability',
        icon: 'https://img.icons8.com/?size=22&id=12345&format=png&color=',
        route: 'observability',
        icon2: <VisibilityOutlined />,
    },
    {
        key: 'credentials-vault',
        label: 'Credentials Vault',
        icon: 'https://img.icons8.com/?size=22&id=99999&format=png&color=', // Replace with a more appropriate vault icon URL if available
        route: 'credentials-vault',
        icon2: <SecurityOutlined />,
    },
    {
        key: 'teams',
        label: 'Teams',
        icon: 'https://img.icons8.com/?size=22&id=12345&format=png&color=',
        route: 'teams',
        icon2: <PeopleOutline />,
    },
    {
        key: 'configuration',
        label: 'Configuration',
        route: 'configuration',
        icon: 'https://img.icons8.com/?size=22&id=49338&format=png&color=',
        icon2: <Settings />,
        subItems: [
            {
                key: 'tenant',
                label: 'Tenants',
                icon: 'https://img.icons8.com/?size=22&id=86973&format=png&color=',
                route: 'tenants',
                icon2: <ApartmentOutlined />,
            },
            {
                key: 'repositories',
                label: 'Repositories',
                icon: 'https://img.icons8.com/?size=22&id=4716&format=png&color=',
                route: 'repositories',
                icon2: <AccountTreeOutlined />,
            },
            {
                key: 'domains',
                label: 'Domains',
                icon: 'https://img.icons8.com/?size=22&id=4716&format=png&color=',
                route: 'domains',
                icon2: <LanguageOutlined />,
            },
        ],
    },
];
