import React, { useEffect, useState } from 'react'
import {
   Dialog,
   DialogActions,
   DialogContent,
   DialogContentText,
   DialogTitle,
   TextField,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   CircularProgress,
   Button,
   Autocomplete,
   debounce,
   IconButton,
   Divider,
   Grid,
} from '@mui/material'
import { TeamType, UserRoleType } from '../TeamManagement'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'
import { SpaceBottom } from '../../Applications/ApplicationStyles'
import { useTenantApiClient, useUserApiClient } from '../../../Configs/API/createAxiosConfig'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'
import { dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import { TenantSingleType } from '../../Configurations/Tenants/Tenant'
import { clearUserSearch, dispatchSearchUsersData, setSearchUsersLoading } from '../../../Configs/ReduxSlice/usersSlice'
import { useDebounce } from '../../../components/helpers/debounce'
import { AddBoxOutlined, DeleteOutline } from '@mui/icons-material'
import { addToTeamAPIData } from '../../../Configs/ReduxSlice/teamSlice'
import { useAuth0 } from '@auth0/auth0-react'
import useAlert from '../../../components/Extras/Alerts/useAlert'

export type singleTenantInTeamType = {
   tenantId: number
   tenantName: string
   clientId: string
}
export const ROLES = ['ADMIN', 'VIEWER']

const AddTeamDialog = ({ open, closeModal }: { open: boolean; closeModal: any }) => {
   const [teamName, setTeamName] = useState('')
   const [selectedTenants, setSelectedTenants] = useState<singleTenantInTeamType[]>([])
   const [selectedUserRoles, setSelectedUserRoles] = useState<UserRoleType[]>([
      { userName: '', role: 'VIEWER', userId: '', email: '', picture: '' },
   ])
   const [apierror, setError] = useState<string | null>(null)
   const [formError, setformError] = useState('')
   const [loading, setLoading] = useState(false)
   const tenantService = useTenantApiClient()
   const dispatch = useAppDispatch()
   const teneantAPIData = useAppSelector((state) => state.tenants.teneantAPIData)
   const searchUsersAPIData = useAppSelector((state) => state.users.searchUsers)
   const teamsService = useUserApiClient()
   const { user } = useAuth0()
   const { showAlert } = useAlert()
   const handleCloseModal = () => {
      setTeamName('')
      setSelectedTenants([])
      setSelectedUserRoles([{ userName: '', role: 'VIEWER', userId: '', email: '', picture: '' }])
      closeModal(false)
   }

   const handleAddTeam = async (e: any) => {
      e.preventDefault()
      const newTeam = {
         name: teamName,
         tenants: selectedTenants,
         users: selectedUserRoles,
      }
      // Validation
      if (!!newTeam.name && newTeam.tenants?.length > 0 && newTeam.users?.length > 0) {
         if (newTeam.users.find((user) => user.userName === '')) {
            setformError('Please fill all User fields')
            return
         }
      } else {
         setformError('Please fill all fields')
         return
      }
      setformError('')
      console.log('new Team ', newTeam)
      try {
         setLoading(true)
         const response = await teamsService.addTeam(newTeam)
         if (response.status == 200) {
            dispatch(addToTeamAPIData(response.data))
            handleCloseModal()
         } else {
            setError('Failed to save Team. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to save team', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         setError('Failed to save Team. Please try again later.')
         showAlert('Failed to save team', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   const handleUserRoleChange = (index: number, field: keyof UserRoleType, value: string) => {
      const newUserRoles: any[] = [...selectedUserRoles]
      newUserRoles[index][field] = value
      setSelectedUserRoles(newUserRoles)
   }

   const handleUserNameChange = (e: any, value: any, index: number) => {
      const newUserRoles: any[] = [...selectedUserRoles]
      newUserRoles[index] = {
         userName: value.name,
         userId: value.userId,
         email: value.email,
         picture: value.picture,
         role: newUserRoles[index].role, // retain the role
      }
      setSelectedUserRoles(newUserRoles)
      dispatch(clearUserSearch(true))
   }

   const handleAddUserRole = (e: any) => {
      setSelectedUserRoles([...selectedUserRoles, { userName: '', role: 'VIEWER', userId: '', email: '', picture: '' }])
   }

   const handleSearchUsers = async (e: any) => {
      const searchText = String(e?.target?.value)
      if (searchText.length > 3) {
         searchUsers(searchText)
      }
   }

   const handleRemoveUserRole = (index: number) => {
      const newUserRoles = selectedUserRoles.filter((_, i) => i !== index)
      setSelectedUserRoles(newUserRoles)
   }

   const handleSelectedTenants = (event: any, value: TenantSingleType[]) => {
      const TenantSelectedIds = value.map((tn) => ({
         tenantId: tn.id,
         tenantName: tn.tenantName,
         clientId: tn.clientId,
      }))
      setSelectedTenants(TenantSelectedIds)
   }

   const searchUsers = debounce(async (str: string) => {
      dispatch(setSearchUsersLoading(true))
      try {
         const response = await teamsService.searchUsers(str)
         if (response.status == 200) {
            dispatch(setSearchUsersLoading(false))
            dispatch(dispatchSearchUsersData(response.data))
         } else {
            setError('Failed to search users. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to search users', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error search users', err)
         setError('Failed to search users. Please try again later.')
         showAlert('Failed to search users', { extraInfo: 'Please try again later' })
      } finally {
         dispatch(setSearchUsersLoading(false))
      }
   }, 1000)

   const fetchTenants = async () => {
      try {
         const response = await tenantService.getTenants()
         if (response.status == 200) {
            dispatch(dispatchTenantsAPIData(response.data))
         } else {
            setError('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to fetch tenants', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      fetchTenants()
   }, [])

   return (
      <Dialog
         open={open}
         onClose={handleCloseModal}
         PaperProps={{
            style: { width: '70vw', maxHeight: '70vh', borderRadius: 10 },
            component: 'form',
         }}
         scroll={'paper'}
      >
         <DialogTitle>Add Team</DialogTitle>
         <DialogContent style={{ padding: ' 8px 2vw' }}>
            <FormControl fullWidth margin="dense" required>
               <TextField
                  label="Team Name"
                  variant="outlined"
                  required
                  value={teamName}
                  onChange={(e) => setTeamName(e.target.value)}
               />
            </FormControl>
            <SpaceBottom top="20" />
            <FormControl fullWidth margin="dense" required>
               <Autocomplete
                  multiple
                  id="tags-outlined"
                  options={teneantAPIData?.filter(
                     (eachTenant) => eachTenant.clientId === user?.sub && eachTenant.tenantStatus === 'ACTIVE'
                  )}
                  getOptionLabel={(option) => option.tenantName}
                  onChange={handleSelectedTenants}
                  filterSelectedOptions
                  noOptionsText={'No tenants found'}
                  renderInput={(params) => <TextField {...params} label="Tenants" />}
               />
            </FormControl>
            <SpaceBottom top="20" />
            <DialogContentText style={{ marginTop: '16px', marginBottom: '8px' }}>Users and Roles:</DialogContentText>
            {selectedUserRoles.map((ur, index) => (
               <UserRoleContainer key={index} container spacing={1}>
                  <Grid item xs={12} sm={6}>
                     <FormControl fullWidth margin="dense" required>
                        <Autocomplete
                           id="asynchronous-demo"
                           getOptionLabel={(option: any) => option?.name}
                           onChange={(e, value) => handleUserNameChange(e, value, index)}
                           options={searchUsersAPIData?.data}
                           renderOption={(props, option) => (
                              <li {...props}>
                                 <img
                                    src={option.picture}
                                    alt=""
                                    width="24"
                                    height="24"
                                    style={{ marginRight: '8px' }}
                                 />
                                 <div>
                                    <div>{option.name}</div>
                                    <div style={{ color: 'gray' }}>{option.email}</div>
                                 </div>
                              </li>
                           )}
                           renderInput={(params) => (
                              <TextField
                                 {...params}
                                 label="Type to search users"
                                 onChange={handleSearchUsers}
                                 InputProps={{
                                    ...params.InputProps,
                                    endAdornment: (
                                       <React.Fragment>
                                          {selectedUserRoles.length - 1 === index && searchUsersAPIData.loading ? (
                                             <CircularProgress color="inherit" size={20} />
                                          ) : null}
                                          {params.InputProps.endAdornment}
                                       </React.Fragment>
                                    ),
                                 }}
                              />
                           )}
                        />
                     </FormControl>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <FormControl fullWidth margin="dense" required>
                        <InputLabel>Role</InputLabel>
                        <Select
                           label="Role"
                           value={ur.role}
                           onChange={(e) => handleUserRoleChange(index, 'role', e.target.value)}
                           renderValue={(selected) => selected}
                        >
                           {ROLES.map((role) => (
                              <MenuItem key={role} value={role}>
                                 {role}
                              </MenuItem>
                           ))}
                        </Select>
                     </FormControl>
                  </Grid>
                  <Grid
                     item
                     xs={6}
                     sm={1}
                     style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}
                  >
                     <IconButton color="error" onClick={() => handleRemoveUserRole(index)}>
                        <DeleteOutline />
                     </IconButton>
                  </Grid>
                  {index === selectedUserRoles.length - 1 && (
                     <Grid
                        item
                        xs={6}
                        sm={1}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 0 }}
                     >
                        <IconButton color="primary" onClick={handleAddUserRole}>
                           <AddBoxOutlined />
                        </IconButton>
                     </Grid>
                  )}
               </UserRoleContainer>
            ))}
         </DialogContent>
         <Divider />
         <DialogActions sx={{ padding: '16px' }}>
            <Button onClick={handleCloseModal} color="primary" variant="outlined">
               Cancel
            </Button>
            <Button color="primary" variant="contained" onClick={handleAddTeam} disabled={loading}>
               {loading ? <CircularProgress size={24} /> : 'Add Team'}
            </Button>
         </DialogActions>
      </Dialog>
   )
}

export default AddTeamDialog

const UserRoleContainer = styled(Grid)`
   margin-bottom: 8px;
`
