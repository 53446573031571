import React, { createContext, useState, useCallback, FC, ReactNode } from 'react'
import { createPortal } from 'react-dom'
import Alert from './Alert'

interface AlertProps {
   message: string
   callback?: () => void
   header?: string
   buttonText?: string
   extraInfo?: string
   linkClick?: () => void
}

interface AlertContextValue {
   alertProps: AlertProps
   showAlert: (
      message: string,
      options?: {
         callback?: () => void
         header?: string
         buttonText?: string
         extraInfo?: string
         onLinkClick?: () => void
      }
   ) => void
   closeAlert: () => void
}

const initialAlertProps: AlertProps = {
   message: '',
   header: '',
   buttonText: '',
   extraInfo: '',
}
export const AlertContext = createContext<AlertContextValue>({
   alertProps: initialAlertProps,
   showAlert: () => {},
   closeAlert: () => {},
})

// export const AlertContext = createContext<AlertContextValue>()

interface AlertProviderProps {
   children: ReactNode
}

export const AlertProvider: FC<AlertProviderProps> = ({ children }) => {
   const [alertProps, setAlertProps] = useState<AlertProps>(initialAlertProps)

   const showAlert = useCallback(
      (
         message: string,
         {
            callback,
            header,
            buttonText,
            extraInfo,
            onLinkClick,
         }: {
            callback?: () => void
            header?: string
            buttonText?: string
            extraInfo?: string
            onLinkClick?: () => void
         } = {}
      ) => {
         setAlertProps({
            message,
            callback,
            header,
            buttonText,
            extraInfo,
            linkClick: onLinkClick,
         })
      },
      []
   )

   const closeAlert = useCallback(() => {
      setAlertProps(initialAlertProps)
   }, [])

   const contextValue: AlertContextValue = {
      alertProps,
      showAlert,
      closeAlert,
   }

   return (
      <AlertContext.Provider value={contextValue}>
         {children}
         {createPortal(
            <Alert
               {...{
                  ...alertProps,
                  show: !!alertProps.message,
                  hideAlert: closeAlert,
               }}
            />,
            document.body
         )}
      </AlertContext.Provider>
   )
}
