export type integrationType = {
   title: string
   description: string
   icon: string
}

export const financeIntegrations: integrationType[] = [
   {
      title: 'Amazon Fraud Detector',
      description: 'Integrate with Amazon Fraud Detector for identifying fraudulent activities.',
      icon: '/static/fraud-detector.svg',
   },
   {
      title: 'Kabbage',
      description: 'Integrate with Kabbage for small business financing solutions.',
      icon: '/static/kabbage.svg',
   },
   {
      title: 'Auth0',
      description: 'Integrate with Auth0 for authentication and authorization.',
      icon: '/static/auth0.svg',
   },
   // Add more Finance-related integrations here
]

export const telecomIntegrations: integrationType[] = [
   {
      title: 'AWS IoT Analytics',
      description: 'Integrate with AWS IoT Analytics for advanced analytics.',
      icon: '/static/aws-iot.svg',
   },
   {
      title: 'AWS Greengrass',
      description: 'Integrate with AWS Greengrass for edge computing.',
      icon: '/static/aws-greengrass.svg',
   },
   // Add more Telecom-related integrations here
]

export const enterpriseIntegrations: integrationType[] = [
   {
      title: 'Datadog',
      description: 'Integrate with Datadog for cloud-scale monitoring and security.',
      icon: '/static/datadog.svg',
   },
   {
      title: 'Grafana Cloud',
      description: 'Integrate with Grafana Cloud for monitoring and observability.',
      icon: '/static/grafana.svg',
   },
   {
      title: 'Confluent Kafka',
      description: 'Integrate with Confluent Kafka for real-time data streaming.',
      icon: '/static/kafka.svg',
   },
   {
      title: 'Elasticsearch',
      description: 'Integrate with Elasticsearch - The leading search and analytics engine.',
      icon: '/static/elasticsearch.svg',
   },
   {
      title: 'MongoDB',
      description: 'Integrate with MongoDB for database management.',
      icon: '/static/mongodb.svg',
   },
   // Add more Digital Transformation-related integrations here
]

export const openSourceIntegrations: integrationType[] = [
   {
      title: 'Apache Kafka',
      description: 'Install Apache Kafka.',
      icon: '/static/kafka.svg',
   },
   {
      title: 'Elasticsearch',
      description: 'Install Elasticsearch.',
      icon: '/static/elasticsearch.svg',
   },
   {
      title: 'MySQL',
      description: 'Install MySQL.',
      icon: '/static/mysql.svg',
   },
   {
      title: 'Neo4j',
      description: 'Install Neo4j.',
      icon: '/static/neo4j.svg',
   },
   {
      title: 'PostgreSQL',
      description: 'Install PostgreSQL.',
      icon: '/static/postgresql.svg',
   },
   {
      title: 'Redis',
      description: 'Install Redis.',
      icon: '/static/redis.svg',
   },
   {
      title: 'MongoDB',
      description: 'Install MongoDB.',
      icon: '/static/mongodb.svg',
   },
]
