import { Card, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { convertHexToRGBA, theme } from '../../styles/theme'
import { CardItem } from './cardsConfig'

const StyledCard = styled(Card)<{ available: boolean }>`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   padding: 10px;
   text-align: center;
   background: transparent;
   border: 1px solid ${convertHexToRGBA(theme.colors.dark, 8)};
   box-shadow: 0 1px 2px ${theme.colors.boxBorder}
   transition: transform 0.2s;
   border-radius: 12px;
   padding-bottom: 0px;
   cursor: pointer;
   &:hover {
      ${(props) => props.available && ' transform: scale(1.02);'}
   }
   &.MuiCard-root {
     border-radius: 10px;
      box-shadow: none;
      box-shadow: 0 1px 4px ${convertHexToRGBA(theme.colors.dark, 10)};
   }
`

const CardIcon = styled.img`
   margin-top: 10px;
`
const CardContent = styled.span`
   margin: 15px 0px 15px 0px;
`
interface CardProps {
   card: CardItem
   onClickFn: Function
}

const CardComponent = ({ card, onClickFn }: CardProps) => {
   return (
      <Tooltip title={!card.available ? 'Coming soon' : ''}>
         <StyledCard available={card.available} onClick={card.available ? () => onClickFn(card.label) : () => {}}>
            <CardIcon src={card.icon} style={{ opacity: card.available ? '100%' : '20%' }} />
            <CardContent>{card.label}</CardContent>
         </StyledCard>
      </Tooltip>
   )
}

export default CardComponent
