import React, { createContext, useState, useContext, ReactNode } from 'react'

interface AppContextType {
   selectedSidebarItem: string | null
   setSelectedSidebarItem: (item: string | null) => void
   highlightSidebarItem: string | null | undefined
   setHighlightSidebarItem: (item: string | null | undefined) => void
}

const AppContext = createContext<AppContextType | undefined>(undefined)

export const AppProvider = ({ children }: { children: ReactNode }) => {
   const [selectedSidebarItem, setSelectedSidebarItem] = useState<string | null>(null)
   const [highlightSidebarItem, setHighlightSidebarItem] = useState<string | null | undefined>(null)

   return (
      <AppContext.Provider
         value={{ selectedSidebarItem, setSelectedSidebarItem, highlightSidebarItem, setHighlightSidebarItem }}
      >
         {children}
      </AppContext.Provider>
   )
}

export const useAppContext = () => {
   const context = useContext(AppContext)
   if (!context) {
      throw new Error('useAppContext must be used within an AppProvider')
   }
   return context
}
