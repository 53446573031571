import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Subtitle, Title, TopContainer } from '../../Applications/ApplicationStyles'
import {
   Button,
   CircularProgress,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TableRow,
} from '@mui/material'
import { AddOutlined, Delete, MoreHoriz, MoreVert, Refresh } from '@mui/icons-material'
import {
   MUITableCellHead,
   PageContainer,
   PageContainerApp,
   PageSubTitle,
   PageTitle,
   TableContainerDefault,
} from '../../Devops/CommonStyles'
import { convertHexToRGBA, theme } from '../../../styles/theme'
import CreateTenantDialog from '../../Applications/CreateTenantDialog/CreateTenantDialog'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import ErrorScreen from '../../../components/Common/ErrorScreen'
import { useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import { toCamelCase, truncateString } from '../../../components/helpers/stringFns'
import ReviewModal from '../../Applications/ReviewModal/ReviewModal'
import { UrlContainer, UrlItem, UrlLabel, UrlLink } from '../../Applications/Applications'
import { listOfRepositoriesToDisplay } from '../Repositories/ListOfRepository'
import MoreInfoMenu from '../../../components/Common/MoreInfoMenu'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'
import { RepoType } from '../Repositories/Repositories'
import useAlert from '../../../components/Extras/Alerts/useAlert'

export type TenantSingleType = {
   id: number
   tenantName: string
   clientId: string
   region: string
   environmentType: 'Shared' | 'Dedicated'
   codeRepositoryGroupId: number | undefined
   codeRepositoryGroupName?: string
   codeRepositoryId: number | undefined
   repositoryType: RepoType
   domainId: number | undefined
   domainName: string | undefined
   tenantStatus: string
   iacStatus: string
   iacPipelineUrl: string | null
   applicationsGroupId: number | null
   argocdGroupId: number | null
   argocdAppOfAppsProjectId: string | null
   tenantNamespace: string | null
   tenantIamRoleArn: string | null
   systemTenant: true
}

const Tenants = () => {
   const [openTenantDialog, setOpenTenantDialog] = React.useState(false)
   const [tenants, setTenants] = useState<TenantSingleType[]>([])
   const [loading, setLoading] = useState<boolean>(true)
   const [selectedTenantId, setSelectedTenantId] = useState<number | null>(null)
   const [selectedTenantName, setselectedTenantName] = useState<string | null>(null)
   const [processing, setProcessing] = useState<boolean>(false)
   const [isPreparing, setIsPreparing] = useState(false)
   const [reviewModal, setReviewModal] = useState({
      status: false,
      message: '',
      isDelete: false,
   })
   const [error, setError] = useState<string | null>(null)
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
   const tenantService = useTenantApiClient()
   const dispatch = useAppDispatch()
   const teneantAPIData = useAppSelector((state) => state.tenants.teneantAPIData)
   const { showAlert } = useAlert()

   const fetchTenants = async () => {
      try {
         const response = await tenantService.getTenants()
         if (response.status == 200) {
            // setTenants(response.data)
            checkIfPreparing(response)
            dispatch(dispatchTenantsAPIData(response.data))
         } else {
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
            setError('Failed to load tenants. Please try again later.')
         }
      } catch (err) {
         console.error('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to fetch tenants', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Tenants'
      fetchTenants()
   }, [])

   useEffect(() => {
      setTenants(teneantAPIData)
   }, [teneantAPIData])

   const checkIfPreparing = (response: any) => {
      const preparingTenant = response?.data?.some((tenant: TenantSingleType) => tenant.tenantStatus === 'PREPARING')
      if (preparingTenant) {
         const interval = setTimeout(() => {
            console.log('Calling tenants again after 30 secs')

            fetchTenants()
         }, 25000)

         return () => clearTimeout(interval)
      } else return ''
   }

   if (loading) {
      return <SimpleLoadingScreen />
   }

   if (error) {
      return <ErrorScreen message={error} />
   }

   const displayStatus = (tenant: TenantSingleType): any => {
      const camelCasedStatus = toCamelCase(tenant.tenantStatus)

      if (tenant.tenantStatus === 'ERROR') {
         if (tenant.iacStatus === 'FAILED') {
            return 'Automation Error'
         } else {
            return { camelCasedStatus }
         }
      }

      switch (tenant.tenantStatus) {
         case 'PREPARING':
            return (
               <span style={{ display: 'inline-flex' }}>
                  {camelCasedStatus}
                  <CircularProgress size={18} sx={{ marginLeft: '8px', marginTop: '2px' }} />
               </span>
            )
         default:
            return camelCasedStatus
      }
   }

   const handleCloseMenu = () => {
      setAnchorEl(null)
      // setSelectedTenantId(null)
   }

   const handleRefresh = () => {
      handleCloseMenu()
      fetchTenants()
   }

   const handleClickMenu = (event: React.MouseEvent<HTMLElement>, tenantId: number, tenantName: string) => {
      setSelectedTenantId(tenantId)
      setselectedTenantName(tenantName)
      setAnchorEl(event.currentTarget)
   }
   const handleDeleteTenant = async () => {
      if (selectedTenantId !== null) {
         setProcessing(true)
         try {
            const response = await tenantService.deleteTenant(selectedTenantId)
            if (response.status === 200 || response.status === 201) {
               const newTenants = tenants.filter((tent) => tent.id !== selectedTenantId)
               setTenants(newTenants)
               dispatch(dispatchTenantsAPIData(newTenants))
               handleCloseReviewModal()
            } else
               showAlert(response?.response?.data?.errorMessage || 'Failed to Delete tenant', {
                  header: response?.response?.data?.message,
               })
         } catch (error) {
            console.error('Error deleting tenant', error)
            setError('Failed to delete tenant. Please try again later.')
            showAlert('Failed to Delete tenant', { extraInfo: 'Please try again later' })
         } finally {
            setProcessing(false)
         }
      }
   }
   const handleOpenReviewModal = (isDelete: boolean) => {
      if (selectedTenantId !== null && selectedTenantName !== null) {
         setReviewModal({
            status: true,
            message: isDelete
               ? `Are you sure you want to delete the tenant "${selectedTenantName}"? This action cannot be undone.`
               : 'Some other action message',
            isDelete,
         })
      } else {
         console.log('No app selected')
      }
      handleCloseMenu()
   }

   const handleCloseReviewModal = () => {
      setReviewModal({ status: false, message: '', isDelete: false })
      setSelectedTenantId(null)
      setselectedTenantName(null)
   }

   return (
      <PageContainer>
         <PageContainerApp>
            {tenants && tenants.length > 0 ? (
               <>
                  <PageTitle>Tenants</PageTitle>
                  <PageSubTitle>Create an Tenant to deploy your applications</PageSubTitle>
                  <Button
                     variant="contained"
                     color="primary"
                     style={{
                        marginRight: '20px',
                        marginTop: '-70px',
                        float: 'right',
                     }}
                     onClick={() => setOpenTenantDialog(true)}
                  >
                     Add Tenant
                  </Button>

                  <TableContainer sx={{ marginTop: '50px' }} component={TableContainerDefault}>
                     <Table sx={{ minWidth: 650 }}>
                        <TableHead>
                           <TableRow>
                              <MUITableCellHead align="left">Name</MUITableCellHead>
                              <MUITableCellHead>Repository Group</MUITableCellHead>
                              <MUITableCellHead>Domain</MUITableCellHead>
                              <MUITableCellHead>Status</MUITableCellHead>
                              <MUITableCellHead>Actions</MUITableCellHead>
                           </TableRow>
                        </TableHead>
                        <TableBody>
                           {tenants?.map((tenant: TenantSingleType) => (
                              <TableRow key={tenant.id}>
                                 <TableCell align="left">{tenant.tenantName || '-'}</TableCell>
                                 <TableCell>
                                    <UrlContainer>
                                       <UrlItem>
                                          {truncateString(String(tenant.codeRepositoryGroupName), 38) || ' - '}
                                       </UrlItem>
                                       <UrlItem>
                                          {/* <UrlLabel> Type : </UrlLabel> */}
                                          <div
                                             style={{
                                                display: listOfRepositoriesToDisplay[tenant.repositoryType as RepoType]
                                                   ?.icon
                                                   ? 'inline-flex'
                                                   : 'none',
                                                fontSize: '12px',
                                                fontWeight: 500,
                                                alignItems: 'center',
                                             }}
                                          >
                                             <img
                                                src={
                                                   listOfRepositoriesToDisplay[tenant.repositoryType as RepoType]?.icon
                                                }
                                                style={{
                                                   width: 26,
                                                   height: 25,
                                                   marginRight: '6px',
                                                }}
                                             />
                                             {listOfRepositoriesToDisplay[tenant.repositoryType as RepoType]?.name ||
                                                ' - '}
                                          </div>
                                       </UrlItem>
                                    </UrlContainer>
                                 </TableCell>
                                 <TableCell>{tenant.domainName || '-'}</TableCell>
                                 <TableCell>{displayStatus(tenant)} </TableCell>
                                 <TableCell>
                                    <Button
                                       key={tenant.id}
                                       style={{
                                          marginRight: '0px',
                                          padding: '4px 3px', // Reduced padding
                                          borderRadius: '4px',
                                          color: theme.colors.font2,
                                          border: `1px solid ${theme.colors.boxBorder}`,
                                          // width: '30px', // Reduced width
                                          height: '30px', // Reduced height
                                          minWidth: 'unset', // Ensure width reduction is effective
                                       }}
                                       variant="outlined"
                                       color="primary"
                                       onClick={(e) => handleClickMenu(e, tenant.id, tenant.tenantName)}
                                    >
                                       <MoreHoriz />
                                    </Button>
                                    <MoreInfoMenu
                                       anchorEl={anchorEl}
                                       open={selectedTenantId == tenant.id && Boolean(anchorEl)}
                                       handleCloseMenu={handleCloseMenu}
                                       menuItems={[
                                          {
                                             name: ' Refresh State',
                                             onClickFn: handleRefresh,
                                             disabled: false,
                                             icon: <Refresh fontSize="small" />,
                                          },
                                          {
                                             name: 'Delete',
                                             onClickFn: () => handleOpenReviewModal(true),
                                             disabled:
                                                tenant.tenantStatus == 'ACTIVE' || tenant.tenantStatus == 'ERROR'
                                                   ? false
                                                   : true,
                                             icon: <Delete fontSize="small" style={{ color: theme.colors.red }} />,
                                          },
                                       ]}
                                    />
                                 </TableCell>
                              </TableRow>
                           ))}
                        </TableBody>
                     </Table>
                  </TableContainer>
               </>
            ) : (
               <TopContainer>
                  <Title>No tenants</Title>
                  <Subtitle>Get started by creating a new tenant.</Subtitle>
                  <Button
                     variant="contained"
                     style={{ padding: '10px 16px' }}
                     onClick={() => setOpenTenantDialog(true)}
                  >
                     <AddOutlined style={{ marginRight: '6px' }} />
                     Create Tenant
                  </Button>
               </TopContainer>
            )}
            {openTenantDialog && (
               <CreateTenantDialog
                  openTenantDialog={openTenantDialog}
                  setOpenTenantDialog={setOpenTenantDialog}
                  setIsPreparing={setIsPreparing}
               />
            )}
         </PageContainerApp>
         <ReviewModal
            openReviewModal={reviewModal.status}
            setOpenReviewModal={setReviewModal}
            message={reviewModal.message}
            handleAction={reviewModal.isDelete ? handleDeleteTenant : undefined}
            colorType={reviewModal.isDelete ? 'error' : 'primary'}
            processing={processing}
         />
      </PageContainer>
   )
}

export default Tenants

export const ButtonNoRippleSuccess = styled(Button)`
   & :hover {
      background-color: white !important;
      background: white;
      border: 1px solid ${theme.mui.palette.success.main};
   }

   & .MuiTouchRipple-root {
      display: none;
   }
`
export const ButtonNoRippleInfo = styled(Button)`
   & :hover {
      background-color: transparent !important;
      border: 1px solid ${theme.mui.palette.info.main};
   }

   & .MuiTouchRipple-root {
      display: none;
   }
`
