import React, { useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'

const Login = () => {
   const { loginWithRedirect } = useAuth0()

   // Automatically redirect to login when the component mounts
   useEffect(() => {
      loginWithRedirect()
   }, [loginWithRedirect]) // Only run this effect once after the initial render

   return null // Since there's no button, return null or any other UI component you want
}

export default Login
