import { AxiosInstance } from 'axios'

export const usersApiUse = (api: AxiosInstance) => {
   return {
      getTeams: getTeams(api),
      deleteTeam: deleteTeam(api),
      addTeam: addTeam(api),
      searchUsers: searchUsers(api),
      addUserFromTeam: addUserFromTeam(api),
      deleteUserFromTeam: deleteUserFromTeam(api),
   }
}

const getTeams = (api: AxiosInstance) => async (req?: any) => {
   return api.get('/teams', req)
}

const searchUsers = (api: AxiosInstance) => async (searchString: string) => {
   return api.get(`/teams/users/search?query=${searchString}`)
}

const deleteTeam = (api: AxiosInstance) => async (teamId: number) => {
   return api.delete(`/teams/${teamId}`)
}
const addTeam = (api: any) => async (req?: any) => {
   return api.post('/teams', req)
}

const addUserFromTeam = (api: any) => async (teamId: number, req?: any) => {
   return api.post(`/teams/${teamId}/users`, req)
}

const deleteUserFromTeam = (api: AxiosInstance) => async (teamId: number, userId: number) => {
   return api.delete(`/teams/${teamId}/users/${userId}`)
}
