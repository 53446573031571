import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { addOpacity, theme } from '../../styles/theme';
import { useAuth0 } from '@auth0/auth0-react';
import { Button } from '@mui/material';
import { LogoutOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useAppContext } from './AppContext';

const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.colors.dark};
  padding: 18px 18px;
  height: 24px;
  color: ${theme.colors.white};
`;

const SearchBarContainer = styled.div`
  position: relative;
  flex: 1;
  margin-left: 125px;
  max-width: 22vw;
`;

const SearchBar = styled.input`
  padding: 5px 10px;
  border: 1px solid rgba(255, 255, 255, 0.56);
  height: 24px;
  border-radius: 6px;
  background-color: ${theme.colors.dark};
  color: ${theme.colors.white};
  width: 100%;
  &:focus {
    outline: none;
    background-color: ${theme.colors.dark};
  }
  &::placeholder {
    color: ${addOpacity(theme.colors.white, 70)};
  }
`;

const SearchDropdown = styled.div<{ width: number }>`
  position: absolute;
  top: 36px; /* Adjusted to align below the input */
  left: 0;
  margin-top: 2px;
  width: ${({ width }) => width}px;
  background-color: ${theme.colors.white};
  border: 1px solid ${theme.colors.boxBorder};
  border-radius: 6px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  max-height: 300px;
  overflow-y: auto;
`;

const SearchDropdownItem = styled.div`
  padding: 12px;
  cursor: pointer;
  color: ${theme.colors.text};
  font-size: 14px;
  border-bottom: 1px solid ${theme.colors.boxBorder};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${addOpacity(theme.colors.lightGrey, 10)};
  }
`;

const SearchItemTitle = styled.div`
  font-weight: bold;
  margin-bottom: 4px;
`;

const SearchItemDescription = styled.div`
  font-size: 12px;
  color: ${theme.colors.font2};
`;

const DocumentationButton = styled.button`
  background-color: ${theme.colors.dark};
  color: ${theme.colors.blue};
  border: 1px solid ${addOpacity(theme.colors.blue, 90)};
  border-radius: 8px;
  padding: 7px 16px;
  cursor: pointer;
  font-size: 15px;
  margin-right: 28px;
  &:hover {
    opacity: 0.8;
  }
`;

const ProfileIcon = styled.div`
  background-color: ${theme.colors.profileBackground};
  color: ${theme.colors.white};
  border-radius: 50%;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  cursor: pointer;
`;

const RightPanel = styled.div`
  display: flex;
  align-items: end;
  margin-left: auto;
`;

const Logo = styled.span`
  padding-bottom: 2px;
  font-family: Baumans, sans-serif;
  cursor: pointer;
`;

const Logo1 = styled.span`
  background: linear-gradient(90deg, #da22ff, #ffa824);
  background-clip: text;
  color: #0000;
  font-size: 36px;
  font-weight: 400;
  letter-spacing: 1px;
`;

const Logo2 = styled.span`
  font-weight: 400;
  color: ${theme.colors.white};
  font-size: 36px;
  letter-spacing: 1px;
  padding-left: 1px;
`;

const UserDropdown = styled.div`
  position: absolute;
  top: 64px;
  right: 28px;
  color: black;
  background-color: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  padding: 14px 25px;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  & div {
    margin: 1px 0px;
    text-align: center;
  }
  & button {
    margin-top: 16px;
    & svg {
      margin-right: 6px;
    }
  }
`;

const Header = (props: { onClickFn: () => void }) => {
  const { user, isAuthenticated, logout } = useAuth0();
  const [userDropdownOpen, setUserDropdownOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredResults, setFilteredResults] = useState<searchableItemsType[]>([]);
  const navigate = useNavigate();
  const { setSelectedSidebarItem } = useAppContext();
  const searchBarRef = useRef<HTMLInputElement>(null); // Reference for the search bar

  type searchableItemsType = {
    name: string;
    path: string;
    key: string;
    description: string;
    category: string; // Add category field
  };

  const searchableItems: searchableItemsType[] = [
    { name: 'Get Started', path: '/Home', key: 'getStarted', description: 'Learn how to get started COCREATE platform', category: 'Menu' },
    { name: 'Home', path: '/Home', key: 'getStarted', description: 'Back to homepage', category: 'Menu' },
    { name: 'Tenants', path: '/tenants', key: 'tenant', description: 'Create & manage tenants', category: 'Menu' },
    { name: 'Applications', path: '/applications', key: 'applications', description: 'Create & manage applications', category: 'Menu' },
    { name: 'Repositories', path: '/repositories', key: 'repositories', description: 'Manage code repositories', category: 'Menu' },
    { name: 'Domains', path: '/domains', key: 'domains', description: 'Manage your domains.', category: 'Menu' },
    { name: 'Builds & Pipelines', path: '/code-build', key: 'codeBuild', description: 'Explore CI/CD pipelines', category: 'Menu' },
    { name: 'Kubernetes Workloads', path: '/kube-workloads', key: 'kube-workloads', description: 'Explore Kubernetes workloads', category: 'Menu' },
    { name: 'Deployments', path: '/kube-workloads', key: 'kube-workloads', description: 'Explore deployments', category: 'Menu' },
    { name: 'Observability', path: '/observability', key: 'observability', description: 'Monitor your application logs & metrics', category: 'Menu' },
    { name: 'Integration Hub', path: '/integration-hub', key: 'integrationHub', description: 'Manage third-party integrations', category: 'Menu' },
    { name: 'Teams', path: '/teams', key: 'teams', description: 'Create & manage teams', category: 'Menu' },
    { name: 'Vision Board', path: '/vision-board', key: 'visionBoard', description: 'Convert your vision into reality', category: 'Menu' },
    { name: 'Credentials Vault', path: '/credentials-vault', key: 'credentials-vault', description: 'Manage and access credentials securely with Vault', category: 'Menu' },
    // Add other categories like users, tenants, etc.
    // Example:
    { name: 'John Doe', path: '/users/johndoe', key: 'user_johndoe', description: 'User profile for John Doe.', category: 'User' },
    { name: 'Acme Corp', path: '/tenants/acmecorp', key: 'tenant_acmecorp', description: 'Tenant details for Acme Corp.', category: 'Tenant' },
  ];

  useEffect(() => {
    if (searchTerm.length >= 3) {
      const results = searchableItems.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  }, [searchTerm]);

  const handleLogoClick = () => {
    setSelectedSidebarItem('getStarted');
    navigate('/Home');
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleSearchSelect = (item: searchableItemsType) => {
    setSearchTerm('');
    setFilteredResults([]);
    setSelectedSidebarItem(item.key); // Update context with selected item key
    navigate(item.path);
  };

  const toggleUserDropdown = () => {
    setUserDropdownOpen(!userDropdownOpen);
  };

  return (
    <HeaderContainer>
      <Logo onClick={handleLogoClick}>
        <Logo1>co</Logo1>
        <Logo2>create</Logo2>
      </Logo>
      <SearchBarContainer>
        <SearchBar
          ref={searchBarRef} // Set the reference for the search bar
          placeholder="Search applications, tenants etc..."
          value={searchTerm}
          onChange={handleSearchChange}
        />
        {filteredResults.length > 0 && searchBarRef.current && (
          <SearchDropdown width={searchBarRef.current.offsetWidth}>
            {filteredResults.map((item, index) => (
              <SearchDropdownItem key={index} onClick={() => handleSearchSelect(item)}>
                <SearchItemTitle>{item.name}</SearchItemTitle>
                <SearchItemDescription>{item.description} ({item.category})</SearchItemDescription>
              </SearchDropdownItem>
            ))}
          </SearchDropdown>
        )}
      </SearchBarContainer>
      <RightPanel>
        <DocumentationButton>Documentation</DocumentationButton>
        {isAuthenticated && (
          <>
            <ProfileIcon onClick={toggleUserDropdown}>{String(user?.name)[0]}</ProfileIcon>
            {userDropdownOpen && (
              <UserDropdown>
                <div>
                  <b>{user?.name}</b>
                </div>
                <div>{user?.email}</div>
                <Button onClick={() => logout()} variant="contained">
                  <LogoutOutlined /> Log Out
                </Button>
              </UserDropdown>
            )}
          </>
        )}
      </RightPanel>
    </HeaderContainer>
  );
};

export default Header;
