import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import WrappedApp from './App'
import './index.css'
// import 'virtual:windi.css'
import { BrowserRouter } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'
import { store } from './Configs/store'

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
   <Auth0Provider
      domain={`${import.meta.env.VITE_VAR_DOMAIN_NAME}`}
      clientId={`${import.meta.env.VITE_VAR_CLIENT_ID}`}
      authorizationParams={{
         redirect_uri: `${window.location.origin}/Home`,
         audience: `${import.meta.env.VITE_VAR_CO_CREATE_BACKEND_API_AUDIENCE}`,
      }}
   >
      <Provider store={store}>
         <BrowserRouter>
            <WrappedApp />
         </BrowserRouter>
      </Provider>
   </Auth0Provider>
)
