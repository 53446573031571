export const toCamelCase = (str: string) => {
   return str
      .toLowerCase()
      .replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
      .replace(/^./, (match) => match.toUpperCase())
}
export const truncateString = (str: string, num: number) => {
   if (str.length <= num) {
      return str
   }
   return str.slice(0, num) + '...'
}
