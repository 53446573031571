import { ErrorOutlineOutlined } from '@mui/icons-material'
import React from 'react'
import styled from 'styled-components'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   height: 76%;
`

const Icon = styled.img`
   height: 300px;
   margin-bottom: 5px;
`

const Message = styled.p`
   font-size: 1rem;
   color: #333;
`

interface ErrorScreenProps {
   message?: string
}

const ErrorScreen: React.FC<ErrorScreenProps> = ({
   message = 'Something went wrong',
}) => {
   return (
      <Container>
         <Icon src="static/images/404Error.svg" />
         <Message>{message}</Message>
      </Container>
   )
}

export default ErrorScreen
