import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type initialStateType = {
   searchUsers: {
      loading: boolean
      data: any
      error: null
   }
}
const initialState: initialStateType = {
   searchUsers: {
      loading: false,
      data: [],
      error: null,
   },
}

export const userSlice = createSlice({
   name: 'users',
   initialState,
   reducers: {
      dispatchSearchUsersData: (state, action: PayloadAction<any>) => {
         state.searchUsers.data = action.payload
      },
      setSearchUsersLoading: (state, action: PayloadAction<boolean>) => {
         state.searchUsers.loading = action.payload
      },
      clearUserSearch: (state, action: PayloadAction<boolean>) => {
         state.searchUsers = initialState.searchUsers
      },
   },
})

// Action creators are generated for each case reducer function
export const { dispatchSearchUsersData, setSearchUsersLoading, clearUserSearch } = userSlice.actions

export default userSlice.reducer
