import styled from 'styled-components'
import { addOpacity, convertHexToRGBA, theme } from '../../styles/theme'

// Children Component
export const Children = styled.div<{ displaySidebar: boolean }>`
   width: 100%;
   height: 100%;
   margin-left: ${({ displaySidebar }) => (displaySidebar ? '18rem' : '6rem')}; /* Adjusted margins */

   @media (max-width: 468px) {
      margin-left: 6rem; /* Adjusted margins */
   }
`

export const StyledMenuItem = styled.div<{
   isSubItem: boolean
   active: boolean
   tutorialHighLight: boolean
}>`
   padding: 6px 16px; /* Added more padding to the left and right */
   margin: 5px;
   display: flex;
   align-items: center;
   cursor: pointer;
   background-color: transparent;
   border-radius: 8px;

   &:hover {
      background: linear-gradient(
         90deg,
         ${convertHexToRGBA('#DA22FF', 0.1)} 0%,
         ${convertHexToRGBA('#808080', 0.1)} 50%,
         ${convertHexToRGBA('#FFA824', 0.1)} 100%
      );
      border-radius: 8px;
   }

   padding-left: ${(props) => (props.isSubItem ? '38px' : '24px')};

   ${(props) =>
      props.active &&
      `
      background: linear-gradient(90deg, ${convertHexToRGBA('#DA22FF', 0.1)} 0%, ${convertHexToRGBA(
         '#808080',
         0.1
      )} 50%, ${convertHexToRGBA('#FFA824', 0.1)} 100%);
      border-radius: 8px;
  `}
   ${(props) => props.tutorialHighLight && `border: 5px solid red;`}
   & span {
      padding: 0px 2px 6px 2px;
      color: ${(props) =>
         props.active ? '#000' : theme.colors.font1}; /* Explicitly set text color to black on active */
      font-weight: 500;
      font-size: 15px;
   }
`

export const IconStyled = styled.div<{ isCollapsed: boolean }>`
   margin-right: ${(props) => (props.isCollapsed ? '6px' : '12px')};
   ${(props) => props.isCollapsed && 'padding: 8px 2px;'}
   margin-top: 0px;
`

export const SidebarContainer = styled.div<{ isCollapsed: boolean }>`
   width: ${(props) => (props.isCollapsed ? '5.2vw' : '25vw')}; /* Adjusted widths */
   min-width: ${(props) => (props.isCollapsed ? '82px' : '220px')}; /* Adjusted min-widths */
   max-width: ${(props) => (props.isCollapsed ? '98px' : '300px')}; /* Adjusted max-widths */
   display: flex;
   flex-direction: column;
   background-color: ${theme.colors.white};
   border-right: 1px solid ${convertHexToRGBA(theme.colors.dark, 15)};
   padding-top: 30px;
   overflow-y: auto;
   height: calc(99.8vh - 94px);
   transition: width 0.1s;
   box-shadow: 5px 0 15px rgba(0, 0, 0, 0.2); /* Stronger shadow effect on the right side */
`

export const CollapseIcon = styled.div`
   cursor: pointer;
   padding: 10px;
   display: flex;
   justify-content: center;
`

export const SubMenu = styled.div<{ open: boolean; isCollapsed: boolean }>`
   display: ${(props) => (props.open && !props.isCollapsed ? 'block' : 'none')};
`
export const CollapseItems = styled.div`
   padding: 15px 30px;
   margin-top: auto;
   margin-bottom: 10px;
   border-top: 1px solid ${theme.colors.boxBorder};
   cursor: pointer;
   & svg {
      margin-left: auto;
      float: right;
   }
`
