import React, { useEffect, useState } from 'react'
import {
   PageContainer,
   PageContainerApp,
   PageSubTitle,
   PageTitle,
   TableContainerDefault,
} from '../../Devops/CommonStyles'
import { theme } from '../../../styles/theme'
import {
   Cancel,
   CheckCircle,
   MoreHoriz,
   OpenInNew,
   AccessTime,
   CalendarToday,
   PlayCircleOutline,
} from '@mui/icons-material'
import {
   Button,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Menu,
   MenuItem,
   ListItemIcon,
   ListItemText,
   Grid,
   Card,
   CardContent,
   Typography,
} from '@mui/material'
import { useApplicationApiClient } from '../../../Configs/API/createAxiosConfig'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import ErrorScreen from '../../../components/Common/ErrorScreen'
import styled from 'styled-components'
import useAlert from '../../../components/Extras/Alerts/useAlert'

interface applicationType {
   id: number
   clientId: string
   appName: string
   technology: string
   appStatus: string
   tenantId: number
   appType: string
   appProjectId: number | string | null
   webhookToken: string | null
   appAttributes: any | null
   appBuildAttributes: any | null
}

const CodeAndBuild: React.FC = () => {
   const [applications, setApplications] = useState<applicationType[]>([])
   const [loading, setLoading] = useState<boolean>(true)
   const [error, setError] = useState<string | null>(null)
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
   const [selectedAppId, setSelectedAppId] = useState<number | null>(null)
   const [selectedAppName, setSelectedAppName] = useState<string | null>(null)
   const { showAlert } = useAlert()

   const { getApplicationPipelines } = useApplicationApiClient()

   const fetchApplications = async () => {
      try {
         const response = await getApplicationPipelines()
         if (response.status === 200) {
            setApplications(response.data)
         } else {
            setError('Failed to load applications. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetching Applications', {
               header: response?.response?.data?.message,
            })
         }
      } catch (error) {
         console.error('Error fetching applications', error)
         setError('Failed to load applications. Please try again later.')
         showAlert('Failed to load Applications', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   const handleClickMenu = (event: React.MouseEvent<HTMLElement>, appId: number, appName: string) => {
      setAnchorEl(event.currentTarget)
      setSelectedAppId(appId)
      setSelectedAppName(appName)
      console.log('Selected App:', appId, appName)
   }

   const handleCloseMenu = () => {
      setAnchorEl(null)
   }

   const rerunPipeline = (appId: number) => {
      console.log('Rerun pipeline for app id:', appId)
      // Add your rerun logic here
      handleCloseMenu()
   }

   useEffect(() => {
      document.title = 'COCREATE | Builds & Pipelines'
      fetchApplications()
   }, [])

   if (loading) {
      return <SimpleLoadingScreen />
   }

   if (error) {
      return <ErrorScreen message={error} />
   }

   const toCamelCase = (str: string) => {
      return str
         .toLowerCase()
         .replace(/(_\w)/g, (matches) => matches[1].toUpperCase())
         .replace(/^./, (match) => match.toUpperCase())
   }

   const truncateString = (str: string, num: number) => {
      if (str.length <= num) {
         return str
      }
      return str.slice(0, num) + '...'
   }

   const formatTime = (seconds: number) => {
      const minutes = Math.floor(seconds / 60)
      const secs = seconds % 60
      return `${minutes}m ${secs}s`
   }

   const calculateAveragePassRate = () => {
      if (applications.length === 0) return null
      const total = applications.length
      const passed = applications.filter((app) => app.appBuildAttributes?.objectAttributes.status === 'success').length
      return (passed / total) * 100
   }

   const calculateAverageDuration = () => {
      if (applications.length === 0) return null
      const totalDuration = applications.reduce((sum, app) => {
         return sum + (app.appBuildAttributes?.objectAttributes.duration || 0)
      }, 0)
      return totalDuration / applications.length
   }

   const averagePassRate = calculateAveragePassRate()
   const averageDuration = calculateAverageDuration()

   return (
      <PageContainer>
         <PageContainerApp>
            <PageTitle>Builds & Pipelines</PageTitle>
            <PageSubTitle>Summary of recent builds and pipelines for all applications</PageSubTitle>
            <Grid container spacing={3}>
               <Grid item xs={12} md={6}>
                  <Card>
                     <CardContent>
                        <Typography variant="h6" gutterBottom>
                           Average Pipeline Pass Rate
                        </Typography>
                        <Typography variant="h4">
                           {averagePassRate !== null ? `${averagePassRate.toFixed(2)}%` : 'No data available'}
                        </Typography>
                     </CardContent>
                  </Card>
               </Grid>
               <Grid item xs={12} md={6}>
                  <Card>
                     <CardContent>
                        <Typography variant="h6" gutterBottom>
                           Average Execution Duration
                        </Typography>
                        <Typography variant="h4">
                           {averageDuration !== null ? formatTime(averageDuration) : 'No data available'}
                        </Typography>
                     </CardContent>
                  </Card>
               </Grid>
            </Grid>
            <TableContainer sx={{ marginTop: '50px' }} component={TableContainerDefault}>
               <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                     {applications.map((app) => (
                        <TableRow key={app.id}>
                           <TableCell align="center">
                              {app.appBuildAttributes ? (
                                 <StatusWrapper status={app?.appBuildAttributes?.objectAttributes?.status}>
                                    {app.appBuildAttributes.objectAttributes.status === 'success' ? (
                                       <CheckCircle fontSize="small" />
                                    ) : (
                                       <Cancel fontSize="small" />
                                    )}
                                    <StatusLabel>
                                       {app.appBuildAttributes.objectAttributes.status === 'success'
                                          ? 'Passed'
                                          : 'Failed'}
                                    </StatusLabel>
                                 </StatusWrapper>
                              ) : (
                                 <span>No pipeline info yet</span>
                              )}
                           </TableCell>
                           <TableCell align="center">
                              <ApplicationInfoContainer>
                                 <div className="col">
                                    <span
                                       style={{
                                          fontWeight: '500',
                                          fontSize: '18px',
                                       }}
                                    >
                                       {app.appName}
                                    </span>
                                    <Technology>{app.technology}</Technology>
                                 </div>
                              </ApplicationInfoContainer>
                           </TableCell>
                           <TableCell align="center">
                              {app.appBuildAttributes ? (
                                 <UrlContainer>
                                    <UrlItem>
                                       <UrlLabel>Pipeline Url:</UrlLabel>
                                       <UrlLink
                                          onClick={() =>
                                             window.open(app.appAttributes?.repositoryUrl.replace('.git', '/pipelines'))
                                          }
                                       >
                                          {truncateString(
                                             app.appAttributes?.repositoryUrl.replace('.git', '/pipelines'),
                                             35
                                          )}
                                          <OpenInNew
                                             style={{
                                                fontSize: '15px',
                                                marginLeft: '4px',
                                             }}
                                          />
                                       </UrlLink>
                                    </UrlItem>
                                    <UrlItem>
                                       <UrlLabel>Commit:</UrlLabel>
                                       <UrlLink
                                          onClick={() =>
                                             window.open(app.appAttributes?.repositoryUrl.replace('.git', '/commits'))
                                          }
                                       >
                                          {truncateString(app.appBuildAttributes.commit.message, 35)}
                                          <OpenInNew
                                             style={{
                                                fontSize: '15px',
                                                marginLeft: '4px',
                                             }}
                                          />
                                       </UrlLink>
                                    </UrlItem>
                                 </UrlContainer>
                              ) : (
                                 <span>-</span>
                              )}
                           </TableCell>
                           <TableCell align="center">
                              {app.appBuildAttributes ? (
                                 <>
                                    <TimeContainer>
                                       <AccessTime style={{ fontSize: '16px', marginRight: '4px' }} />
                                       {formatTime(app.appBuildAttributes.objectAttributes.duration)}
                                    </TimeContainer>
                                    <TimeContainer>
                                       <CalendarToday style={{ fontSize: '16px', marginRight: '4px' }} />
                                       {new Date(app.appBuildAttributes.commit.timestamp).toLocaleString()}
                                    </TimeContainer>
                                 </>
                              ) : (
                                 <span>-</span>
                              )}
                           </TableCell>
                           <TableCell align="center" style={{ width: '150px' }}>
                              <Button
                                 style={{
                                    marginRight: '0px',
                                    padding: '4px 2px',
                                    borderRadius: '4px',
                                    color: theme.colors.font2,
                                    border: `1px solid ${theme.colors.boxBorder}`,
                                    width: '30px',
                                    height: '30px',
                                    minWidth: 'unset',
                                 }}
                                 variant="outlined"
                                 color="primary"
                                 onClick={(e) => handleClickMenu(e, app.id, app.appName)}
                              >
                                 <MoreHoriz />
                              </Button>
                              <Menu
                                 anchorEl={anchorEl}
                                 open={Boolean(anchorEl)}
                                 onClose={handleCloseMenu}
                                 anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                                 transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                 }}
                              >
                                 <MenuItem onClick={() => rerunPipeline(app.id)}>
                                    <ListItemIcon>
                                       <PlayCircleOutline fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText>Run Pipeline</ListItemText>
                                 </MenuItem>
                              </Menu>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
         </PageContainerApp>
      </PageContainer>
   )
}

export default CodeAndBuild

const ApplicationInfoContainer = styled.div`
   display: flex;
   align-items: center;
`

const Technology = styled.div`
   color: #708090;
   font-size: 12px;
`

const UrlContainer = styled.div`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
`

const UrlItem = styled.div`
   margin: 4px 0;
   display: flex;
   align-items: center;
   white-space: nowrap;
`

const UrlLabel = styled.span`
   margin-right: 8px;
`

const UrlLink = styled.span`
   font-size: 12px;
   color: #708090;
   cursor: pointer;
   display: flex;
   align-items: center;
   &:hover {
      text-decoration: underline;
   }
`

const StatusWrapper = styled.div<{ status: string }>`
   display: flex;
   align-items: center;
   background-color: ${({ status }) => (status === 'success' ? '#d4edda' : '#f8d7da')};
   color: ${({ status }) => (status === 'success' ? '#155724' : '#721c24')};
   padding: 4px 8px;
   border-radius: 4px;
   font-weight: 500;
   justify-content: center;
`

const StatusLabel = styled.span`
   margin-left: 4px;
`

const TimeContainer = styled.div`
   display: flex;
   align-items: center;
   margin: 4px 0;
`
