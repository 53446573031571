export const radioImageHandler = (logoSrc: string, logoText: string, availability: boolean) => (
   <div
      style={{
         display: 'flex',
         alignItems: 'center',
      }}
   >
      <img
         src={logoSrc}
         alt="Gitlab Logo"
         style={{
            marginRight: '4px',
            width: '40px',
            height: '40px',
            opacity: availability ? '100%' : '50%',
         }}
      />
      {logoText}
   </div>
)
