import { configureStore } from '@reduxjs/toolkit'
import teamSlice from '../ReduxSlice/teamSlice'
import productTour from '../ReduxSlice/productTour'
import tenantSlice from '../ReduxSlice/tenantSlice'
import applicationSlice from '../ReduxSlice/ApplicationSlice'
import userSlice from '../ReduxSlice/usersSlice'
import visionBoardSlice from '../ReduxSlice/visionBoardSlice'

export const store = configureStore({
   reducer: {
      productTour: productTour,
      tenants: tenantSlice,
      applications: applicationSlice,
      teams: teamSlice,
      users: userSlice,
      visionBoard: visionBoardSlice,
   },
})
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
