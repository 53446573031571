export const theme = {
   colors: {
      text: '#000000',
      primary: '#0000ff',
      secondary: '#a9a9a9',
      hover: '#f0f0f0',
      activeBackground: '#e0e0e0',

      searchBackground: '#d3d3d3',

      profileBackground: '#a9a9a9',
      tutorialBackground: '#F5F5F5',
      sidebarIconColor: '#789',
      // From Figma
      font1: '#444444',
      font2: '#6C6C6C',
      iconGrey: '00000096',

      white: '#ffffff',
      blue: '#3f59e4', //"#4C89FF", f99442
      green: '#5CB85C',
      red: '#DC3545',
      lightGreen: '#5cb85c3b',
      dark: '#000000',

      black: '#212121',
      grey: '#424242',
      lightGrey: '#61616169',

      primary1: '#3f59e4',

      boxBorder: 'rgba(0, 0, 0, 0.20)',
   },
   mui: {
      palette: {
         primary: {
            main: '#000000',
         },
         secondary: {
            main: '#5CB85C',
         },
         info: {
            main: '#6C6C6C',
         },
         error: {
            main: '#DC3545',
         },
         success: {
            main: '#2e7d3280',
         },
      },
   },
}

export function addOpacity(color: string, opacity: number | bigint | any) {
   // coerce values so it is between 0 and 1.
   const _opacity = Math.round(Math.min(Math.max(opacity ?? 1, 0), 1) * 255)
   return color + _opacity.toString(16).toUpperCase()
}
export const convertHexToRGBA = (hexCode: string, opacity = 1) => {
   let hex = hexCode.replace('#', '')

   if (hex.length === 3) {
      hex = `${hex[0]}${hex[0]}${hex[1]}${hex[1]}${hex[2]}${hex[2]}`
   }

   const r = parseInt(hex.substring(0, 2), 16)
   const g = parseInt(hex.substring(2, 4), 16)
   const b = parseInt(hex.substring(4, 6), 16)

   /* Backward compatibility for whole number based opacity values. */
   if (opacity > 1 && opacity <= 100) {
      opacity = opacity / 100
   }

   return `rgba(${r},${g},${b},${opacity})`
}
