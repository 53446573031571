import { useAuth0 } from '@auth0/auth0-react'
import useFetchToken from './Auth0TokenUtil'
import { createApiClient } from './createApiClient'
import { tenantApiUse } from './useTenantsApi'
import { applicationApiUse } from './useApplicationsApi'
import { usersApiUse } from './useUsersApi'

const createAxiosClient = () => {
   const fetchToken = useFetchToken()
   const fetchAccessToken = async () => fetchToken()
   const { user } = useAuth0()

   const tenantClientUse = tenantApiUse(
      createApiClient({
         endpoint: import.meta.env.VITE_VAR_TMS_BASE_URL,
         fetchAccessToken,
         user,
      })
   )

   const applicationClientUse = applicationApiUse(
      createApiClient({
         endpoint: import.meta.env.VITE_VAR_AMS_BASE_URL,
         fetchAccessToken,
         user,
      })
   )

   const usersClientUse = usersApiUse(
      createApiClient({
         endpoint: import.meta.env.VITE_VAR_USER_BASE_URL,
         fetchAccessToken,
         user,
      })
   )

   return { tenantClientUse, applicationClientUse, usersClientUse }
}

export const useTenantApiClient = () => createAxiosClient().tenantClientUse

export const useApplicationApiClient = () => createAxiosClient().applicationClientUse

export const useUserApiClient = () => createAxiosClient().usersClientUse
