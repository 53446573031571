import { useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { theme } from '../../styles/theme'
import { useAppContext } from '../Common/AppContext'
import { CollapseItems, IconStyled, SidebarContainer, StyledMenuItem, SubMenu } from './SidebarStyles'
import { SidebarItem, sidebarConfig } from './SidebarData'
import {
   KeyboardArrowDown,
   KeyboardArrowRightOutlined,
   KeyboardDoubleArrowRightOutlined,
   KeyboardDoubleArrowLeftOutlined,
} from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { SvgIcon } from '@mui/material'
import useWindowSize from '../helpers/useWindowSize'
import useSessionStorage from '../helpers/useSessionStorage'

const Sidebar = () => {
   const [isCollapsed, setIsCollapsed] = useState(false)
   const { width } = useWindowSize()
   const { selectedSidebarItem, setSelectedSidebarItem, highlightSidebarItem } = useAppContext()

   const setsideBarColapsed = (val: boolean) => {
      sessionStorage.setItem('sideBarColapsed', `${val}`)
   }
   useEffect(() => {
      if (width < 1000) {
         setIsCollapsed(true)
         setsideBarColapsed(true)
      }
   }, [width])

   const collapseSidebar = () => {
      setIsCollapsed(!isCollapsed)
      setsideBarColapsed(!isCollapsed)
   }

   const [openSubMenus, setOpenSubMenus] = useState<{ [key: string]: boolean }>({})

   const navigate = useNavigate()
   const toggleSubMenu = (key: string) => {
      setOpenSubMenus((prev) => ({ ...prev, [key]: !prev[key] }))
   }

   const handleMenuItemClick = (item: SidebarItem) => {
      setSelectedSidebarItem(item.key) // Update context with selected item key
      navigate(item.route)
   }

   const displayArrowForSubmenu = (isOpen: boolean) => {
      return isOpen ? (
         <KeyboardArrowDown style={{ marginLeft: 'auto', color: theme.colors.lightGrey }} />
      ) : (
         <KeyboardArrowRightOutlined style={{ marginLeft: 'auto', color: theme.colors.font2 }} />
      )
   }

   const renderMenuItems = (items: SidebarItem[], isSubItem = false) => {
      return items.map((item) => (
         <div key={item.key} className={`sidebar-item-${item.key}`}>
            <StyledMenuItem
               active={selectedSidebarItem === item.key}
               isSubItem={isSubItem}
               tutorialHighLight={highlightSidebarItem === item.key}
               onClick={() => {
                  if (item.subItems) {
                     toggleSubMenu(item.key)
                     isCollapsed && collapseSidebar()
                  } else {
                     handleMenuItemClick(item)
                  }
               }}
            >
               <IconStyled isCollapsed={isCollapsed}>
                  <SvgIcon style={{ fontSize: '20px' }}>{item.icon2}</SvgIcon>
               </IconStyled>
               <span>{!isCollapsed && item.label}</span>
               {item.subItems && !isCollapsed && displayArrowForSubmenu(openSubMenus[item.key])}
            </StyledMenuItem>
            {item.subItems && !isCollapsed && (
               <SubMenu open={openSubMenus[item.key]} isCollapsed={isCollapsed}>
                  {renderMenuItems(item.subItems, true)}
               </SubMenu>
            )}
         </div>
      ))
   }

   return (
      <ThemeProvider theme={theme}>
         <SidebarContainer isCollapsed={isCollapsed}>
            <div>{renderMenuItems(sidebarConfig)}</div>
            <CollapseItems onClick={collapseSidebar}>
               {!isCollapsed ? (
                  <KeyboardDoubleArrowLeftOutlined color="primary" />
               ) : (
                  <KeyboardDoubleArrowRightOutlined color="primary" />
               )}
            </CollapseItems>
         </SidebarContainer>
      </ThemeProvider>
   )
}

export default Sidebar
