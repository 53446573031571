import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface productTourType {
   run: boolean
   stepIndex: number
   tourActive: boolean
}

const initialState: productTourType = {
   run: false,
   stepIndex: 0,
   tourActive: false,
}

export const productTour = createSlice({
   name: 'productTour',
   initialState,
   reducers: {
      setTour: (state, action: PayloadAction<boolean>) => {
         state.run = action.payload
      },
      decrement: (state) => {},
      incrementByAmount: (state, action: PayloadAction<number>) => {},
   },
})

// Action creators are generated for each case reducer function
export const { setTour, decrement, incrementByAmount } = productTour.actions

export default productTour.reducer
