import React, { useState } from 'react'
import { Button, TableCell, TableRow, MenuItem, Menu, ListItemIcon, ListItemText } from '@mui/material'
import { MoreHoriz, Delete, GroupOutlined, AddBusinessOutlined, ManageAccountsOutlined } from '@mui/icons-material'
import { theme } from '../../../styles/theme'
import { TeamType } from '../TeamManagement'
import ReviewModal from '../../Applications/ReviewModal/ReviewModal'
import styled from 'styled-components'
import { useUserApiClient } from '../../../Configs/API/createAxiosConfig'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { deleteFromTeamAPIData } from '../../../Configs/ReduxSlice/teamSlice'
import ManageTeamTeanants from './ManageTeamTenants'
import ManageTeamUsers from './ManageTeamUsers'
import useAlert from '../../../components/Extras/Alerts/useAlert'

type ManageTeamDialogType = {
   status: boolean
   edit: boolean
}

const TeamInTable = ({ team }: { team: TeamType }) => {
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
   const [selectedTeamId, setSelectedTeamId] = useState<number | null>(null)
   const [reviewModal, setReviewModal] = useState({
      status: false,
      message: '',
   })
   const [openManageUsers, setOpenManageUsers] = useState<ManageTeamDialogType>({
      status: false,
      edit: false,
   })
   const [openManageTenants, setOpenManageTenants] = useState<ManageTeamDialogType>({
      status: false,
      edit: false,
   })
   const [apierror, setError] = useState<string | null>(null)
   const { showAlert } = useAlert()
   const teamsService = useUserApiClient()
   const dispatch = useAppDispatch()

   const handleClickMenu = (event: React.MouseEvent<HTMLElement>, teamId: number) => {
      setAnchorEl(event.currentTarget)
      setSelectedTeamId(teamId)
   }

   const handleCloseMenu = () => {
      setAnchorEl(null)
   }

   const handleDelete = () => {
      if (selectedTeamId !== null) {
         deleteTeam(selectedTeamId)
         handleCloseReviewModal()
      }
   }

   const handleOpenReviewModal = () => {
      setReviewModal({
         status: true,
         message: `Are you sure you want to delete the team? This action cannot be undone.`,
      })
      handleCloseMenu()
   }

   const handleCloseReviewModal = () => {
      setReviewModal({ status: false, message: '' })
      setSelectedTeamId(null)
   }

   const deleteTeam = async (selectedTeamId: number) => {
      try {
         // setteamsLoading(true)
         const response = await teamsService.deleteTeam(selectedTeamId)
         if (response.status == 200) {
            dispatch(deleteFromTeamAPIData(selectedTeamId))
            // setteamsLoading(false)
         } else {
            setError('Failed to load deleteTeam. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to Delete Team', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error fetching delete Team', err)
         setError('Failed to load delete Team. Please try again later.')
         showAlert('Failed to Delete Team', { extraInfo: 'Please try again later' })
      } finally {
         // setteamsLoading(false)
      }
   }

   return (
      <>
         <TableRow key={team.id}>
            <TableCell>
               <GroupOutlined style={{ margin: '8px 12px -6px 0px' }} /> {team.name}
            </TableCell>
            <TableCell>
               <UrlLink onClick={() => setOpenManageTenants({ status: true, edit: false })}>View Tenants</UrlLink>
            </TableCell>
            <TableCell>
               <UrlLink onClick={() => setOpenManageUsers({ status: true, edit: false })}>View Users</UrlLink>
            </TableCell>
            <TableCell align="right">
               <Button
                  style={{
                     padding: '4px 2px',
                     borderRadius: '4px',
                     color: theme.colors.font2,
                     border: `1px solid ${theme.colors.boxBorder}`,
                     width: '30px',
                     height: '30px',
                     minWidth: 'unset',
                     marginRight: '8px',
                  }}
                  variant="outlined"
                  color="primary"
                  onClick={(e) => handleClickMenu(e, team.id)}
               >
                  <MoreHoriz />
               </Button>
               <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                  anchorOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
                  transformOrigin={{
                     vertical: 'top',
                     horizontal: 'right',
                  }}
               >
                  <MenuItem onClick={handleOpenReviewModal}>
                     <ListItemIcon>
                        <Delete fontSize="small" />
                     </ListItemIcon>
                     <ListItemText>Delete</ListItemText>
                  </MenuItem>
                  <MenuItem onClick={() => setOpenManageTenants({ status: true, edit: true })}>
                     <ListItemIcon>
                        <AddBusinessOutlined fontSize="small" />
                     </ListItemIcon>
                     <ListItemText>Manage Tenants</ListItemText>
                  </MenuItem>

                  <MenuItem onClick={() => setOpenManageUsers({ status: true, edit: true })}>
                     <ListItemIcon>
                        <ManageAccountsOutlined fontSize="small" />
                     </ListItemIcon>
                     <ListItemText>Manage Users</ListItemText>
                  </MenuItem>
               </Menu>
            </TableCell>
         </TableRow>
         {reviewModal.status && (
            <ReviewModal
               openReviewModal={reviewModal.status}
               setOpenReviewModal={setReviewModal}
               message={reviewModal.message}
               handleAction={handleDelete}
               colorType="error"
            />
         )}
         {openManageTenants.status && (
            <ManageTeamTeanants
               open={openManageTenants.status}
               closeModal={() => setOpenManageTenants({ status: false, edit: false })}
               data={team.tenants}
               edit={openManageTenants.edit}
               teamId={team.id}
            />
         )}

         {openManageUsers.status && (
            <ManageTeamUsers
               open={openManageUsers.status}
               closeModal={() => setOpenManageUsers({ status: false, edit: false })}
               data={team.users}
               edit={openManageUsers.edit}
               teamId={team.id}
            />
         )}
      </>
   )
}

export default TeamInTable

const UrlLink = styled.span`
   // font-size: 13px;
   color: ${theme.colors.font2};
   cursor: pointer;
   font-weight: 600;
   display: inline-flex;
   text-decoration: underline;

   &:hover {
      text-decoration: underline;
   }
`
