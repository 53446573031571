import React, { useState } from 'react'
import Joyride, { STATUS, CallBackProps, Step } from 'react-joyride'

import styled, { ThemeProvider as SCThemeProvider } from 'styled-components'
import { ThemeProvider as MUIThemeProvider } from '@mui/material/styles'
import CustomTooltip from './CustomTooltip'
import { theme } from '../../../styles/theme'
import { setTour } from '../../../Configs/ReduxSlice/productTour'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import { useAppSelector } from '../../../Configs/store/config/useAppSelector'

const steps: Step[] = [
   {
      target: '.sidebar-item-getStarted',
      title: 'Get Started',
      content: 'Click here to create an application',
   },
   {
      target: '.sidebar-item-applications',
      title: 'Applications',
      content: 'Here you can manage your applications',
   },
   {
      target: '.sidebar-item-devops',
      title: 'DevOps',
      content: 'Manage your DevOps pipelines here',
   },
]

const JoyrideContainer: React.FC = () => {
   const dispatch = useAppDispatch()
   const { run } = useAppSelector((state) => state.productTour)
   const handleJoyrideCallback = (data: any) => {
      const { status, index } = data
      const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED]
      console.log('status', status, index)

      if (finishedStatuses.includes(status)) {
         dispatch(setTour(false))
      }
   }

   return (
      <Joyride
         steps={steps}
         run={run}
         continuous={true}
         showSkipButton={true}
         callback={handleJoyrideCallback}
         tooltipComponent={CustomTooltip}
         styles={{
            options: {
               zIndex: 10000,
            },
            overlay: {
               backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
         }}
      />
   )
}

export default JoyrideContainer
