import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type initialStateType = {
   visionBoardFileData: any[]
}
const initialState: initialStateType = {
   visionBoardFileData: [],
}

export const visionBoardSlice = createSlice({
   name: 'visionBoard',
   initialState,
   reducers: {
      dispatchFileNodes: (state, action: PayloadAction<any[]>) => {
         state.visionBoardFileData = action.payload
      },
   },
})

// Action creators are generated for each case reducer function
export const { dispatchFileNodes } = visionBoardSlice.actions

export default visionBoardSlice.reducer
