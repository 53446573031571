import { AxiosInstance } from 'axios'

export const tenantApiUse = (api: AxiosInstance) => {
   return {
      getTenants: getTenants(api),
      getRepositories: getRepositories(api),
      getDomains: getDomains(api),
      saveDomains: saveDomains(api),
      verifyDomain: verifyDomain(api),
      deleteDomain: deleteDomain(api),
      getRepositoryGroups: getRepositoryGroups(api),
      addTenant: addTenant(api),
      deleteTenant: deleteTenant(api),
      getKubeWorkloads: getKubeWorkloads(api),
      checkGroupPat: checkGroupPat(api),
      generateVaultToken: generateVaultToken(api),
   }
}

const getTenants = (api: any) => async (req?: any) => {
   return api.get('/tenants', req)
}
const getDomains = (api: any) => async (req?: any) => {
   return api.get('/domains', req)
}

const deleteDomain = (api: any) => async (domainId: number) => {
   return api.delete(`/domains/${domainId}`);
};

const verifyDomain = (api: any) => async (req?: any) => {
   return api.get('/domains/verify', req)
}

const saveDomains = (api: any) => async (req?: any) => {
   return api.post('/domains', req)
}

const getRepositories = (api: any) => async (req?: any) => {
   return api.get('/repositories', req)
}

const getRepositoryGroups = (api: any) => async (codeRepositoryId: number) => {
   return api.get(
      `/repositories/top-level-groups?repository_type=${codeRepositoryId}`
   )
}

const addTenant = (api: any) => async (req?: any) => {
   return api.post('/tenants', req)
}

const deleteTenant = (api: any) => async (tenantId?: any) => {
   return api.delete(`/tenants/${tenantId}`)
}

const getKubeWorkloads = (api: any) => async (tenantId: string) => {
    return api.get(`/tenants/${tenantId}/kube-workloads`);
};

const checkGroupPat = (api: any) => async (repositoryId: number, groupPath: string) => {
   return api.get(`/repositories/${repositoryId}/validate-cicd-variable?groupPath=${groupPath}`);
}

const generateVaultToken = (api: any) => async (tenantId: number) => {
   return api.post(`/tenants/${tenantId}/vault-token`);
}
