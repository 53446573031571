import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { applicationType } from '../../pages/Applications/Applications'

type initialStateType = {
   applicationAPIData: applicationType[]
}
const initialState: initialStateType = {
   applicationAPIData: [],
}

export const applicationSlice = createSlice({
   name: 'applications',
   initialState,
   reducers: {
      dispatchApplicationsAPIData: (
         state,
         action: PayloadAction<applicationType[]>
      ) => {
         state.applicationAPIData = action.payload
      },
      addApplicationSlice: (state, action: PayloadAction<applicationType>) => {
         state.applicationAPIData = [
            ...state.applicationAPIData,
            action.payload,
         ]
      },
   },
})

// Action creators are generated for each case reducer function
export const { dispatchApplicationsAPIData, addApplicationSlice } =
   applicationSlice.actions

export default applicationSlice.reducer
