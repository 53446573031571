import styled from 'styled-components'
import { theme } from '../../styles/theme'
import { TableCell } from '@mui/material'

export const PageContainer = styled.div`
   width: 100%;
`
export const PageContainerApp = styled.div`
   max-width: 1000px;
   margin: 30px auto;
   @media (min-width: 1300px) {
      margin: 30px ${String(sessionStorage.getItem('sideBarColapsed')) == 'true' ? '30px' : 'auto'};
      max-width: 1200px;
   }
`

export const PageTitle = styled.h2`
   color: ${theme.colors.dark};
   margin-bottom: 8px;
   font-size: 1.42em;
`

export const PageSubTitle = styled.p`
   color: ${theme.colors.font1};
   width: 70%;
   margin-bottom: 30px;
   font-size: 15.2px;
   margin-top: 2px;
`

export const DevopsCard = styled.div`
   border-radius: 8px;
   box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
   border: 1px solid rgba(0, 0, 0, 0.1);
   background: #fff;
   mix-blend-mode: darken;
   margin: 60px 0;
   padding: 20px;
`
export const TableContainerDefault = styled.div`
   border-radius: 8px;
   box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.1);
   border: 1px solid rgba(0, 0, 0, 0.1);
   background: #fff;
   mix-blend-mode: darken;
`

export const DevopsTable = styled.table`
   width: 100%;
   border-collapse: collapse;
`

export const DevopsTableRow = styled.tr`
   border-bottom: 1px solid ${theme.colors.boxBorder};
   &:last-child {
      border: none;
   }
`

export const DevopsTableData = styled.td`
   padding: 20px 20px;
   text-align: center;
`
export const DevopsTableDataWithMoreData = styled.tr`
   padding: 20px 20px;
   display: flex;
   flex-direction: column;
   font-size: 14px;
   text-align: center;
   align-content: center;
`

export const DevopsTableHeadData = styled.td`
   padding: 12px 20px;
   font-weight: 500;
   font-size: 15px;
   text-transform: uppercase;
   text-align: center;
   color: ${theme.colors.font2};
`
export const MUITableCellHead = styled(TableCell)`
   color: ${theme.colors.font2} !important;
`
