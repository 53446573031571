import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
   PageContainer,
   PageContainerApp,
   PageSubTitle,
   PageTitle,
   TableContainerDefault,
} from '../../Devops/CommonStyles'
import {
   Box,
   Button,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Menu,
   MenuItem,
   TextField,
   ListItemIcon,
   ListItemText,
} from '@mui/material'
import { AddOutlined, MoreHoriz, Delete, Verified } from '@mui/icons-material'
import { theme } from '../../../styles/theme'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import ErrorScreen from '../../../components/Common/ErrorScreen'
import { useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { useAuth0 } from '@auth0/auth0-react'
import SnackbarNotification from '../../../components/Common/SnackbarNotification'
import ReviewModal from '../../Applications/ReviewModal/ReviewModal'
import useAlert from '../../../components/Extras/Alerts/useAlert'

export interface DomainType {
   txtCode: string
   domainName: string
   status: string
   id: number
}

const Domains = () => {
   const { user } = useAuth0()

   const [domain, setDomain] = useState('mydomain.com')
   const [domains, setDomains] = useState<DomainType[]>([])
   const [loading, setLoading] = useState<boolean>(true)
   const [txtCode, setTxtCode] = useState('')
   const [error, setError] = useState<string | null>(null)
   const tenantService = useTenantApiClient()
   const [verificationMessage, setVerificationMessage] = useState('')
   const [isSuccess, setIsSuccess] = useState(false)
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
   const [selectedDomainIndex, setSelectedDomainIndex] = useState<number | null>(null)
   const [reviewModal, setReviewModal] = useState({
      status: false,
      message: '',
      isDelete: false,
   })
   const [processing, setProcessing] = useState(false)
   const { showAlert } = useAlert()

   const fetchDomains = async () => {
      try {
         const response = await tenantService.getDomains()
         if (response.status === 200) {
            setDomains(response.data)
         } else {
            setError('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch domains', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to fetch domains', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Domains'
      fetchDomains()
   }, [user])

   const verifyDomain = async (domainIndex: number) => {
      const domainToVerify = domains[domainIndex]

      if (user?.sub) {
         try {
            const response = await tenantService.verifyDomain({
               domainName: domainToVerify.domainName,
               txtCode: domainToVerify.txtCode,
               clientId: user.sub,
            })

            if (response.status === 200) {
               const updatedDomains = domains.map((d, idx) => (idx === domainIndex ? { ...d, status: 'Verified' } : d))
               setDomains(updatedDomains)
               const nameserverMessage = `Domain verification successful`

               setVerificationMessage(nameserverMessage)
               setIsSuccess(true)
            } else {
               setVerificationMessage('Domain verification failed.')
               setIsSuccess(false)
            }
         } catch (error) {
            setVerificationMessage('Error during domain verification. Please try again.')
            setIsSuccess(false)
            console.error('Error verifying domain:', error)
         }
      }
   }

   const handleAddDomain = () => {
      if (domain && user?.sub) {
         var newTxtCode = ''
         if (domain.trim() !== 'colate.io') {
            newTxtCode = `TXT-${Math.random().toString(36).substring(2, 15)}`
            setTxtCode(newTxtCode)
         }

         saveDomain(newTxtCode, domain)
         setDomain('colate.io')
      }
   }

   const saveDomain = async (txtCode: string, domainName: string) => {
      try {
         if (user?.sub) {
            const response = await tenantService.saveDomains({
               clientId: user.sub,
               domainName,
               txtCode,
            })
            if (response.status === 200 && response.data) {
               setDomains((prevDomains) => [...prevDomains, response.data])
            } else {
               setError('Error saving domain. Please try again later.')
               showAlert(response?.response?.data?.errorMessage || 'Failed to save domain', {
                  header: response?.response?.data?.message,
               })
            }
         }
      } catch (error) {
         console.error('Error saving domain:', error)
         showAlert('Failed to save domain', { extraInfo: 'Please try again later' })
      }
   }

   const handleClickMenu = (event: React.MouseEvent<HTMLElement>, index: number) => {
      setAnchorEl(event.currentTarget)
      setSelectedDomainIndex(index)
   }

   const handleCloseMenu = () => {
      setAnchorEl(null)
      setSelectedDomainIndex(null)
   }

   const handleDeleteDomain = () => {
      setReviewModal({
         status: true,
         message: 'Are you sure you want to delete this domain? This action cannot be undone.',
         isDelete: true,
      })
   }

   const handleCloseReviewModal = () => {
      setReviewModal({ status: false, message: '', isDelete: false })
      setSelectedDomainIndex(null)
   }

   const confirmDeleteDomain = async () => {
      if (selectedDomainIndex !== null) {
         setProcessing(true)
         try {
            const domainToDelete = domains[selectedDomainIndex]
            await tenantService.deleteDomain(domainToDelete.id)
            setDomains(domains.filter((_, idx) => idx !== selectedDomainIndex))
            handleCloseReviewModal()
         } catch (error) {
            setError('Failed to delete domain. Please try again later.')
            console.error('Error deleting domain:', error)
         } finally {
            setProcessing(false)
            handleCloseMenu()
         }
      }
   }

   if (loading) {
      return <SimpleLoadingScreen />
   }

   if (error) {
      return <ErrorScreen message={error} />
   }

   return (
      <PageContainer>
         <PageContainerApp>
            <PageTitle>Domain Verification</PageTitle>
            <PageSubTitle>
               Add a domain and verify ownership by adding the provided TXT record to your DNS settings.
            </PageSubTitle>
            <DomainInputWrapper>
               <TextFieldStyled
                  label="Enter domain"
                  variant="outlined"
                  value={domain}
                  onChange={(e) => setDomain(e.target.value)}
                  style={{ width: '66%' }}
               />
               <Button variant="contained" color="primary" style={{ marginLeft: '14px' }} onClick={handleAddDomain}>
                  Add Domain
               </Button>
            </DomainInputWrapper>
            {txtCode && (
               <div>
                  <p>
                     <strong>TXT Code:</strong> {txtCode}
                  </p>
                  <p>
                     Add this code to your domain's DNS as a TXT record to verify ownership. After adding, return here
                     to verify the domain from the actions.
                  </p>
                  <p>
                     <strong>Important: This code is only displayed on this screen during domain creation!</strong>
                  </p>
               </div>
            )}
            <TableContainer component={TableContainerDefault} sx={{ marginTop: '50px' }}>
               <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                     {domains.map((domain: DomainType, index) => (
                        <TableRow key={domain.id}>
                           <TableCell align="center">{domain.domainName}</TableCell>
                           <TableCell align="center">
                              {domain.status === 'Verified' ? (
                                 <StatusText success>{camelCase(domain.status)}</StatusText>
                              ) : (
                                 <StatusText>{camelCase(domain.status)}</StatusText>
                              )}
                           </TableCell>
                           <TableCell align="center" style={{ width: '150px' }}>
                              <Button
                                 style={{
                                    marginRight: '0px',
                                    padding: '4px 2px',
                                    borderRadius: '4px',
                                    color: theme.colors.font2,
                                    border: `1px solid ${theme.colors.boxBorder}`,
                                    width: '30px',
                                    height: '30px',
                                    minWidth: 'unset',
                                 }}
                                 variant="outlined"
                                 color="primary"
                                 onClick={(e) => handleClickMenu(e, index)}
                              >
                                 <MoreHoriz />
                              </Button>
                              <Menu
                                 anchorEl={anchorEl}
                                 open={selectedDomainIndex === index && Boolean(anchorEl)}
                                 onClose={handleCloseMenu}
                              >
                                 <MenuItem onClick={() => verifyDomain(index)}>
                                    <ListItemIcon>
                                       <Verified fontSize="small" />
                                    </ListItemIcon>
                                    <ListItemText primary="Verify Domain" />
                                 </MenuItem>
                                 <MenuItem onClick={handleDeleteDomain}>
                                    <ListItemIcon>
                                       <Delete fontSize="small" style={{ color: theme.colors.red }} />
                                    </ListItemIcon>
                                    <ListItemText primary="Delete" />
                                 </MenuItem>
                              </Menu>
                           </TableCell>
                        </TableRow>
                     ))}
                  </TableBody>
               </Table>
            </TableContainer>
            {verificationMessage && (
               <SnackbarNotification
                  open={Boolean(verificationMessage)}
                  autoHideDuration={6000}
                  onClose={() => setVerificationMessage('')}
                  type={isSuccess ? 'success' : 'error'}
                  alertTitle={isSuccess ? 'Successful' : 'Error'}
                  alertText={verificationMessage}
               />
            )}
            <ReviewModal
               openReviewModal={reviewModal.status}
               setOpenReviewModal={setReviewModal}
               message={reviewModal.message}
               handleAction={reviewModal.isDelete ? confirmDeleteDomain : undefined}
               colorType={reviewModal.isDelete ? 'error' : 'primary'}
               processing={processing}
            />
         </PageContainerApp>
      </PageContainer>
   )
}

export default Domains

const TextFieldStyled = styled(TextField)`
   input {
      height: 12px;
      border-radius: 6px;
   }
`

const DomainInputWrapper = styled(Box)`
   display: flex;
   align-items: center;
   gap: 10px;
   margin: 40px 0px;
   width: 70%;
`

const StatusText = styled.span<{ success?: boolean }>`
   color: ${(props) => (props.success ? theme.colors.black : theme.colors.secondary)};
   text-transform: capitalize;
`

const camelCase = (str: string) => {
   return str.toLowerCase().replace(/_([a-z])/g, (g) => g[1].toUpperCase())
}
