import React, { useState } from 'react'
import {
   Dialog,
   DialogTitle,
   DialogContent,
   DialogActions,
   Button,
   TextField,
   MenuItem,
   Select,
   FormControl,
   InputLabel,
} from '@mui/material'
import styled from 'styled-components'
import { PageSubTitle } from '../Devops/CommonStyles'

const StyledDialog = styled(Dialog)`
   .MuiDialog-paper {
      padding: 20px 15px 30px 15px;
   }
`

const StyledButton = styled(Button)`
   margin: 5px 10px;
`

const NewUserDialog = ({
   open,
   handleClose,
}: {
   open: boolean
   handleClose: () => void
}) => {
   const [companyName, setCompanyName] = useState('')
   const [industry, setIndustry] = useState('')
   const [role, setRole] = useState('')

   const handleCompanyNameChange = (e: any) => {
      setCompanyName(e.target.value)
   }

   const handleIndustryChange = (e: any) => {
      setIndustry(e.target.value)
   }

   const handleRoleChange = (e: any) => {
      setRole(e.target.value)
   }

   const handleSubmit = () => {
      // Add submit logic here
      handleClose()
   }

   return (
      <StyledDialog open={open} onClose={handleClose}>
         <DialogTitle sx={{ fontWeight: 600 }}>Welcome to COCREATE!</DialogTitle>
         <DialogContent>
            <PageSubTitle style={{ width: '99%' }}>
               We're setting up a default cloud environment for you, which may take a few minutes.
               Meanwhile, Please submit your preferences to customize your experience.
            </PageSubTitle>
            <div
               style={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
               }}
            >
               <form
                  style={{
                     width: '75%',
                  }}
               >
                  <TextField
                     fullWidth
                     label="Company Name"
                     value={companyName}
                     onChange={handleCompanyNameChange}
                     margin="normal"
                     variant="outlined"
                  />
                  <FormControl fullWidth margin="normal" variant="outlined">
                     <InputLabel>Industry</InputLabel>
                     <Select
                        value={industry}
                        onChange={handleIndustryChange}
                        label="Industry"
                     >
                        <MenuItem value="Telecom">Fintech</MenuItem>
                        <MenuItem value="Finance">Telecom</MenuItem>
                        <MenuItem value="Healthcare">Healthcare</MenuItem>
                        <MenuItem value="Healthcare">Other</MenuItem>
                     </Select>
                  </FormControl>
                  <FormControl fullWidth margin="normal" variant="outlined">
                     <InputLabel>Role</InputLabel>
                     <Select
                        value={role}
                        onChange={handleRoleChange}
                        label="Role"
                     >
                        <MenuItem value="Engineer">Software Engineer</MenuItem>
                        <MenuItem value="Manager">Software Manager</MenuItem>
                        <MenuItem value="Director">Product Owner</MenuItem>
                        <MenuItem value="Architect">Solution Architect</MenuItem>
                        <MenuItem value="Analyst">Business Analyst</MenuItem>
                        <MenuItem value="QA">Quality Assurance Engineer</MenuItem>
                        <MenuItem value="DevOps">DevOps Engineer</MenuItem>
                        <MenuItem value="Designer">UX/UI Designer</MenuItem>
                        <MenuItem value="Consultant">IT Consultant</MenuItem>
                        <MenuItem value="ProjectManager">Project Manager</MenuItem>
                        <MenuItem value="CEO">Founder</MenuItem>
                        <MenuItem value="CTO">Co-founder</MenuItem>
                        <MenuItem value="Intern">Other</MenuItem>
                     </Select>
                  </FormControl>
               </form>
            </div>
         </DialogContent>
         <DialogActions style={{ marginRight: '25px', marginTop: '50px' }}>
            <StyledButton
               variant="contained"
               color="primary"
               style={{ marginLeft: '18px' }}
               onClick={handleSubmit}
            >
               Submit
            </StyledButton>
         </DialogActions>
      </StyledDialog>
   )
}

export default NewUserDialog
