import axios, { AxiosInstance } from 'axios'
import { useNavigate } from 'react-router-dom'

export const createApiClient = (args: any): AxiosInstance => {
   const navigate = useNavigate()
   const { endpoint, fetchAccessToken, user } = args

   const api = axios.create({
      baseURL: endpoint,
   })
   api.interceptors.request.use(
      async (config) => {
         const token = await fetchAccessToken()
         config.headers.Authorization = `Bearer ${token}`
         config.headers['x-client-id'] = user?.sub

         return config
      },
      (error) => {
         return Promise.reject(error)
      }
   )
   api.interceptors.response.use(
      (res) => {
         return res
      },
      (err) => {
         //|| err?.response?.status === 401
         if (err?.response?.status === 403) {
            navigate('/') // Not Authorised , Redirect to login
         }
         return err
      }
   )
   return api
}
