import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
interface FileContextMenuProps {
   contextMenu: { mouseX: number | null; mouseY: number | null; node: any | null }
   onClose: () => void
   addFileOrFolder: (parentPath: string, type: 'file' | 'folder') => void
   renameFileOrFolder: (oldPath: string, newPath: string) => void
   deleteFileOrFolder: (filePath: string) => void
   setNewFileName: (name: string | null) => void
   setCurrentNodePath: (path: string | null) => void
   setCurrentNodeType: (type: 'file' | 'folder' | null) => void
}

const FileContextMenu: React.FC<FileContextMenuProps> = ({
   contextMenu,
   onClose,
   addFileOrFolder,
   renameFileOrFolder,
   deleteFileOrFolder,
   setNewFileName,
   setCurrentNodePath,
   setCurrentNodeType,
}) => {
   const handleAddFileOrFolder = (type: 'file' | 'folder') => {
      const selectedPath = contextMenu.node.path
      const parentPath =
         contextMenu.node.type === 'folder' ? selectedPath : selectedPath.split('/').slice(0, -1).join('/')

      // Set the state to handle naming of the new file or folder
      setCurrentNodePath(parentPath)
      setCurrentNodeType(type)
      setNewFileName('')

      // Call addFileOrFolder to add the new file or folder
      addFileOrFolder(parentPath, type)
      onClose()
   }

   return (
      <Menu
         open={contextMenu.mouseY !== null}
         onClose={onClose}
         anchorReference="anchorPosition"
         anchorPosition={
            contextMenu.mouseY !== null && contextMenu.mouseX !== null
               ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
               : undefined
         }
      >
         <MenuItem onClick={() => handleAddFileOrFolder('file')}>Add File</MenuItem>
         <MenuItem onClick={() => handleAddFileOrFolder('folder')}>Add Folder</MenuItem>
         {/* <MenuItem
            onClick={() => {
               const newName = prompt('Enter new name:', contextMenu.node.path.split('/').pop())
               if (newName) {
                  const newPath = `${contextMenu.node.path.split('/').slice(0, -1).join('/')}/${newName}`
                  renameFileOrFolder(contextMenu.node.path, newPath)
               }
               onClose()
            }}
         >
            Rename
         </MenuItem> */}
         <MenuItem
            onClick={() => {
               deleteFileOrFolder(contextMenu.node.path)
               onClose()
            }}
         >
            Delete
         </MenuItem>
      </Menu>
   )
}

export default FileContextMenu
