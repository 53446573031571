// BackendApplication.tsx

import React from 'react';
import { TableCell, Tooltip, IconButton } from '@mui/material';
import { OpenInNew, ContentCopy } from '@mui/icons-material';
import { UrlContainer, UrlItem, UrlLabel, UrlLink } from './UrlStyles'; // Assuming you have these styled components
import { truncateString } from '../../components/helpers/stringFns';

interface BackendApplicationProps {
  app: any; // Replace 'any' with the appropriate type
  authToken: string | null;
}

const BackendApplication: React.FC<BackendApplicationProps> = ({ app, authToken }) => {
  return (
    <TableCell align="center">
      <UrlContainer>
        {/* Execute command with curl */}
        {app?.appAttributes?.appUrl && (
          <UrlItem>
            <Tooltip
              title={
                <div
                  style={{
                    backgroundColor: '#fff',
                    padding: '10px',
                    borderRadius: '10px',
                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                    width: '600px',
                    border: '1px solid #e0e0e0',
                    wordWrap: 'break-word',
                    whiteSpace: 'pre-wrap',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      gap: '10px',
                      fontSize: '14px',
                      color: '#333',
                    }}
                  >
                    {/* Header and Copy button on the same row */}
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                      }}
                    >
                      <h3 style={{ margin: 0, fontWeight: 'bold' }}>Execute Curl Command</h3>
                      <IconButton
                        size="small"
                        onClick={() => {
                          const curlCommand = `curl -H "Authorization: Bearer ${authToken}" ${app.appAttributes?.appUrl}`;
                          navigator.clipboard.writeText(curlCommand);
                        }}
                      >
                        <ContentCopy fontSize="small" />
                      </IconButton>
                    </div>
                    {/* Curl command */}
                    <pre
                      style={{
                        backgroundColor: '#f7f7f7',
                        padding: '8px',
                        borderRadius: '5px',
                        overflowX: 'auto',
                        fontSize: '12px',
                        whiteSpace: 'pre-wrap',
                        wordWrap: 'break-word',
                      }}
                    >
                      {`curl -H "Authorization: Bearer [TOKEN MASKED]" \\\n${app.appAttributes?.appUrl}`}
                    </pre>
                  </div>
                </div>
              }
              placement="top"
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'transparent',
                  },
                },
              }}
            >
              <UrlLink>
                Execute API
                <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
              </UrlLink>
            </Tooltip>
          </UrlItem>
        )}

        {/* App Repository */}
        {app?.appAttributes?.repositoryUrl && (
          <UrlItem>
            <UrlLabel>App Repository:</UrlLabel>
            <UrlLink onClick={() => window.open(app.appAttributes?.repositoryUrl)}>
              {truncateString(app.appAttributes?.repositoryUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </UrlLink>
          </UrlItem>
        )}

        {/* Deployment Specs */}
        {app?.appAttributes?.appOfAppsRepositoryUrl && (
          <UrlItem>
            <UrlLabel>Deployment Specs:</UrlLabel>
            <UrlLink onClick={() => window.open(app.appAttributes?.appOfAppsRepositoryUrl)}>
              {truncateString(app.appAttributes?.appOfAppsRepositoryUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </UrlLink>
          </UrlItem>
        )}
      </UrlContainer>
    </TableCell>
  );
};

export default BackendApplication;
