import { useAuth0 } from '@auth0/auth0-react'
import { useCallback } from 'react'

const useFetchToken = () => {
   const { getAccessTokenSilently } = useAuth0()

   const fetchToken = useCallback(async () => {
      try {
         const token = await getAccessTokenSilently({
            authorizationParams: {
               audience: `${import.meta.env.VITE_VAR_CO_CREATE_BACKEND_API_AUDIENCE}`,
            },
         })
         return token
      } catch (error) {
         console.error('Error getting Auth0 access token:', error)
      }
   }, [getAccessTokenSilently])

   return fetchToken
}

export default useFetchToken
