import React, { useEffect, useState } from 'react'
import { Button } from '@mui/material'
import styled from 'styled-components'
import { ArrowForwardOutlined, InfoOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { parseJwt } from '../../components/helpers/parseJWT'
import CreateApplicationDialog from '../Applications/CreateApplicationDialog/CreateApplicationDialog'
import CreateTenantDialog from '../Applications/CreateTenantDialog/CreateTenantDialog'
import NewUserDialog from './NewUserDialog'
import { setTour } from '../../Configs/ReduxSlice/productTour'
import { useAppDispatch } from '../../Configs/store/config/useAppDispatch'
import { useAppContext } from '../../components/Common/AppContext'
import TutorialDialog from './TutorialDialog' // Import the new TutorialDialog component
import { theme } from '../../styles/theme' // Make sure to import the theme if it's being used

const GetStarted = () => {
   const { user, getAccessTokenSilently } = useAuth0()
   const [openTenantDialog, setOpenTenantDialog] = useState(false)
   const [newUserdialogOpen, setNewUserDialogOpen] = useState(false)
   const [openApplicationDialogGetStarted, setOpenApplicationDialogGetStarted] = useState(false)
   const [openTutorialDialog, setOpenTutorialDialog] = useState(false) // State to control TutorialDialog visibility
   const dispatch = useAppDispatch()
   const navigate = useNavigate()
   const { setSelectedSidebarItem } = useAppContext()

   const handleTutorialOpen = () => {
      setOpenTutorialDialog(true) // Open the tutorial dialog
   }

   const handleTutorialClose = () => {
      setOpenTutorialDialog(false) // Close the tutorial dialog
   }

   const checkIfFirstTimeUsers = async () => {
      const accessToken = await getAccessTokenSilently()
      if (accessToken) {
         const parsedJWT = parseJwt(accessToken)

         if (parsedJWT && parsedJWT.firstTimeLogin === true) {
            setNewUserDialogOpen(true)
         }
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Home'
      checkIfFirstTimeUsers()
   }, [])

   const handleNavigation = (path: string, key: string) => {
      setSelectedSidebarItem(key) // Update context with selected item key
      navigate(path)
   }

   return (
      <>
         <Container>
            <Section>
               <Title>Get Started</Title>
               <InfoBox>
                  <InfoOutlined color="primary" />
                  <InfoText>
                     <b>New to COCREATE?</b> Try the tutorial to get started.{' '}
                     <TutorialLink onClick={handleTutorialOpen}>Start the Tutorial</TutorialLink> {/* Updated link */}
                  </InfoText>
               </InfoBox>
            </Section>

            <Section>
               <CardContainer>
                  <CreateAppLeft>
                     <Title2>Create Applications</Title2>
                     <NextStepText
                        style={{
                           marginBottom: '40px',
                           color: theme.colors.font1,
                        }}
                     >
                        Start creating an application to harness the full potential of COCREATE!
                     </NextStepText>
                     <Button
                        variant="contained"
                        sx={{ backgroundColor: 'black', color: 'white' }} // Changed button background to black
                        onClick={() => setOpenApplicationDialogGetStarted(true)}
                     >
                        Create Applications
                     </Button>{' '}
                     <Button
                        variant="text"
                        color="primary"
                        style={{
                           marginLeft: '6px',
                           color: '#708090',
                           fontWeight: 'bold',
                        }}
                     >
                        More Info
                     </Button>
                  </CreateAppLeft>
                  <CreateAppRight>
                     <img src="/static/images/applications.png" />
                  </CreateAppRight>
               </CardContainer>
            </Section>

            <Section>
               <h3>Next Steps</h3>
               <NextStepsContainer>
                  <h4>Integrate with Third-Party Software</h4>
                  <NextStep>
                     <NextStepText>
                        After creating your applications, explore the integrations section, install third-party solutions and connect them with your applications.
                     </NextStepText>
                     <NextStepsButton
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNavigation('/integration-hub', 'integration-hub')} // Navigate to the integrations page
                     >
                        Explore Integrations <ArrowForwardOutlined sx={{ marginLeft: '4px', fontSize: '12px' }} />
                     </NextStepsButton>
                  </NextStep>
               </NextStepsContainer>
               <NextStepsContainer>
                 <h4>AI Vision Board</h4>
                 <NextStep>
                    <NextStepText>
                       Turn your vision into reality by using the Vision Board, transform your ideas into market-ready solutions.
                    </NextStepText>
                    <NextStepsButton
                       variant="outlined"
                       color="primary"
                       onClick={() => handleNavigation('/vision-board', 'visionBoard')} // Navigate to AI Vision Board
                    >
                       Explore Vision Board <ArrowForwardOutlined sx={{ marginLeft: '4px', fontSize: '12px' }} />
                    </NextStepsButton>
                 </NextStep>
               </NextStepsContainer>
               <NextStepsContainer>
                  <h4>CI / CD</h4>
                  <NextStep>
                     <NextStepText>
                        Checkout the source code & CI/CD pipelines that are pre-configured for your applications to
                        accelerate your development.
                     </NextStepText>
                     <NextStepsButton
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNavigation('/code-build', 'codeBuild')} // Update context and navigate
                     >
                        Builds & Pipelines <ArrowForwardOutlined sx={{ marginLeft: '4px', fontSize: '12px' }} />
                     </NextStepsButton>
                  </NextStep>
               </NextStepsContainer>

               <NextStepsContainer>
                  <h4>Create a team</h4>
                  <NextStep>
                     <NextStepText>
                        Create a team and add members so that they can view & contribute to your applications.
                     </NextStepText>
                     <NextStepsButton
                        variant="outlined"
                        color="primary"
                        onClick={() => handleNavigation('/teams', 'teams')} // Update context and navigate
                     >
                        Add Team <ArrowForwardOutlined sx={{ marginLeft: '4px', fontSize: '12px' }} />
                     </NextStepsButton>
                  </NextStep>
               </NextStepsContainer>

               <NextStepsContainer>
                  <h4>Create a custom environment</h4>
                  <NextStep>
                     <NextStepText>
                        Create a custom environment based on your requirements by adding a new tenant.
                     </NextStepText>
                     <NextStepsButton
                        variant="outlined"
                        color="primary"
                        onClick={() => setOpenTenantDialog(true)} // Inline navigation
                     >
                        Add Tenant <ArrowForwardOutlined sx={{ marginLeft: '4px', fontSize: '12px' }} />
                     </NextStepsButton>
                  </NextStep>
               </NextStepsContainer>
            </Section>
         </Container>
         {openApplicationDialogGetStarted && (
            <CreateApplicationDialog
               setOpenApplicationDialog={setOpenApplicationDialogGetStarted}
               openApplicationDialog={openApplicationDialogGetStarted}
            />
         )}
         {openTenantDialog && (
            <CreateTenantDialog openTenantDialog={openTenantDialog} setOpenTenantDialog={setOpenTenantDialog} />
         )}
         <NewUserDialog open={newUserdialogOpen} handleClose={() => setNewUserDialogOpen(false)} />

         {/* Render the TutorialDialog when openTutorialDialog is true */}
         {openTutorialDialog && <TutorialDialog open={openTutorialDialog} onTutorialClose={handleTutorialClose} />}
      </>
   )
}

export default GetStarted

// Styled Components specific to GetStarted only
const Container = styled.div`
   padding: 2px;
`

const Section = styled.div`
   margin: 10px auto;
   margin-bottom: 40px;
   max-width: 1000px;
`

const Title = styled.h2`
   color: ${theme.colors.text};
`

const Title2 = styled.h4`
   color: ${theme.colors.text};
   font-size: 19px;
   margin-top: 15px;
   margin-bottom: 20px;
`

const InfoBox = styled.div`
   padding: 20px;
   display: flex;
   align-items: center;
   background-color: ${theme.colors.white};
   border: 1px solid ${theme.colors.boxBorder};
   border-radius: 6px;
   margin-top: 20px;
   margin-bottom: 20px;
   position: relative;
   overflow: hidden;

   &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 8px;
      background: linear-gradient(#da22ff, #ffa824);
   }
`

const InfoText = styled.div`
   margin-left: 10px;
   color: ${theme.colors.text};
`

const TutorialLink = styled.span`
   color: ${theme.colors.blue};
   cursor: pointer;
`

const CardContainer = styled(Container)`
   background-image: linear-gradient(rgba(255, 255, 255, 0.22), rgba(255, 255, 255, 0.2)),
      url('/static/images/gradient-getStarted2.png');
   background-size: cover;
   background-position: center;
   background-repeat: no-repeat;
   border-radius: 8px;
   display: flex;
   padding: 25px 50px;
   width: calc(100% - 104px);
   height: max-content;
`

const CreateAppLeft = styled.div`
   flex: 3 1 0%;
`

const CreateAppRight = styled.div`
   flex: 1 1 0%;
   padding-right: 16px;
   padding-top: 0px;
   padding-bottom: 0px;
   align-self: center;
   & img {
      display: block;
      height: 250px;
      width: 450px;
      margin: 0px auto;
      object-fit: contain;
   }
   @media (min-width: 900px) {
      display: flex;
   }
`

const NextStepsContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding: 12px 25px 25px 25px;
   background-color: ${theme.colors.white};
   border: 1px solid ${theme.colors.boxBorder};
   margin-top: 8px;
   border-radius: 6px;
   margin-bottom: 20px;
`

const NextStep = styled.div`
   display: flex;
   flex-direction: row;
   padding-top: 8px;
`

const NextStepText = styled.div`
   color: ${theme.colors.font2};
   width: 70%;
   font-size: 15px;
`

const NextStepsButton = styled(Button)`
   margin-left: auto !important;
   margin-top: -20px !important;
   height: 36px !important;
   font-size: 15px !important;
   text-transform: none !important;
`
