import React, { useEffect, useState } from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import Divider from '@mui/material/Divider'
import DialogContent from '@mui/material/DialogContent'
import FormControl from '@mui/material/FormControl'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import DialogTitle from '@mui/material/DialogTitle'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import { Container, SpaceBottom } from '../Applications/ApplicationStyles'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { useTenantApiClient, useApplicationApiClient } from '../../Configs/API/createAxiosConfig'
import { TenantSingleType } from '../Configurations/Tenants/Tenant'
import { dispatchTenantsAPIData } from '../../Configs/ReduxSlice/tenantSlice'
import { CircularProgress, FormHelperText, Skeleton, Typography } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react'
import { useAppDispatch } from '../../Configs/store/config/useAppDispatch'
import useAlert from '../../components/Extras/Alerts/useAlert'
import { useNavigate } from 'react-router-dom'

type InstallOpenSourceDialogProps = {
   openInstallDialog: boolean
   setOpenInstallDialog: Function
   selectedIntegration: string
   handleInstall: (name: string, tenantId: string) => void
}

const InstallOpenSourceDialog = ({
   openInstallDialog,
   setOpenInstallDialog,
   selectedIntegration,
   handleInstall,
}: InstallOpenSourceDialogProps) => {
   const [getTenentAPILoading, setgetTenentAPILoading] = useState<boolean>(false)
   const [tenants, setTenants] = React.useState<TenantSingleType[]>([])
   const [selectedTenant, setselectedTenant] = React.useState('')
   const [integrationName, setIntegrationName] = useState('')
   const [formError, setformError] = useState<string>('')
   const { user } = useAuth0()
   const [installLoading, setInstallLoading] = useState(false)
   const tenantService = useTenantApiClient()
   const applicationService = useApplicationApiClient() // Use this for API calls
   const dispatch = useAppDispatch()
   const { showAlert } = useAlert()
   const navigate = useNavigate()
   const handleClose = () => {
      setOpenInstallDialog(false)
   }

   const handleChangeTenant = (event: SelectChangeEvent) => {
      setselectedTenant(event.target.value as string)
   }

   const fetchTenants = async () => {
      try {
         setgetTenentAPILoading(true)
         const response = await tenantService.getTenants()
         if (response.status == 200) {
            setTenants(response.data)
            dispatch(dispatchTenantsAPIData(response.data))
         } else {
            console.error('Failed to load tenants. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenants', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.log('Error fetching tenants', err)
         showAlert('Failed to load tenants', { extraInfo: 'Please try again later' })
      } finally {
         setgetTenentAPILoading(false)
      }
   }

   const handleInstallClick = async () => {
      if (!integrationName || !selectedTenant) {
         setformError('All fields are required')
         return
      }
      setInstallLoading(true)

      try {
         const payload = {
            integration: selectedIntegration.toLowerCase(),
            tenantId: Number(selectedTenant),
            name: integrationName,
            clientId: user?.sub,
         }

         // Assuming there's a method in applicationService to handle integrations
         const response = await applicationService.addIntegration(payload)

         if (response.status === 200) {
            // Handle success (e.g., show a success message or trigger a refresh)
            console.log('Integration installed successfully:', response.data)
            navigate('/applications')
         } else {
            // Handle failure (e.g., show an error message)
            console.error('Failed to install integration:', response.data)
            showAlert(response?.response?.data?.errorMessage || 'Failed to install integrations', {
               header: response?.response?.data?.message,
            })
         }
      } catch (error) {
         console.error('Error installing integration:', error)
         showAlert('Failed to install integrations', { extraInfo: 'Please try again later' })
      } finally {
         setInstallLoading(false)
         handleClose()
      }
   }

   useEffect(() => {
      fetchTenants()
   }, [])

   return (
      <React.Fragment>
         <Dialog
            open={openInstallDialog}
            onClose={handleClose}
            scroll={'paper'}
            PaperProps={{
               style: { width: '60vw', maxHeight: '70vh', borderRadius: 10 },
               component: 'form',
            }}
         >
            <DialogTitle sx={{ fontWeight: 600 }}>Install {selectedIntegration}</DialogTitle>
            <DialogContent>
               <Typography variant="subtitle1" color="textSecondary" gutterBottom>
                  Review the status of the integration under Applications after submission.
               </Typography>
               <Container>
                  <FormControl fullWidth>
                     <TextField
                        error={!!formError}
                        margin="dense"
                        id="integration-name"
                        label="Integration Name"
                        variant="outlined"
                        value={integrationName}
                        onChange={(e) => setIntegrationName(e.target.value)}
                        size="medium"
                     />
                     <FormHelperText error>{formError}</FormHelperText>
                  </FormControl>
                  <SpaceBottom />
                  <Grid container columnSpacing={2}>
                     <Grid xs={12}>
                        {getTenentAPILoading ? (
                           <Skeleton variant="rectangular" height={50} />
                        ) : (
                           <FormControl fullWidth error={!!formError}>
                              <InputLabel id="tenant-label">Tenant</InputLabel>
                              <Select
                                 labelId="tenant-select-label"
                                 id="tenant-select"
                                 value={selectedTenant}
                                 label="Tenant"
                                 onChange={handleChangeTenant}
                                 size="medium"
                              >
                                 {tenants.map(
                                    (tenant: TenantSingleType) =>
                                       tenant.tenantStatus === 'ACTIVE' && (
                                          <MenuItem key={tenant.id} value={tenant.id}>
                                             {tenant.tenantName}
                                          </MenuItem>
                                       )
                                 )}
                              </Select>
                           </FormControl>
                        )}
                     </Grid>
                  </Grid>
                  <SpaceBottom />
               </Container>
            </DialogContent>
            <Divider />
            <DialogActions>
               <Grid container columnSpacing={2} sx={{ marginRight: 2, marginBottom: 1, marginTop: 1 }}>
                  <Grid>
                     <Button color="primary" onClick={handleClose} variant="outlined">
                        Cancel
                     </Button>
                  </Grid>
                  <Grid>
                     <Button color="primary" variant="contained" onClick={handleInstallClick} disabled={installLoading}>
                        {installLoading ? (
                           <CircularProgress color="primary" size={24} sx={{ marginTop: '6px', marginLeft: '8px' }} />
                        ) : (
                           'Install'
                        )}
                     </Button>
                  </Grid>
               </Grid>
            </DialogActions>
         </Dialog>
      </React.Fragment>
   )
}
export default InstallOpenSourceDialog
