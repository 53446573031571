import { TenantSingleType } from './../../pages/Configurations/Tenants/Tenant'
import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

type initialStateType = {
   teneantAPIData: TenantSingleType[]
}
const initialState: initialStateType = {
   teneantAPIData: [],
}

export const tenantSlice = createSlice({
   name: 'tenant',
   initialState,
   reducers: {
      dispatchTenantsAPIData: (
         state,
         action: PayloadAction<TenantSingleType[]>
      ) => {
         state.teneantAPIData = action.payload
      },
      addTenantSlice: (state, action: PayloadAction<TenantSingleType>) => {
         state.teneantAPIData = [...state.teneantAPIData, action.payload]
      },
   },
})

// Action creators are generated for each case reducer function
export const { dispatchTenantsAPIData, addTenantSlice } = tenantSlice.actions

export default tenantSlice.reducer
