import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { TeamType } from '../../pages/TeamManagement/TeamManagement'

type initialStateType = {
   teamsAPIData: TeamType[]
}
const initialState: initialStateType = {
   teamsAPIData: [],
}

export const teamSlice = createSlice({
   name: 'teams',
   initialState,
   reducers: {
      dispatchteamsAPIData: (state, action: PayloadAction<TeamType[]>) => {
         state.teamsAPIData = action.payload
      },
      addToTeamAPIData: (state, action: PayloadAction<TeamType>) => {
         state.teamsAPIData = [...state.teamsAPIData, action.payload]
      },
      deleteFromTeamAPIData: (state, action: PayloadAction<number>) => {
         state.teamsAPIData = state.teamsAPIData.filter((team) => team.id !== action.payload)
      },
   },
})

// Action creators are generated for each case reducer function
export const { dispatchteamsAPIData, addToTeamAPIData, deleteFromTeamAPIData } = teamSlice.actions

export default teamSlice.reducer
