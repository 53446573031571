import React from 'react'
import styled, { keyframes } from 'styled-components'
import { theme } from '../../styles/theme'

const fadeInOut = keyframes`
   0% { opacity: 0; }
   50% { opacity: 1; }
   100% { opacity: 0; }
`

const Wrapper = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 50vh;
`

const GradientLetter = styled.span<{ delay: number }>`
   animation: ${fadeInOut} 2s linear infinite;
   animation-delay: ${({ delay }) => delay}s;
   background: linear-gradient(90deg, #da22ff, #ffa824);
   -webkit-background-clip: text;
   -webkit-text-fill-color: transparent;
   font-size: 2rem;
   font-weight: 500;
   font-family: 'Baumans', sans-serif;
   padding: 1px;
   margin: 0 1px;
   display: inline-block;
`

const BlackLetter = styled.span<{ delay: number }>`
   animation: ${fadeInOut} 2s linear infinite;
   animation-delay: ${({ delay }) => delay}s;
   color: ${theme.colors.font1};
   font-size: 2rem;
   font-weight: 500;
   font-family: 'Baumans', sans-serif;
   padding: 1px;
   margin: 0 1px;
   display: inline-block;
`

const LoadingAnimation: React.FC = () => {
   const word = 'cocreate'
   return (
      <Wrapper>
         {word.split('').map((letter, index) =>
            index === 0 || index === 1 ? (
               <GradientLetter key={index} delay={index * 0.2}>
                  {letter}
               </GradientLetter>
            ) : (
               <BlackLetter key={index} delay={index * 0.2}>
                  {letter}
               </BlackLetter>
            )
         )}
      </Wrapper>
   )
}

export default LoadingAnimation
