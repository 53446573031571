import React, { useEffect, useState } from 'react';
import {
  PageContainer,
  PageContainerApp,
  PageSubTitle,
  PageTitle,
} from '../Devops/CommonStyles';
import {
  Button,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material';
import { ContentCopy, CheckCircle, OpenInNew } from '@mui/icons-material';
import styled from 'styled-components';
import { useTenantApiClient } from '../../Configs/API/createAxiosConfig';

interface Tenant {
  id: string;
  name: string;
}

const CredentialsVault: React.FC = () => {
  const [tenants, setTenants] = useState<Tenant[]>([]);
  const [selectedTenant, setSelectedTenant] = useState<string>('');
  const [vaultToken, setVaultToken] = useState<string>('');
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const [tokenGenerated, setTokenGenerated] = useState<boolean>(false);
  const [copied, setCopied] = useState<boolean>(false);
  const [loadingTenants, setLoadingTenants] = useState<boolean>(true);

  const tenantService = useTenantApiClient();

  const fetchTenants = async () => {
    try {
      setLoadingTenants(true);
      const response = await tenantService.getTenants();
      if (response.status === 200) {
        setTenants(response.data);
      } else {
        console.error('Failed to load tenants. Please try again later.');
      }
    } catch (err) {
      console.error('Error fetching tenants', err);
    } finally {
      setLoadingTenants(false);
    }
  };

  useEffect(() => {
    document.title = 'COCREATE | Credentials Vault';
    fetchTenants();
  }, []);

  // Handle tenant selection
  const handleTenantChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedTenant(event.target.value as string);
    setTokenGenerated(false);
    setVaultToken('');
  };

  // Generate Vault Token
  const handleGenerateVaultToken = async () => {
    if (loadingToken || !selectedTenant) return;
    setLoadingToken(true);
    try {
      const response = await tenantService.generateVaultToken(selectedTenant);
      if (response.status === 200 || response.status === 201) {
        setVaultToken(response.data);
        setTokenGenerated(true);
      }
    } catch (error) {
      console.error('Error generating Vault Token', error);
    } finally {
      setLoadingToken(false);
    }
  };

  // Handle copying the token
  const handleCopyToken = () => {
    navigator.clipboard.writeText(vaultToken);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };

  return (
    <PageContainer>
      <PageContainerApp>
        {/* Title and Subtitle */}
        <PageTitle>Credentials Vault</PageTitle>
        <PageSubTitle>
          Manage and access credentials securely with Vault. Strengthen your DevSecOps by safely storing keys, passwords, and certificates, ensuring compliance and protecting against unauthorized access throughout your delivery lifecycle.
        </PageSubTitle>

        {/* Tenant Selection and Generate Token Section */}
        <CenteredContainer>
          <FormControl fullWidth variant="outlined" disabled={loadingTenants} sx={{ marginBottom: '20px' }}>
            <InputLabel>Select Tenant</InputLabel>
            <Select
              value={selectedTenant}
              onChange={handleTenantChange}
              label="Select Tenant"
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.tenantName}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            variant="contained"
            color="primary"
            disabled={loadingToken || !selectedTenant || tokenGenerated}
            onClick={handleGenerateVaultToken}
            fullWidth
          >
            {loadingToken ? 'Generating...' : tokenGenerated ? 'Token Generated' : 'Generate Token To Access Vault'}
          </Button>
        </CenteredContainer>

        {/* Display Generated Token and Copy Option */}
        {tokenGenerated && (
          <TokenContainer>
            <Typography variant="body1">Vault Token:</Typography>
            <TokenWrapper>
              <TokenText>**********</TokenText>
              <Tooltip title={copied ? 'Copied' : 'Copy Vault Token'}>
                <IconButton onClick={handleCopyToken}>
                  {copied ? (
                    <CheckCircle fontSize="small" />
                  ) : (
                    <ContentCopy fontSize="small" />
                  )}
                </IconButton>
              </Tooltip>
              <VaultLink
                onClick={() => window.open('https://vault.colate.io', '_blank')}
              >
                Open Vault UI
                <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
              </VaultLink>
            </TokenWrapper>
          </TokenContainer>
        )}
      </PageContainerApp>
    </PageContainer>
  );
};

export default CredentialsVault;

// Styled Components
const CenteredContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
`;

const TokenContainer = styled.div`
  margin-top: 20px;
  text-align: center;
`;

const TokenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 10px;
`;

const TokenText = styled.span`
  font-size: 16px;
  font-weight: bold;
`;

const VaultLink = styled.span`
  margin-left: 10px;
  color: #1976d2;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;
