import React, { useEffect, useState } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Divider } from '@mui/material'
import styled, { keyframes } from 'styled-components'
import steps from './TutorialSteps'
import { useAppContext } from '../../components/Common/AppContext'

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const BulletPoint = styled.li`
   margin-bottom: 10px;
   display: flex;
   align-items: center;
   color: #555;
   font-size: 16px;
   gap: 8px;
`

const SubTitle = styled.h3`
   font-size: 18px;
   color: black;
   margin-top: 20px;
   margin-bottom: 10px;
   display: flex;
   align-items: center;
   gap: 8px;
`

const StyledDialogTitle = styled(DialogTitle)`
   font-weight: 550 !important;
   font-size: 22px !important;
   padding: 18px !important;
   // border-bottom: 1px solid #eee;
   text-align: center;
   margin-bottom: 15px !important;
   color: black !important;
   animation: ${fadeIn} 0.6s ease-in-out;
   line-height: 1.4;
`

const SubTitleText = styled.h4`
   font-size: 16px;
   color: gray;
   font-weight: 400;
   margin-top: 8px;
   line-height: 1.3;
`

const StyledDialogContent = styled(DialogContent)`
   padding: 24px;
   display: flex;
   flex-direction: column;
   align-items: center;
   text-align: left;
`

const Description = styled.div`
   font-size: 16px;
   color: #555;
   margin-bottom: 20px;
   line-height: 1.75;
   animation: ${fadeIn} 0.6s ease-in-out;
`

const IntroDescription = styled(Description)`
   // font-family: 'Arial', sans-serif; /* Customize your font here */
   font-size: 16px;
   color: #333;
   text-align: center;
`

const StyledDialogActions = styled(DialogActions)`
   padding-left: 20px !important;
   padding-right: 20px !important;
   // border-top: 1px solid #eee;
   display: flex;
   justify-content: space-between;
   margin-top: 9px;

   min-height: 60px;
`

const RightActions = styled.div`
   display: flex;
   align-items: center;
   & > *:not(:last-child) {
      margin-right: 16px;
   }
`

const ContentContainer = styled.div`
   display: flex;
   flex-direction: ${({ isIntro }) => (isIntro ? 'column' : 'row')};
   align-items: center;
   min-height: 320px;
   width: 100%;
`

const TextContent = styled.div`
   flex: ${({ isIntro }) => (isIntro ? 1 : 2)};
   padding-right: ${({ isIntro }) => (isIntro ? '0' : '20px')};
   text-align: ${({ isIntro }) => (isIntro ? 'center' : 'left')};
`

const ImageContent = styled.div`
   flex: ${({ isIntro }) => (isIntro ? 0 : 1)};
   display: flex;
   justify-content: ${({ isIntro }) => (isIntro ? 'center' : 'flex-end')};
   width: ${({ isIntro }) => (isIntro ? '100%' : 'auto')};
`

const Image = styled.img`
   width: ${({ isIntro }) => (isIntro ? '70%' : '100%')}; /* Further reduced width */
   max-width: ${({ isIntro }) => (isIntro ? '350px' : '180px')}; /* Further reduced max-width */
   margin-top: ${({ isIntro }) => (isIntro ? '20px' : '0')};
   border-radius: 8px;
   box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
   animation: ${fadeIn} 0.8s ease-in-out;
`

const Video = styled.video`
   width: 100%;
   max-width: 600px;
   border-radius: 8px;
   margin-top: 20px;
   animation: ${fadeIn} 0.8s ease-in-out;
`

const TutorialDialog = ({ open, onTutorialClose }: { open: boolean; onTutorialClose: () => void }) => {
   const { setHighlightSidebarItem } = useAppContext()

   const [currentStepIndex, setCurrentStepIndex] = useState(0)
   const [currentPartIndex, setCurrentPartIndex] = useState(0)

   const currentStep = steps[currentStepIndex]
   const currentPart = currentStep.parts[currentPartIndex]

   useEffect(() => {
      setHighlightSidebarItem(currentStep?.toHighlightSidebar)
   }, [currentStepIndex])

   const onClose = () => {
      setHighlightSidebarItem(undefined)
      onTutorialClose()
   }

   const handleNext = () => {
      if (currentPartIndex < currentStep.parts.length - 1) {
         setCurrentPartIndex(currentPartIndex + 1)
      } else if (currentStepIndex < steps.length - 1) {
         setCurrentStepIndex(currentStepIndex + 1)
         setCurrentPartIndex(0)
      }
   }

   const handlePrevious = () => {
      if (currentPartIndex > 0) {
         setCurrentPartIndex(currentPartIndex - 1)
      } else if (currentStepIndex > 0) {
         setCurrentStepIndex(currentStepIndex - 1)
         setCurrentPartIndex(steps[currentStepIndex - 1].parts.length - 1)
      }
   }

   const renderPoints = (points: { icon: React.ElementType; text: string }[] = []) => (
      <ul>
         {points.map((point, index) => (
            <BulletPoint key={index}>
               {React.createElement(point.icon, { style: { fontSize: '20px', color: '#D35400' } })} {point.text}
            </BulletPoint>
         ))}
      </ul>
   )

   const renderContent = () => {
      const isIntro = currentStep.key === 'introduction'

      if (currentPart.type === 'image') {
         return (
            <ContentContainer isIntro={isIntro}>
               <TextContent isIntro={isIntro}>
                  <div>
                     {isIntro ? (
                        <IntroDescription>{currentPart.description}</IntroDescription>
                     ) : (
                        <>{renderPoints(currentPart.features)}</>
                     )}
                  </div>
               </TextContent>
               <ImageContent isIntro={isIntro}>
                  <Image src={currentPart.content} alt={currentPart.title} isIntro={isIntro} />
               </ImageContent>
            </ContentContainer>
         )
      } else if (currentPart.type === 'video') {
         return (
            <Video controls>
               <source src={currentPart.content} type="video/mp4" />
               Your browser does not support the video tag.
            </Video>
         )
      }
   }

   const isLastSlide = currentStepIndex === steps.length - 1 && currentPartIndex === currentStep.parts.length - 1

   return (
      <Dialog
         open={open}
         onClose={onClose}
         maxWidth="md"
         fullWidth
         PaperProps={{
            style: { borderRadius: 10, backgroundColor: '#fff', marginLeft: '100px' },
         }}
         BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' },
         }}
         disableBackdropClick
         disableEscapeKeyDown
      >
         <StyledDialogTitle>{currentPart.title}</StyledDialogTitle>
         {/* <Divider style={{ backgroundColor: '#eee' }} /> */}
         <StyledDialogContent>{renderContent()}</StyledDialogContent>
         {/* <Divider style={{ backgroundColor: '#eee' }} /> */}
         <StyledDialogActions>
            <Button onClick={onClose} variant="outlined" color="secondary" style={{ marginRight: 'auto' }}>
               Skip Tutorial
            </Button>
            <RightActions>
               <Button
                  onClick={handlePrevious}
                  disabled={currentStepIndex === 0 && currentPartIndex === 0}
                  variant="outlined"
               >
                  Previous
               </Button>
               {isLastSlide ? (
                  <Button onClick={onClose} variant="contained" color="primary">
                     Finish
                  </Button>
               ) : (
                  <Button onClick={handleNext} variant="contained" color="primary">
                     Next
                  </Button>
               )}
            </RightActions>
         </StyledDialogActions>
      </Dialog>
   )
}

export default TutorialDialog
