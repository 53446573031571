// UrlStyles.tsx

import styled from 'styled-components';

export const UrlContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const UrlItem = styled.div`
  margin: 4px 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
`;

export const UrlLabel = styled.span`
  margin-right: 8px;
`;

export const UrlLink = styled.span`
  font-size: 12px;
  color: #708090;
  cursor: pointer;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
`;
