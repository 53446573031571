import React, { useEffect, useState, useRef, useReducer } from 'react'
import styled from 'styled-components'
import SimpleLoadingScreen from '../../components/Common/SimpleLoadingScreen' // New import
import {
   List,
   ListItem,
   ListItemText,
   Collapse,
   IconButton,
   ListItemIcon,
   Tabs,
   Tab,
   MenuItem,
   Select,
   Button,
   TextField,
   ToggleButtonGroup,
   ToggleButton,
   CircularProgress,
} from '@mui/material'
import { Close, Send, ArrowForwardIos, CodeOutlined, CallToActionOutlined } from '@mui/icons-material'
import Editor from '@monaco-editor/react'
import { PageContainer, PageTitle, PageSubTitle } from '../Devops/CommonStyles'
import { useApplicationApiClient } from '../../Configs/API/createAxiosConfig'
import { SandpackProvider, SandpackPreview } from '@codesandbox/sandpack-react'
import { useAuth0 } from '@auth0/auth0-react'
import FileContextMenu from './FileContextMenu'
import { getIconForFile } from 'vscode-icons-js'
import { applicationType } from '../Applications/Applications'
import useAlert from '../../components/Extras/Alerts/useAlert'
import { fileTreeReducer } from '../../Configs/ReduxSlice/FileTreeReducer'

interface CodeObject {
   [key: string]: { code: string }
}

type FileNodeType = {
   path: string
   name?: string
   type: 'file' | 'folder'
   content: string | undefined
   children: FileNodeType[] | undefined
   isOpen: boolean
}

type OpenTabType = {
   path: string
   content: string | undefined
   language: string
}

const VisionBoard = () => {
   const [loading, setLoading] = useState(true) // Loading state for chat response
   const [error, setError] = useState<string | null>(null)
   const [applications, setApplications] = useState([])
   const [selectedAppId, setSelectedAppId] = useState(null)
   const [selectedAppTechnology, setSelectedAppTechnology] = useState(null)
   const [fileTree, setFileTree] = useState([])

   const [openTabs, setOpenTabs] = useState([])
   const [activeTab, setActiveTab] = useState(0)
   const [isFileTreeVisible, setIsFileTreeVisible] = useState(true)
   const [isCodeView, setIsCodeView] = useState(true)
   const [previewLoading, setPreviewLoading] = useState(false)
   const [isSendingMessage, setIsSendingMessage] = useState(false) // Track the message sending status
   const [previewSrcDoc, setPreviewSrcDoc] = useState<CodeObject>()
   const { getActiveFEAndBEApplications } = useApplicationApiClient()
   const [socket, setSocket] = useState(null)
   const [chatInput, setChatInput] = useState('')
   const [threadId, setThreadId] = useState(null)
   const { getAccessTokenSilently } = useAuth0()
   const { showAlert } = useAlert()
   const { user, isAuthenticated, isLoading } = useAuth0()
   const clientId = user?.sub
   const debounceTimeout = useRef(null)
   const newFileFolderInputRef = useRef<HTMLInputElement>(null)
   const [selectedNodePath, setSelectedNodePath] = useState<string | null>(null)

   const [fileTreeNew, dispatch] = useReducer(fileTreeReducer, [])

   useEffect(() => {
      document.title = 'COCREATE | Vision Board'
      fetchApplications()
   }, [])

   useEffect(() => {
      return () => {
         if (socket) {
            socket.close()
         }
      }
   }, [socket])

   const fetchApplications = async () => {
      try {
         const response = await getActiveFEAndBEApplications()
         if (response.status === 200) {
            setApplications(response.data)
            if (response.data.length > 0) {
               const firstApp = response.data[0]
               setSelectedAppId(String(firstApp.id))
               setSelectedAppTechnology(firstApp.technology)
               await connectWebSocketAndFetchTree(String(firstApp.id))
            }
         } else {
            setError('Failed to load applications. Please try again later.')
            showAlert(response?.response?.data?.errorMessage || 'Failed to fetching Applications', {
               header: response?.response?.data?.message,
            })
         }
      } catch (error) {
         console.error('Error fetching applications:', error)
         setError('Failed to load applications. Please try again later.')
         showAlert('Failed to load Applications', { extraInfo: 'Please try again later' })
      }
   }

   const connectWebSocketAndFetchTree = async (appId) => {
      try {
         setLoading(true)
         const token = await getAccessTokenSilently()
         const socketClientId = `${clientId}-app-${appId}`
         const ws = new WebSocket(`${import.meta.env.VITE_VAR_VISION_BOARD_BASE_URL}/${socketClientId}?token=${token}`)

         ws.onopen = () => {
            console.log('WebSocket connection established for client:', socketClientId)
            setSocket(ws)
            const initData = JSON.stringify({
               appId,
            })
            ws.send(initData)
         }

         ws.onmessage = (event) => {
            const message = JSON.parse(event.data)
            // console.log('Received response from backend:', message)
            if (message.filePath && message.fileContent !== undefined) {
               updateFileTree(message.filePath, message.fileContent)
            } else if (message.threadId) {
               setThreadId(message.threadId)
            } else if (message.error) {
               console.error('Error from backend:', message.error)
            }

            // Stop the loading screen after receiving the chat response
            setLoading(false)
         }

         ws.onerror = (error) => {
            console.error('WebSocket error for client: ', socketClientId)
            setLoading(false)
         }

         ws.onclose = () => {
            console.log('WebSocket connection closed for client: ', socketClientId)
            setSocket(null)
         }
      } catch (error) {
         console.error('Error connecting to WebSocket:', error)
         setError('Failed to connect to WebSocket. Please try again later.')
         setLoading(false)
      }
   }

   const updateFileTree = (filePath, fileContent) => {
      dispatch({ type: 'UPDATE_FILE', payload: { filePath, fileContent } })
   }

   const clearFileTree = () => {
      dispatch({ type: 'CLEAR_TREE' })
   }

   const handleFileSelect = (fileNode) => {
      setSelectedNodePath(fileNode.path) // Track the selected node
      const existingTab = openTabs.findIndex((tab) => tab.path === fileNode.path)

      if (existingTab !== -1) {
         setActiveTab(existingTab)
      } else {
         const newTab = {
            path: fileNode.path,
            content: fileNode.content,
            language: getLanguageFromFileExtension(fileNode.path),
         }
         setOpenTabs([...openTabs, newTab])
         setActiveTab(openTabs.length)
      }
   }

   const handleFolderToggle = (fileNode) => {
      setSelectedNodePath(fileNode.path) // Track the selected node
      fileNode.isOpen = !fileNode.isOpen
      // setFileTree([...fileTree])
      dispatch({ type: 'CHANGE_FILE_TREE', payload: [...fileTreeNew] })
   }

   const handleEditorChange = (value) => {
      if (openTabs.length > 0) {
         const updatedTabs = [...openTabs]
         updatedTabs[activeTab].content = value || ''
         updateFileContentInTree(updatedTabs[activeTab].path, updatedTabs[activeTab].content)
         setOpenTabs(updatedTabs)

         if (debounceTimeout.current) {
            clearTimeout(debounceTimeout.current)
         }

         debounceTimeout.current = setTimeout(() => {
            sendFileContentToBackend(updatedTabs[activeTab].path, updatedTabs[activeTab].content)
         }, 2000)
      }
   }

   const sendFileContentToBackend = (filePath, fileContent) => {
      if (socket && socket.readyState === WebSocket.OPEN) {
         const message = JSON.stringify({
            filePath,
            fileContent,
         })
         socket.send(message)
         console.log('Sent editor change:', message)
      }
   }

   const updateFileContentInTree = (filePath, newContent) => {
      dispatch({
         type: 'UPDATE_FILE_CONTENT',
         payload: { targetFilePath: filePath, newContent },
      })
   }

   const handleTabChange = (event, newValue) => {
      setActiveTab(newValue)
   }

   const handleTabClose = (index) => {
      const newTabs = [...openTabs]
      newTabs.splice(index, 1)
      setOpenTabs(newTabs)
      if (activeTab >= newTabs.length) {
         setActiveTab(newTabs.length - 1)
      }
   }

   const handleAppChange = async (event) => {
      const appId = event.target.value
      setIsCodeView(true)

      if (appId) {
         setSelectedAppId(appId)
         setSelectedAppTechnology(applications?.find((ap: any) => ap?.id == appId)?.technology || null)

         if (socket) {
            // Optional: Notify the server of an impending close
            if (socket.readyState === WebSocket.OPEN) {
               socket.send(JSON.stringify({ action: 'closing', reason: 'app change' }))
            }

            // Create a Promise that resolves when the socket is fully closed
            const closeSocket = new Promise((resolve) => {
               socket.onclose = () => {
                  console.log('WebSocket connection closed gracefully')
                  resolve()
               }

               // Close the socket with a specific code and reason
               socket.close(1000, 'App change requested')
            })

            // Wait for the socket to close before continuing
            await closeSocket
         }

         // Reset state
         clearFileTree()
         setFileTree([])
         setOpenTabs([])
         setThreadId(null)

         // Establish a new WebSocket connection and fetch the file tree
         await connectWebSocketAndFetchTree(appId)
      }
   }

   const getLanguageFromFileExtension = (filePath) => {
      const extension = filePath.split('.').pop()
      switch (extension) {
         case 'js':
            return 'javascript'
         case 'ts':
            return 'typescript'
         case 'jsx':
            return 'javascript'
         case 'tsx':
            return 'typescript'
         case 'json':
            return 'json'
         case 'html':
            return 'html'
         case 'css':
            return 'css'
         case 'scss':
            return 'scss'
         case 'md':
            return 'markdown'
         case 'xml':
            return 'xml'
         case 'java':
            return 'java'
         case 'py':
            return 'python'
         case 'yml':
         case 'yaml':
            return 'yaml'
         default:
            return 'plaintext'
      }
   }

   const transpileAndBundleCode = async () => {
      const codeObject: CodeObject = await buildCodeObject()
      console.log('final codeObject', codeObject)
      setPreviewSrcDoc(codeObject)
   }

   const buildCodeObject = () => {
      const codeObject: CodeObject | undefined = {}
      console.log('fileTree', fileTreeNew)

      const traverse = (nodes: any) => {
         for (const node of nodes) {
            if (node.type === 'file') {
               codeObject[`${node.path}`] = {
                  code: node.content,
               }
            } else if (node.type === 'folder' && node.children) {
               traverse(node.children)
            }
         }
      }

      traverse(fileTreeNew)
      return codeObject
   }

   const handleSwitchToPreview = async () => {
      setPreviewLoading(true)
      await transpileAndBundleCode()
      setIsCodeView(false)
      setPreviewLoading(false)
   }

   const handleSendMessage = async () => {
      if (chatInput.trim() && socket) {
         try {
            setLoading(true) // Show the loading screen when chat is sent
            setIsSendingMessage(true)
            setIsCodeView(true)
            const message = {
               requirement: chatInput.trim(),
               threadId: threadId || null,
            }
            socket.send(JSON.stringify(message))
            setChatInput('')
         } catch (error) {
            console.error('Error sending message:', error)
            setLoading(false) // Ensure loading stops in case of error
            setIsSendingMessage(false)
         }
      }
   }

   const handleSave = async () => {
      setIsCodeView(true)
      setLoading(true)
      if (socket && socket.readyState === WebSocket.OPEN) {
         try {
            const token = await getAccessTokenSilently()
            const saveMessage = {
               action: 'save',
            }
            socket.send(JSON.stringify(saveMessage))
            console.log('Save initiated:', saveMessage)
         } catch (error) {
            console.error('Error initiating save:', error)
         }
      }
   }

   const [contextMenu, setContextMenu] = useState({ mouseX: null, mouseY: null, node: null })
   const [currentNodePath, setCurrentNodePath] = useState<string | null>(null)
   const [currentNodeType, setCurrentNodeType] = useState<'file' | 'folder' | null>(null)
   const [newFileName, setNewFileName] = useState<string | null>(null)

   const handleCreateNewFileOrFolder = () => {
      if (!newFileName || !currentNodePath) {
         handleCancelNewFileOrFolder()
         return
      }

      dispatch({
         type: 'RENAME_FILE_OR_FOLDER',
         payload: {
            currentNodePath,
            newFileName,
         },
      })

      setCurrentNodePath(null)
      setNewFileName(null)
   }

   const handleCancelNewFileOrFolder = () => {
      // if (!currentNodePath) return;

      dispatch({
         type: 'REMOVE_NEW_FILE_OR_FOLDER',
         payload: { nodePathToRemove: currentNodePath },
      })

      setCurrentNodePath(null)
      setNewFileName(null)
   }

   const addFileOrFolder = (parentPath: string, type: 'file' | 'folder') => {
      const newNode = {
         path: `${parentPath}/New ${type === 'file' ? 'File' : 'Folder'}`,
         type,
         content: type === 'file' ? '' : undefined,
         children: type === 'folder' ? [] : undefined,
         isOpen: false,
      }
      setCurrentNodePath(newNode.path)

      dispatch({
         type: 'ADD_FILE_OR_FOLDER',
         payload: {
            parentPath,
            newNode,
         },
      })

      setTimeout(() => {
         if (newFileFolderInputRef?.current) {
            newFileFolderInputRef.current.focus()
         }
      }, 0)
   }

   const deleteFileOrFolder = (filePath) => {
      const deleteNode = (nodes, parent = null, index = null) => {
         for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i]
            if (node.path === filePath) {
               nodes.splice(i, 1)
               break
            } else if (node.children) {
               deleteNode(node.children, node, i)
            }
         }
      }
      let newFileTree = [...fileTreeNew]
      deleteNode(newFileTree)
      // setFileTree([...newFileTree])
      dispatch({ type: 'CHANGE_FILE_TREE', payload: [...newFileTree] })
   }

   const renameFileOrFolder = (oldPath, newPath) => {
      const renameNode = (nodes) => {
         for (const node of nodes) {
            if (node.path === oldPath) {
               node.path = newPath
               break
            } else if (node.children) {
               renameNode(node.children)
            }
         }
      }
      let newFileTree = [...fileTreeNew]

      renameNode(newFileTree)
      // setFileTree([...newFileTree])
      dispatch({ type: 'CHANGE_FILE_TREE', payload: [...newFileTree] })
   }

   const renderFileTree = (fileNodes, folderOpen: boolean) => {
      return fileNodes.map((fileNode) => (
         <React.Fragment key={fileNode.path}>
            <FileSidebar
               onClick={() => (fileNode.type === 'file' ? handleFileSelect(fileNode) : handleFolderToggle(fileNode))}
               onContextMenu={(event) => {
                  event.preventDefault()
                  setContextMenu({ mouseX: event.clientX, mouseY: event.clientY, node: fileNode })
               }}
               style={{ paddingLeft: folderOpen ? '8px' : '12px' }}
               selected={fileNode.path === selectedNodePath} // Check if this node is selected
            >
               <CustomListItemIcon>
                  {fileNode.type === 'file' ? (
                     <img
                        src={`https://raw.githubusercontent.com/vscode-icons/vscode-icons/master/icons/${
                           getIconForFile(fileNode.path.split('/').pop()) || 'default_file.svg'
                        }`}
                     />
                  ) : fileNode.isOpen ? (
                     <ArrowForwardIos
                        style={{ transform: 'rotate(90deg)', width: '14px', height: '14px' }}
                        fontSize="small"
                     />
                  ) : (
                     <ArrowForwardIos style={{ width: '14px', height: '14px' }} />
                  )}
               </CustomListItemIcon>
               {fileNode.path === currentNodePath && newFileName !== null ? (
                  <input
                     type="text"
                     value={newFileName}
                     ref={newFileFolderInputRef}
                     onChange={(e) => setNewFileName(e.target.value)}
                     onKeyDown={(e) => {
                        if (e.key === 'Enter') handleCreateNewFileOrFolder()
                        if (e.key === 'Escape') handleCancelNewFileOrFolder()
                     }}
                     autoFocus
                  />
               ) : (
                  <SidebarFileText primary={fileNode.path.split('/').pop()} style={{ fontSize: '15px' }} />
               )}
            </FileSidebar>
            {fileNode.type === 'folder' && (
               <Collapse in={fileNode.isOpen} timeout="auto" unmountOnExit>
                  <div style={{ marginLeft: '16px', borderLeft: '1px solid rgba(0, 0, 0, 0.10)' }}>
                     {renderFileTree(fileNode.children || [], true)}
                  </div>
               </Collapse>
            )}
         </React.Fragment>
      ))
   }

   if (error) {
      return <ErrorContainer>Error: {error}</ErrorContainer>
   }

   return (
      <PageContainer>
         <PageContainerAppVision colapsed={String(sessionStorage.getItem('sideBarColapsed'))}>
            <Header>
               <div>
                  <PageTitle>Vision Board</PageTitle>
                  <PageSubTitle>
                     Select an application from the dropdown and use Nova's AI prompt to convert your vision into
                     reality.
                  </PageSubTitle>
               </div>
               <HeaderActions>
                  <Select
                     value={selectedAppId}
                     onChange={handleAppChange}
                     placeholder="Select Application"
                     style={{
                        height: '36px',
                        fontSize: '15px',
                        paddingLeft: '4px',
                        borderColor: 'rgba(0, 0, 0, 0.08)',
                     }}
                  >
                     {applications.map((app: applicationType) => (
                        <MenuItem key={app.id} value={String(app.id)} style={{ height: '30px', fontSize: '14px' }}>
                           {app.appName}
                        </MenuItem>
                     ))}
                  </Select>

                  <ToggleButtonGroup
                     // onChange={isCodeView ? handleSwitchToPreview : () => setIsCodeView(true)}
                     style={{
                        marginLeft: '12px',
                        borderColor: 'rgba(0, 0, 0, 0.08)',
                        height: '36px',
                     }}
                     color="primary"
                     value={'web'}
                     exclusive
                  >
                     <ToggleButton
                        selected={isCodeView}
                        onClick={() => setIsCodeView(true)}
                        style={{
                           textTransform: 'none',
                           borderColor: 'rgba(0, 0, 0, 0.08)',
                        }}
                        value="web"
                     >
                        <CodeOutlined
                           fontSize="small"
                           style={{
                              marginRight: '4px',
                              color: isCodeView ? '#333' : 'rgba(0, 0, 0, 0.40)',
                              marginTop: '-2px',
                           }}
                        />
                        Code
                     </ToggleButton>
                     <ToggleButton
                        onClick={handleSwitchToPreview}
                        selected={!isCodeView}
                        disabled={selectedAppTechnology != 'REACT'}
                        value="preview"
                        style={{
                           textTransform: 'none',
                           borderColor: 'rgba(0, 0, 0, 0.08)',
                        }}
                     >
                        <CallToActionOutlined
                           style={{
                              marginRight: '6px',
                              color: isCodeView ? 'rgba(0, 0, 0, 0.34)' : '#333',
                              marginTop: '-1.5px',
                              fontSize: '17px',
                           }}
                           fontSize="small"
                        />{' '}
                        Preview
                     </ToggleButton>
                  </ToggleButtonGroup>
                  <Button variant="contained" color="primary" onClick={handleSave} style={{ marginLeft: '12px' }}>
                     Commit
                  </Button>
               </HeaderActions>
            </Header>
            <ContentContainer>
               <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}></div>
               <ChatContainer>
                  <ChatInputContainer>
                     <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="E.g. Ask Nova to create an admin tool..."
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                        onKeyPress={(e) => e.key === 'Enter' && handleSendMessage()}
                        style={{ borderRadius: '1px' }}
                     />
                     <IconButton color="primary" onClick={handleSendMessage} style={{ marginLeft: '-50px' }}>
                        <Send />
                     </IconButton>
                  </ChatInputContainer>
               </ChatContainer>

               <MainContent>
                  {isCodeView ? (
                     <>
                        {isFileTreeVisible && (
                           <FileExplorer>
                              <AppNameContainer>
                                 <AppName>
                                    {applications.find((app) => app.id === Number(selectedAppId))?.appName ||
                                       'Application Name'}
                                 </AppName>
                              </AppNameContainer>
                              <List>{renderFileTree(fileTreeNew, false)}</List>
                           </FileExplorer>
                        )}
                        <EditorContainer style={{ minHeight: '520px' }}>
                           {loading ? (
                              <SimpleLoadingScreen /> // Show loading screen in the editor
                           ) : (
                              <>
                                 <TabsContainer>
                                    <StyledTabs
                                       value={activeTab}
                                       onChange={handleTabChange}
                                       variant="scrollable"
                                       scrollButtons="auto"
                                    >
                                       {openTabs.map((tab, index) => {
                                          const fileName = tab.path.split('/').pop()
                                          const fileIcon = getIconForFile(fileName) || 'default_file.svg'

                                          return (
                                             <StyledTab
                                                key={tab.path}
                                                label={
                                                   <TabLabel>
                                                      <CustomListItemIcon>
                                                         <img
                                                            src={`https://raw.githubusercontent.com/vscode-icons/vscode-icons/master/icons/${fileIcon}`}
                                                            alt={`${fileName} icon`}
                                                            style={{
                                                               width: '15px',
                                                               height: '15px',
                                                               marginRight: '8px',
                                                            }}
                                                         />
                                                      </CustomListItemIcon>
                                                      {fileName}
                                                      <CloseButton
                                                         onClick={(e) => {
                                                            e.stopPropagation()
                                                            handleTabClose(index)
                                                         }}
                                                      >
                                                         <Close fontSize="small" />
                                                      </CloseButton>
                                                   </TabLabel>
                                                }
                                             />
                                          )
                                       })}
                                    </StyledTabs>
                                 </TabsContainer>
                                 {openTabs.length > 0 ? (
                                    <div style={{ minHeight: '500px' }}>
                                       <StyledEditor
                                          height="100%"
                                          language={openTabs[activeTab]?.language}
                                          value={openTabs[activeTab]?.content}
                                          theme="vs-light"
                                          options={{
                                             automaticLayout: true,
                                             wordWrap: 'on',
                                             minimap: {
                                                enabled: false,
                                             },
                                          }}
                                          onChange={handleEditorChange}
                                       />
                                    </div>
                                 ) : (
                                    <Placeholder>Switch To Preview / Select Files To Edit</Placeholder>
                                 )}
                              </>
                           )}
                        </EditorContainer>
                     </>
                  ) : (
                     <PreviewContainer colapsed={String(sessionStorage.getItem('sideBarColapsed'))}>
                        {previewLoading ? (
                           <CircularProgress />
                        ) : (
                           <SandpackProvider template="react" files={previewSrcDoc}>
                              <SandpackPreview showRefreshButton={false} showOpenInCodeSandbox={false} />
                           </SandpackProvider>
                        )}
                     </PreviewContainer>
                  )}
               </MainContent>
            </ContentContainer>
         </PageContainerAppVision>
         <FileContextMenu
            contextMenu={contextMenu}
            onClose={() => setContextMenu({ mouseX: null, mouseY: null, node: null })}
            addFileOrFolder={addFileOrFolder}
            renameFileOrFolder={renameFileOrFolder}
            deleteFileOrFolder={deleteFileOrFolder}
            setNewFileName={setNewFileName}
            setCurrentNodePath={setCurrentNodePath}
            setCurrentNodeType={setCurrentNodeType}
         />
      </PageContainer>
   )
}

export default VisionBoard
const PreviewContainer = styled.div<{ colapsed: string }>`
   & .sp-preview,
   & .sp-preview-container {
      height: calc(100vh - 300px);
      width: ${(props) => (props.colapsed == 'true' ? ' calc(100vw - 200px)' : ' calc(100vw - 300px)')};
      @media (max-width: 1000px) {
         width: calc(100vw - 140px);
      }
   }
`
const Header = styled.div`
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 16px;
`

const HeaderActions = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 16px;
`

const ContentContainer = styled.div`
   display: flex;
   flex-direction: column;
   height: calc(100vh - 100px);
`

const MainContent = styled.div`
   display: flex;
   position: relative;
   overflow: hidden;
   border: 1px solid rgba(0, 0, 0, 0.08);
   box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
   border-radius: 12px;
`

const FileExplorer = styled.div`
   width: 220px;
   background-color: #fafafa;
   border-right: 1px solid #ddd;
   overflow-y: auto;
   position: relative;
`

const EditorContainer = styled.div`
   flex-grow: 1;
   height: 100%;
   background-color: #fff;
   display: flex;
   flex-direction: column;
   overflow: hidden;
   position: relative;
`

const TabsContainer = styled.div`
   flex-shrink: 0;
   background-color: #fff;
   border-bottom: 1px solid #ddd;
`

const StyledEditor = styled(Editor)`
   flex-grow: 1;
`

const Placeholder = styled.div`
   flex-grow: 1;
   display: flex;
   justify-content: center;
   align-items: center;
   font-size: 18px;
   color: #888;

   border-radius: 0 0 8px 0;
`

const StyledTabs = styled(Tabs)`
   color: #fff;
   font-size: 14px;
   text-transform: none;
   min-width: 150px;
   background-color: #fafafa;
   border: none !important;
`

const StyledTab = styled(Tab)`
   color: #333;
   font-weight: 500;
   text-transform: none !important;

   padding: 8px 16px !important;
   &.Mui-selected {
      background-color: #f0f0f0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
   }

   &:hover {
      background-color: #e0e0e0;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
   }
`

const ErrorContainer = styled.div`
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   font-size: 24px;
   color: #ff5555;
`

const TabLabel = styled.div`
   display: flex;
   align-items: center;
`

const IconImage = styled.img`
   width: 15px;
   height: 15px;
   margin-right: 8px;
`

const CloseButton = styled.div`
   margin-left: 8px;
   color: #888;
   & .MuiSvgIcon-root {
      font-size: 16px;
      font-weight: 900;
   }

   &:hover {
      color: #ff5555;
   }
`

const ChatContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: flex-end;
   height: 60px;
   padding-bottom: 10px;
   background-color: #fff;
`
const ChatInputContainer = styled.div`
   display: flex;
   align-items: center;
   & .MuiOutlinedInput-root {
      outline: none;
      border-radius: 12px;
      & :hover {
         border: none !important;
      }
   }
   & .Mui-focused {
      border: none !important;
      outline: none !important;
   }
   & fieldset {
      border: 1px solid rgba(0, 0, 0, 0.14);
      box-shadow: 0 0 #0000, 0 0 #0000, 0 0 #0000, 0 0 #0000, 0px 1px 2px 0px rgba(0, 0, 0, 0.08);
      outline: none;
      border-radius: 12px;
      & :hover,
      & :focus {
         border: none !important;
         border: none;
         outline: none;
      }
   }
`

const CustomListItemIcon = styled(ListItemIcon)`
   color: #666;
   min-width: 24px !important;
   & .MuiSvgIcon-root {
      font-size: 15px;
   }
   & img {
      width: 15px;
      height: 15px;
   }
`
const FileSidebar = styled(ListItem)<{ selected: boolean }>`
   padding-top: 2px !important;
   padding-bottom: 2px !important;
   cursor: pointer;
   background-color: ${({ selected }) => (selected ? '#e0e0e0' : 'transparent')}; // Highlight if selected
   &:hover {
      background-color: #f5f5f5; // Lighter background on hover
   }
`

const SidebarFileText = styled(ListItemText)`
   span {
      font-size: 15px;
      color: #000000a6;
   }
`

const AppNameContainer = styled.div`
   display: flex;
   align-items: center;
   padding: 8px 16px; // Match padding with StyledTabs
   background-color: #fafafa; // Match background color with StyledTabs
   border-bottom: 1px solid #ddd;
   font-weight: bold;
   font-size: 14px; // Adjust font-size to match other UI elements
   height: 32px; // Ensure it matches the height of the StyledTabs
   white-space: nowrap; // Prevent wrapping of the app name
   justify-content: space-between;
`

const AppName = styled.span`
   flex-grow: 1; // Take up the remaining space
   overflow: hidden;
   text-overflow: ellipsis;
   color: black;
`

const PageContainerAppVision = styled.div<{ colapsed: string }>`
   max-width: 1000px;
   margin: 30px auto;
   @media (min-width: 1300px) {
      margin: 30px ${(props) => (props.colapsed == 'true' ? '30px' : 'auto')};
      max-width: 1400px;
   }
`
