// src/routes.ts

// pages
import Home from './pages/Home'
// import About from "./pages/About";

import { IRoute } from './interfaces/interfaces'
import CodeAndBuild from './pages/Devops/Code&Build/CodeBuild'
import KubeWorkloads from './pages/Devops/KubeWorkloads/KubeWorkloads'
import Applications from './pages/Applications/Applications'
import Login from './pages/Auth/Login'
import Tenants from './pages/Configurations/Tenants/Tenant'
import Repositories from './pages/Configurations/Repositories/Repositories'
import Domains from './pages/Configurations/Domains/Domains'
import Observability from './pages/Observability/Observability'
import IntegrationHub from './pages/IntegrationHub/IntegrationHub'
import TeamManagement from './pages/TeamManagement/TeamManagement'
import VisionBoard from './pages/VisionBoard/VisionBoard'
import CredentialsVault from './pages/CredentialsVault/CredentialsVault'

export const routes: Array<IRoute> = [
   {
      key: 'login',
      title: 'login',
      path: '/',
      enabled: true,
      component: Login,
   },
   {
      key: 'home-route',
      title: 'Home',
      path: '/Home',
      enabled: true,
      component: Home,
   },
   {
      key: 'code-build',
      title: 'Code And Build',
      path: '/code-build',
      enabled: true,
      component: CodeAndBuild,
   },
   {
      key: 'kube-workloads',
      title: 'KubeWorkloads',
      path: '/kube-workloads',
      enabled: true,
      component: KubeWorkloads,
   },
   {
      key: 'applications',
      title: 'applications',
      path: '/applications',
      enabled: true,
      component: Applications,
   },
   {
      key: 'tenants',
      title: 'tenants',
      path: '/tenants',
      enabled: true,
      component: Tenants,
   },
   {
      key: 'repositories',
      title: 'repositories',
      path: '/repositories',
      enabled: true,
      component: Repositories,
   },
   {
      key: 'domains',
      title: 'domains',
      path: '/domains',
      enabled: true,
      component: Domains,
   },
   {
      key: 'observability',
      title: 'Observability',
      path: '/observability',
      enabled: true,
      component: Observability,
   },
   {
       key: 'integration-hub',
       title: 'Integration Hub',
       path: '/integration-hub',
       enabled: true,
       component: IntegrationHub
   },
   {
       key: 'teams',
       title: 'Teams',
       path: '/teams',
       enabled: true,
       component: TeamManagement
   },
   {
      key: 'visionBoard',
      title: 'VisionBoard',
      path: '/vision-board',
      enabled: true,
      component: VisionBoard
  },
  {
     key: 'credentials-vault',
     title: 'CredentialsVault',
     path: '/credentials-vault',
     enabled: true,
     component: CredentialsVault,
  },
]
