import React, { useState } from 'react';
import {
  TableCell,
  IconButton,
  Tooltip,
} from '@mui/material';
import { OpenInNew, ContentCopy, CheckCircle } from '@mui/icons-material';
import { UrlContainer, UrlItem, UrlLabel, UrlLink } from './UrlStyles';
import { truncateString } from '../../components/helpers/stringFns';
import { useTenantApiClient } from '../../Configs/API/createAxiosConfig';

interface KubeNativeApplicationProps {
  app: any; // Replace 'any' with the appropriate type
}

const KubeNativeApplication: React.FC<KubeNativeApplicationProps> = ({ app }) => {
  const [vaultToken, setVaultToken] = useState<string>('');
  const [loadingToken, setLoadingToken] = useState<boolean>(false);
  const [tokenGenerated, setTokenGenerated] = useState<boolean>(false);
  const [copied, setCopied] = useState<{ host: boolean; port: boolean; token: boolean }>({
    host: false,
    port: false,
    token: false,
  });

  const tenantService = useTenantApiClient();

  // Function to handle the button click
  const handleGenerateVaultToken = async () => {
    if (loadingToken) return;
    setLoadingToken(true);
    try {
      const response = await tenantService.generateVaultToken(app.tenantId);
      if (response.status === 200 || response.status === 201) {
        setVaultToken(response.data);
        setTokenGenerated(true);
      }
    } catch (error) {
      console.error('Error generating Vault Token', error);
    } finally {
      setLoadingToken(false);
    }
  };

  // Function to handle copying
  const handleCopy = (type: 'host' | 'port' | 'token', value: string) => {
    navigator.clipboard.writeText(value);
    setCopied((prev) => ({ ...prev, [type]: true }));
    setTimeout(() => {
      setCopied((prev) => ({ ...prev, [type]: false }));
    }, 2000); // Show the tick icon for 2 seconds
  };

  return (
    <TableCell align="center">
      <UrlContainer>
        {/* Host and Port in the same row */}
        {app?.appAttributes?.loadBalancerHost && app?.appAttributes?.clientPort && (
          <UrlItem>
            <UrlLabel>Host:</UrlLabel>
            <span>{`${app.appAttributes.loadBalancerHost}.colate.io`}</span>
            <Tooltip title={copied.host ? "Copied" : "Copy Host"}>
              <IconButton onClick={() => handleCopy('host', `${app.appAttributes.loadBalancerHost}.colate.io`)}>
                {copied.host ? (
                  <CheckCircle style={{ fontSize: '16px' }} />
                ) : (
                  <ContentCopy style={{ fontSize: '16px' }} />
                )}
              </IconButton>
            </Tooltip>

            <UrlLabel style={{ marginLeft: '10px' }}>Port:</UrlLabel>
            <span>{app.appAttributes.clientPort}</span>
            <Tooltip title={copied.port ? "Copied" : "Copy Port"}>
              <IconButton onClick={() => handleCopy('port', app.appAttributes.clientPort.toString())}>
                {copied.port ? (
                  <CheckCircle style={{ fontSize: '16px' }} />
                ) : (
                  <ContentCopy style={{ fontSize: '16px' }} />
                )}
              </IconButton>
            </Tooltip>
          </UrlItem>
        )}

        {/* Ingress URL */}
        {app?.appAttributes?.ingressUrl && (
          <UrlItem>
            <UrlLabel>Ingress URL:</UrlLabel>
            <span onClick={() => window.open(app.appAttributes.ingressUrl)} style={{ cursor: 'pointer', color: '#1976d2' }}>
              {truncateString(app.appAttributes.ingressUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </span>
          </UrlItem>
        )}

        {/* Repository URL */}
        {app?.appAttributes?.repositoryUrl && (
          <UrlItem>
            <UrlLabel>Repository Url:</UrlLabel>
            <UrlLink onClick={() => window.open(app.appAttributes?.repositoryUrl)}>
              {truncateString(app.appAttributes?.repositoryUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </UrlLink>
          </UrlItem>
        )}

        {/* Generate Vault Token Link */}
        <UrlItem>
          <UrlLink
            onClick={handleGenerateVaultToken}
            style={{
              cursor: loadingToken || tokenGenerated ? 'not-allowed' : 'pointer',
              color: loadingToken || tokenGenerated ? 'rgba(0, 0, 0, 0.26)' : '#1976d2',
              pointerEvents: tokenGenerated ? 'none' : 'auto',
              fontSize: '14px',
            }}
          >
            {loadingToken ? 'Generating...' : tokenGenerated ? 'Token Generated' : 'Generate token & view credentials in Vault'}
          </UrlLink>

          {/* Copy Token Button */}
          {tokenGenerated && (
            <>
              <Tooltip title={copied.token ? "Copied" : "Copy Vault Token"}>
                <IconButton onClick={() => handleCopy('token', vaultToken)}>
                  {copied.token ? (
                    <CheckCircle style={{ fontSize: '16px' }} />
                  ) : (
                    <ContentCopy style={{ fontSize: '16px' }} />
                  )}
                </IconButton>
              </Tooltip>

              {/* Link to Vault UI */}
              <UrlLink
                onClick={() => window.open('https://vault.colate.io', '_blank')}
                style={{ marginLeft: '10px', cursor: 'pointer' }}
              >
                Open Vault UI
                <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
              </UrlLink>
            </>
          )}
        </UrlItem>
      </UrlContainer>
    </TableCell>
  );
};

export default KubeNativeApplication;
