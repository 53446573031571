import { ErrorOutlineOutlined } from '@mui/icons-material'
import { CircularProgress } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import LoadingAnimation from './LoadingAnimation'

const Container = styled.div`
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   text-align: center;
   height: 90%;
`

const Icon = styled(ErrorOutlineOutlined)`
   color: red;
   font-size: 5rem;
   margin-bottom: 20px;
`

const SimpleLoadingScreen = () => {
   return (
      <Container>
         <LoadingAnimation />
         {/* <CircularProgress size={50} /> */}
      </Container>
   )
}

export default SimpleLoadingScreen
