import React, { useEffect, useState } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import InfoIcon from '@mui/icons-material/Info'
import Tooltip from '@mui/material/Tooltip'
import FormControlLabel from '@mui/material/FormControlLabel'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import Divider from '@mui/material/Divider'
import gitLabLogo from '/static/images/gitlab.png'
import gitHubLogo from '/static/images/github.png'
import bitBucketLogo from '/static/images/bitbucket.png'
import DialogTitle from '@mui/material/DialogTitle'
import FormControl from '@mui/material/FormControl'
import FormLabel from '@mui/material/FormLabel'
import TextField from '@mui/material/TextField'
import InputLabel from '@mui/material/InputLabel'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import MenuItem from '@mui/material/MenuItem'
import Grid from '@mui/material/Unstable_Grid2' // Grid version 2
import { SpaceBottom, Container, CenterAlignedDiv } from '../ApplicationStyles'
import ReviewModal from '../ReviewModal/ReviewModal'
import { useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { addTenantSlice, dispatchTenantsAPIData } from '../../../Configs/ReduxSlice/tenantSlice'
import { RepositoryStatus, RepoType } from '../../Configurations/Repositories/Repositories'
import { listOfRepositoriesToDisplay } from '../../Configurations/Repositories/ListOfRepository'
import { CheckCircle, Error, Info, Warning } from '@mui/icons-material'
import { theme } from '../../../styles/theme'
import { radioImageHandler } from '../../../components/Extras/CreateTenant/RepoImagesHandler'
import { DomainType } from '../../Configurations/Domains/Domains'
import { payloadObjectVerification } from '../../../components/helpers/payloadObjectVerification'
import { useAuth0 } from '@auth0/auth0-react'
import { Button, Chip, CircularProgress, FormHelperText, Skeleton } from '@mui/material'
import { addApplicationSlice } from '../../../Configs/ReduxSlice/ApplicationSlice'
import { useAppDispatch } from '../../../Configs/store/config/useAppDispatch'
import useAlert from '../../../components/Extras/Alerts/useAlert'
import { useNavigate } from 'react-router-dom'

interface CreateTenantDialogProps {
   openTenantDialog: boolean
   setOpenTenantDialog: Function
   setIsPreparing?: Function
}
interface RepositoryType {
   repositoryType: string
   id: number
}
interface RepositoryGroup {
   id: number
   name: string
}
const CreateTenantDialog: React.FC<CreateTenantDialogProps> = ({
   openTenantDialog,
   setOpenTenantDialog,
   setIsPreparing,
}) => {
   const [addTenentAPILoading, setAddTenentAPILoading] = useState<boolean>(false)
   const [selectedRepositoryGroupId, setSelectedRepositoryGroupId] = useState<string>('')
   const [maxTenantnameLength, setmaxTenantLength] = useState<number | null>(null)
   const [selectedDomain, setSelectedDomain] = useState('')
   const [selectedTenantType, setSelectedTenantType] = useState<string>('DEVELOPMENT')
   const [openReviewModal, setOpenReviewModal] = useState({
      status: false,
      message: '',
      isDelete: false,
   })
   const [selectedRepoName, setSelectedRepoName] = useState('')
   const [loadingRepositoryGroups, setRepositoryGroupsLoading] = useState<boolean>(false)
   const [configuredRepos, setConfiguredRepos] = useState<RepositoryStatus>({})
   const [domains, setDomains] = useState<DomainType[]>([])
   const [repositoryTypes, setRepositoryTypes] = useState<RepositoryType[]>([])
   const [newTenantName, setnewTenantName] = useState('')
   const [repositoryGroups, setRepositoryGroups] = useState<RepositoryGroup[]>([])
   const [error, setError] = useState<string | null>(null)
   const [formError, setformError] = useState<string>('')
   const tenantService = useTenantApiClient()
   const dispatch = useAppDispatch()
   const { user } = useAuth0()
   const { showAlert } = useAlert()
   const navigate = useNavigate()
   const [isPatCreated, setIsPatCreated] = useState<boolean>(true)
   const [selectedRepositoryGroupPath, setSelectedRepositoryGroupPath] = useState<string>('')
   const [selectedRepo, setSelectedRepo] = useState<RepositoryType | null>(null)

   const handleTenantTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedTenantType(event.target.value)
   }

   const fetchRepositoriesConfigured = async () => {
      try {
         const response = await tenantService.getRepositories()
         if (response.status == 200) {
            const reposConfigured = response.data.reduce(
               (acc: RepositoryStatus, repo: { repositoryType: RepoType }) => {
                  acc[repo.repositoryType] = true
                  return acc
               },
               {}
            )
            setConfiguredRepos(reposConfigured)
            setRepositoryTypes(response.data)
         } else {
            setError('Failed to fetch repository configurations')
            showAlert(response?.response?.data?.errorMessage || 'Failed to load repository configurations', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         setError('Failed to fetch repository configurations')
         showAlert('Failed to fetch repository configurations', { extraInfo: 'Please try again later' })
      } finally {
         // setLoading(false)
      }
   }

   const fetchRepositoryGroupData = async (codeRepositoryId: number) => {
      if (!codeRepositoryId) return
      try {
         setRepositoryGroupsLoading(true)
         const response = await tenantService.getRepositoryGroups(codeRepositoryId)
         if (response.status == 200) {
            setRepositoryGroupsLoading(false)
            setRepositoryGroups(response.data)
         } else {
            showAlert(response?.response?.data?.errorMessage || 'Failed to load repository group', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         setError('Failed to fetch repository group')
         showAlert('Failed to fetch repository group', { extraInfo: 'Please try again later' })
      } finally {
         setRepositoryGroupsLoading(false)
      }
   }

   const fetchDomains = async () => {
      try {
         const response = await tenantService.getDomains()
         if (response.status == 200) {
            setDomains(response.data)
            if (response.data[0] && response.data[0].status === 'Verified') {
               setSelectedDomain(response.data[0].id)
            }
         } else {
            setError('Failed to load tenants. Please try again later.')

            showAlert(response?.response?.data?.errorMessage || 'Failed to fetch tenant', {
               header: response?.response?.data?.message,
            })
         }
      } catch (err) {
         console.error('Error fetching tenants', err)
         setError('Failed to load tenants. Please try again later.')
         showAlert('Failed to load tenants', { extraInfo: 'Please try again later' })
      } finally {
         // setLoading(false)
      }
   }

   useEffect(() => {
      fetchRepositoriesConfigured()
      fetchDomains()
   }, [])
   const handleClose = () => {
      setOpenTenantDialog(false)
      setIsPreparing && setIsPreparing(true)
      clearAllFields()
   }

   const checkPatAddedForGroup = async (groupId: string, selectedGroupPath: string) => {
      try {
         const response = await tenantService.checkGroupPat(selectedRepo.id, selectedGroupPath)
         setIsPatCreated(response.data)
      } catch (err) {
         console.error('Error checking PAT for group:', err)
         setIsPatCreated(false)
      }
   }

   const handleRepositoryGroupChange = (event: SelectChangeEvent) => {
      setformError('')
      setSelectedRepositoryGroupId(event.target.value as string)
      const selectedGroup = repositoryGroups.find((group) => group.id === event.target.value)

      if (selectedGroup) {
         setSelectedRepositoryGroupPath(selectedGroup.path)
         checkPatAddedForGroup(selectedRepositoryGroupId, selectedGroup.path)
      }
   }

   const handleChangeDomain = (event: SelectChangeEvent) => {
      setformError('')
      setSelectedDomain(event.target.value as string)
   }

   const handleSelectRepoClick = (radioButtonName: RepoType, isRepoConfigured?: boolean) => {
      console.log('handleSelectRepoClick', isRepoConfigured, radioButtonName)

      if (isRepoConfigured) {
         setformError('')
         setSelectedRepoName(radioButtonName)
         const selectedRepo = repositoryTypes.find((repo) => repo.repositoryType === radioButtonName)
         if (selectedRepo) {
            fetchRepositoryGroupData(selectedRepo.id)
            setSelectedRepo(selectedRepo)
         }
      } else {
         setOpenReviewModal({
            status: true,
            message: radioButtonName,
            isDelete: false,
         })
      }
   }
   const clearAllFields = () => {
      setformError('')
      setSelectedRepositoryGroupId('')
      setSelectedDomain('')
      setnewTenantName('')
      setSelectedRepoName('')
   }
   const handleSignIn = (repoType: RepoType) => {
      if (user?.sub) {
         window.location.href = `${import.meta.env.VITE_VAR_TMS_BASE_URL}/oauth?client_id=${encodeURIComponent(
            user.sub
         )}&repository_type=${repoType}`
      } else {
         // setError('User authentication required.')
      }
   }

   const handleAddTenant = async () => {
      setformError('')
      if (!user) {
         console.error('User data is unavailable.')
         return
      }
      try {
         const topLevelRepoTypes = repositoryGroups.find(
            (group) => group.id === Number(selectedRepositoryGroupId)
         ) as any
         const selectedRepo = repositoryTypes.find((repo) => repo.repositoryType === selectedRepoName)
         const tenantToSave = {
            clientId: user?.sub,
            tenantName: newTenantName,
            region: 'US',
            environmentType: 'Shared',
            tenantType: selectedTenantType,
            domainId: selectedDomain,
            codeRepositoryId: selectedRepo?.id,
            codeRepositoryGroupId: selectedRepositoryGroupId,
            codeRepositoryGroupName: topLevelRepoTypes?.full_name || '',
         }
         const verifyPayload = payloadObjectVerification(tenantToSave)
         // To verify all Fileds are filled
         if (verifyPayload && verifyPayload[0] === true) {
            console.log('Form Not Filled', verifyPayload[1], verifyPayload, tenantToSave)
            setformError(verifyPayload[1])
            return
         }
         if (newTenantName.match(/[., +, *, ?, ^, $, (, ), [,\], {, }, |, \\,@]|[A-Z]/g)) {
            console.log('tenant name has capital or special chars')
            setformError('tenantName')
            return
         }
         const selectedDomainname = domains?.find((dom) => dom.id == Number(selectedDomain))?.domainName
         const maxTenantlength = Math.round((63 - Number(selectedDomainname?.length)) / 2)
         // To verify length of application
         if (maxTenantlength < newTenantName.length) {
            setformError('tenantNameLength')
            setmaxTenantLength(maxTenantlength)
            console.log('maxTenantlength', maxTenantlength)
            return
         }
         console.log('addTenentObjVerification', tenantToSave)
         setAddTenentAPILoading(true)
         const response = await tenantService.addTenant(tenantToSave)
         console.log('res', response)

         if (response.status == 200 || response.status == 201) {
            dispatch(addTenantSlice(response.data))
            handleClose()
            clearAllFields()
            navigate('/tenants')
         } else
            showAlert(response?.response?.data?.errorMessage || 'Failed to add tenant', {
               header: response?.response?.data?.message,
            })
         setAddTenentAPILoading(false)
      } catch (err) {
         setAddTenentAPILoading(false)
         console.error('Error adding tenant:', err)
         showAlert('Failed to add tenant', { extraInfo: 'Please try again later' })
      } finally {
         setAddTenentAPILoading(false)
      }
   }
   const handleBlur = () => {
      setnewTenantName((prevName) => prevName.replace(/\s+/g, '-'))
   }
   return (
      <React.Fragment>
         <Dialog
            open={openTenantDialog}
            onClose={handleClose}
            PaperProps={{
               style: { width: '60vw', maxHeight: '70vh', borderRadius: 10 },
               component: 'form',
            }}
            scroll={'paper'}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
         >
            <DialogTitle sx={{ fontWeight: 600 }}>Create Tenant</DialogTitle>

            <DialogContent>
               <Container>
                  <FormControl component="fieldset" margin="normal">
                     <FormLabel component="legend">Select Type</FormLabel>
                     <RadioGroup
                        aria-label="tenantType"
                        name="tenantType"
                        value={selectedTenantType}
                        onChange={handleTenantTypeChange}
                        row // Arrange buttons in a row
                     >
                        <FormControlLabel value="DEVELOPMENT" control={<Radio />} label="Development" />
                        <FormControlLabel
                           value="production"
                           control={<Radio />}
                           label={
                              <div style={{ display: 'flex', alignItems: 'center' }}>
                                 Production
                                 <Tooltip
                                    title="Production environments may have additional security, availability & scalability requirements.
                                  Talk to us to enable this feature."
                                 >
                                    <Chip
                                       label="Upgrade"
                                       style={{ marginLeft: '8px' }}
                                       color="info"
                                       variant="outlined"
                                       icon={<Info />}
                                    />
                                 </Tooltip>
                              </div>
                           }
                           disabled
                        />
                     </RadioGroup>
                  </FormControl>
                  <TextField
                     required
                     margin="dense"
                     label={formError === 'tenantName' ? 'Please enter the name for tenant' : 'Tenant Name'}
                     error={formError === 'tenantName' || formError === 'tenantNameLength'}
                     variant="outlined"
                     value={newTenantName}
                     onChange={(e) => setnewTenantName(e.target.value)}
                     onBlur={handleBlur}
                     fullWidth
                  />
                  <FormHelperText error>
                     {formError === 'tenantNameLength' &&
                        maxTenantnameLength &&
                        `Tenant Name is exceeding the limit of ${maxTenantnameLength}`}
                     {formError === 'tenantName' &&
                        'Tenant Name should not contain Speacial characters or uppercase characters'}
                  </FormHelperText>
                  <SpaceBottom top="20" />
                  <FormControl error={formError === 'codeRepositoryId'}>
                     <FormLabel> Select Service Provider</FormLabel>
                     <SpaceBottom top="10" />
                     <RadioGroup name="radio-buttons-group" value={selectedRepoName}>
                        {Object.entries(listOfRepositoriesToDisplay).map(
                           ([key, { name: repoName, icon: repoIcon, availability }]) => (
                              <CenterAlignedDiv>
                                 <FormControlLabel
                                    onClick={() => handleSelectRepoClick(key as RepoType, configuredRepos[key])}
                                    value={key}
                                    control={<Radio />}
                                    disabled={!availability}
                                    label={radioImageHandler(repoIcon, repoName, availability)}
                                 />
                                 {!!configuredRepos[key] && <CheckCircle color="success" />}
                                 {!availability && (
                                    <Chip label="Coming soon" color="info" variant="outlined" icon={<Info />} />
                                 )}
                              </CenterAlignedDiv>
                           )
                        )}
                     </RadioGroup>
                     <FormHelperText error>
                        {formError === 'codeRepositoryId' && 'Please select the Service Provider'}
                     </FormHelperText>
                  </FormControl>
                  <SpaceBottom />
                  {loadingRepositoryGroups ? (
                     <Skeleton variant="rectangular" height={50} />
                  ) : (
                     <FormControl fullWidth error={formError === 'codeRepositoryGroupId'}>
                        <InputLabel> Repository Group</InputLabel>
                        <Select
                           value={selectedRepositoryGroupId}
                           label="Repository Group"
                           onChange={handleRepositoryGroupChange}
                        >
                           {repositoryGroups.map((group) => (
                              <MenuItem key={group.id} value={group.id}>
                                 {group.name}
                              </MenuItem>
                           ))}
                        </Select>
                        <FormHelperText error>
                           {formError === 'codeRepositoryGroupId' && 'Please select the Repository Group'}
                        </FormHelperText>
                     </FormControl>
                  )}
                  <SpaceBottom />
                  <FormControl error={formError === 'domainId'} fullWidth>
                     <InputLabel> Domain</InputLabel>
                     <Select value={selectedDomain} label="Domain" onChange={handleChangeDomain}>
                        {domains?.map((domain: DomainType, index) => (
                           <MenuItem
                              key={domain.id}
                              value={domain.id}
                              disabled={domain.status !== 'Verified'} // Disable if not verified
                           >
                              {domain.domainName} {domain.status !== 'Verified' ? '(Pending Verification)' : ''}
                           </MenuItem>
                        ))}
                     </Select>
                     <FormHelperText>{formError === 'domainId' && 'Please select the Domain'}</FormHelperText>
                  </FormControl>
                  {!isPatCreated && (
                     <div style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}>
                        <Tooltip
                           title={
                              <div
                                 style={{
                                    backgroundColor: '#f0f0f0',
                                    padding: '20px',
                                    borderRadius: '10px',
                                    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                                    width: '700px',
                                    height: '330px',
                                    border: '1px solid #e0e0e0',
                                 }}
                              >
                                 <div
                                    style={{
                                       display: 'flex',
                                       flexDirection: 'row',
                                       justifyContent: 'space-between',
                                       gap: '30px',
                                    }}
                                 >
                                    {/* Step 1: Create Personal Access Token */}
                                    <div style={{ flex: 1 }}>
                                       <h3
                                          style={{
                                             margin: 0,
                                             marginBottom: '16px',
                                             fontSize: '16px',
                                             fontWeight: 'bold',
                                             color: '#333',
                                          }}
                                       >
                                          Step 1: Create a Personal Access Token (PAT)
                                       </h3>
                                       <ul
                                          style={{
                                             paddingLeft: '20px',
                                             color: '#555',
                                             lineHeight: '1.75',
                                             fontSize: '16px',
                                          }}
                                       >
                                          <li>
                                             Sign in to your GitLab account and go to
                                             <a
                                                href="https://gitlab.com/-/user_settings/personal_access_tokens"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                   color: '#007bff',
                                                   textDecoration: 'underline',
                                                   marginLeft: '4px',
                                                }}
                                             >
                                                Personal Access Tokens page
                                             </a>
                                             .
                                          </li>
                                          <li>
                                             Click <strong>New Access Token</strong>.
                                          </li>
                                          <li>
                                             Name it <strong>GITLAB_MAINTAINER_TOKEN</strong> and set an expiration of{' '}
                                             <strong>30 days</strong>.
                                          </li>
                                          <li>
                                             Select the <strong>write_repository</strong> scope.
                                          </li>
                                          <li>
                                             Click <strong>Create Personal Access Token</strong> and copy the token.
                                          </li>
                                       </ul>
                                    </div>

                                    {/* Vertical Separator */}
                                    <div
                                       style={{
                                          width: '1px',
                                          backgroundColor: '#ccc',
                                          margin: '0 20px',
                                       }}
                                    />

                                    {/* Step 2: Add PAT to CI/CD Variables */}
                                    <div style={{ flex: 1 }}>
                                       <h3
                                          style={{
                                             margin: 0,
                                             marginBottom: '16px',
                                             fontSize: '16px',
                                             fontWeight: 'bold',
                                             color: '#333',
                                          }}
                                       >
                                          Step 2: Add the PAT to CI/CD Variables
                                       </h3>
                                       <ul
                                          style={{
                                             paddingLeft: '20px',
                                             color: '#555',
                                             lineHeight: '1.75',
                                             fontSize: '16px',
                                          }}
                                       >
                                          <li>
                                             Navigate to your
                                             <a
                                                href={`https://gitlab.com/groups/${selectedRepositoryGroupPath}/-/settings/ci_cd`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                style={{
                                                   color: '#007bff',
                                                   textDecoration: 'underline',
                                                   marginLeft: '4px',
                                                }}
                                             >
                                                Group CI/CD settings page
                                             </a>
                                             .
                                          </li>
                                          <li>
                                             Expand the <strong>Variables</strong> section.
                                          </li>
                                          <li>
                                             Click <strong>Add Variable</strong>.
                                          </li>
                                          <li>
                                             For the key, enter <strong>GITLAB_MAINTAINER_TOKEN</strong>.
                                          </li>
                                          <li>For the value, paste the PAT you created.</li>
                                          <li>
                                             Ensure <strong>Masked</strong> is checked, and click <strong>Save</strong>.
                                          </li>
                                       </ul>
                                    </div>
                                 </div>
                              </div>
                           }
                           placement="top"
                           arrow
                           componentsProps={{
                              tooltip: {
                                 sx: {
                                    bgcolor: 'transparent', // Keeps the tooltip background clean
                                 },
                              },
                           }}
                        >
                           <span
                              style={{
                                 color: '#007bff',
                                 textDecoration: 'underline',
                                 cursor: 'pointer',
                                 fontSize: '14px',
                                 fontWeight: '500',
                                 marginTop: '10px',
                              }}
                           >
                              Add a Personal Access Token - Manual step
                           </span>
                        </Tooltip>
                     </div>
                  )}
               </Container>
            </DialogContent>
            <Divider />
            <DialogActions>
               <Grid container columnSpacing={2} sx={{ marginRight: 2, marginBottom: 1, marginTop: 1 }}>
                  <Grid>
                     <Button variant="outlined" onClick={handleClose}>
                        Cancel
                     </Button>
                  </Grid>
                  <Grid>
                     <Button
                        color="primary"
                        variant="contained"
                        onClick={handleAddTenant}
                        disabled={addTenentAPILoading || !isPatCreated}
                     >
                        Create
                     </Button>
                  </Grid>

                  {addTenentAPILoading && (
                     <CircularProgress color="primary" size={24} sx={{ marginTop: '6px', marginLeft: '8px' }} />
                  )}
               </Grid>
            </DialogActions>
         </Dialog>
         <ReviewModal
            openReviewModal={openReviewModal.status}
            setOpenReviewModal={setOpenReviewModal}
            handleAction={() => handleSignIn(openReviewModal.message as RepoType)}
            message={`This will redirect you to the provider's authorization page, do you wish to proceed?`}
         />
      </React.Fragment>
   )
}

export default CreateTenantDialog
