// FrontendApplication.tsx

import React from 'react';
import { TableCell } from '@mui/material';
import { OpenInNew } from '@mui/icons-material';
import { UrlContainer, UrlItem, UrlLabel, UrlLink } from './UrlStyles'; // Assuming you have these styled components
import { truncateString } from '../../components/helpers/stringFns';

interface FrontendApplicationProps {
  app: any; // Replace 'any' with the appropriate type
}

const FrontendApplication: React.FC<FrontendApplicationProps> = ({ app }) => {
  return (
    <TableCell align="center">
      <UrlContainer>
        {/* Application URL */}
        {app?.appAttributes?.appUrl && (
          <UrlItem>
            <UrlLabel>Application Url:</UrlLabel>
            <UrlLink onClick={() => window.open(app.appAttributes?.appUrl)}>
              {truncateString(app.appAttributes?.appUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </UrlLink>
          </UrlItem>
        )}

        {/* Repository Url */}
        {app?.appAttributes?.repositoryUrl && (
          <UrlItem>
            <UrlLabel>Repository Url:</UrlLabel>
            <UrlLink onClick={() => window.open(app.appAttributes?.repositoryUrl)}>
              {truncateString(app.appAttributes?.repositoryUrl, 35)}
              <OpenInNew style={{ fontSize: '15px', marginLeft: '4px' }} />
            </UrlLink>
          </UrlItem>
        )}
      </UrlContainer>
    </TableCell>
  );
};

export default FrontendApplication;
