import { ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material'
import React from 'react'
export type MenuItem = {
   name: string
   onClickFn: () => any
   disabled: boolean
   icon: JSX.Element
   status?: string
}
type MoreinfoType = {
   anchorEl: any
   open: boolean
   handleCloseMenu: () => void
   menuItems: MenuItem[]
}

const MoreInfoMenu = ({ anchorEl, handleCloseMenu, menuItems, open }: MoreinfoType) => {
   return (
      <Menu
         key={anchorEl}
         anchorEl={anchorEl}
         open={open}
         onClose={handleCloseMenu}
         anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
         transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
         }}
      >
         {menuItems.map((item) => (
            <MenuItem key={item.name} onClick={item.onClickFn} disabled={item.disabled}>
               <ListItemIcon>{item.icon}</ListItemIcon>
               <ListItemText>{item.name}</ListItemText>
            </MenuItem>
         ))}
      </Menu>
   )
}

export default MoreInfoMenu
