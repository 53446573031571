import { Routes, Route, useNavigate, Navigate } from 'react-router-dom'
import { routes as appRoutes } from './routes'
import NotFound from './pages/NotFound'
import Sidebar from './components/Sidebar/Sidebar'
import Header from './components/Common/Header'
import { useEffect, useState } from 'react'
import { theme } from './styles/theme'
import JoyRideContainer from './components/Extras/Tutorials/JoyRideContainer'
import styled from 'styled-components'
import SimpleLoadingScreen from './components/Common/SimpleLoadingScreen'
import { useAuth0 } from '@auth0/auth0-react'
import { AppProvider } from './components/Common/AppContext'
import { AlertProvider } from './components/Extras/Alerts/AlertProvider'
import { createTheme, ThemeProvider } from '@mui/material'

const WrappedApp = () => {
   const [isCollapsed, setIsCollapsed] = useState(false)
   const { isLoading, isAuthenticated, user } = useAuth0()
   const navigate = useNavigate()
   const onClickFn = () => {
      setIsCollapsed(!isCollapsed)
   }
   const muiTheme = createTheme(theme.mui)

   useEffect(() => {
      console.log('user?.sub', user, isAuthenticated)

      // if (!user?.sub) {
      //    navigate('/')
      // }
   }, [])

   return (
      <ThemeProvider theme={muiTheme}>
         <AppProvider>
            <JoyRideContainer />
            {isLoading ? (
               <SimpleLoadingScreen />
            ) : (
               <div className="app-container">
                  <AlertProvider>
                     <Header onClickFn={onClickFn} />
                     <div className="page-layout">
                        <Sidebar />
                        <Container>
                           <Routes>
                              {appRoutes.map((route) => (
                                 <Route key={route.key} path={route.path} element={<route.component />} />
                              ))}
                              <Route path="*" element={<NotFound />}></Route>
                           </Routes>
                        </Container>
                     </div>
                  </AlertProvider>
               </div>
            )}
         </AppProvider>
      </ThemeProvider>
   )
}

export default WrappedApp
const Container = styled.div`
   padding: 20px;
   background-color: ${theme.colors.white};
   overflow-y: auto;
   height: calc(99vh - 92px);
   width: 100%;
`
