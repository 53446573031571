import React, { useState, useEffect } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import SimpleLoadingScreen from '../../../components/Common/SimpleLoadingScreen'
import { PageContainer, PageContainerApp, PageSubTitle, PageTitle } from '../../Devops/CommonStyles'
import { Button, Grid, Tooltip } from '@mui/material'
import styled from 'styled-components'
import { convertHexToRGBA, theme } from '../../../styles/theme'
import SnackbarNotification from '../../../components/Common/SnackbarNotification'
import { useTenantApiClient } from '../../../Configs/API/createAxiosConfig'
import { listOfRepositoriesToDisplay } from './ListOfRepository'
import useAlert from '../../../components/Extras/Alerts/useAlert'

// Define repository types explicitly
export type RepoType = 'GITLAB_SAAS' | 'GITLAB_SELF' | 'BITBUCKET' | 'GITHUB'

// Corrected RepositoryStatus to be an indexable type
export interface RepositoryStatus {
   [key: string]: boolean
}

const Repositories: React.FC = () => {
   const tenantService = useTenantApiClient()
   const { user } = useAuth0()
   const [loading, setLoading] = useState<boolean>(true)
   const [configuredRepos, setConfiguredRepos] = useState<RepositoryStatus>({})
   const [error, setError] = useState<string | null>(null)
   const { showAlert } = useAlert()

   const fetchConfigurations = async () => {
      try {
         const response = await tenantService.getRepositories()
         if (response.status == 200) {
            const reposConfigured = response.data.reduce(
               (acc: RepositoryStatus, repo: { repositoryType: RepoType }) => {
                  acc[repo.repositoryType] = true
                  return acc
               },
               {}
            )

            setConfiguredRepos(reposConfigured)
         } else {
            showAlert(response?.response?.data?.errorMessage || 'Failed to repositorys', {
               header: response?.response?.data?.message,
            })
            setError('Failed to fetch repository configurations')
         }
      } catch (err) {
         setError('Failed to fetch repository configurations')
         showAlert('Failed to fetch repository configurations', { extraInfo: 'Please try again later' })
      } finally {
         setLoading(false)
      }
   }

   useEffect(() => {
      document.title = 'COCREATE | Repositories'
      fetchConfigurations()
   }, [user])

   const handleSignIn = (repoType: RepoType) => {
      if (user?.sub) {
         window.location.href = `${import.meta.env.VITE_VAR_TMS_BASE_URL}/oauth?client_id=${encodeURIComponent(
            user.sub
         )}&repository_type=${repoType}`
      } else {
         setError('User authentication required.')
      }
   }

   if (loading) {
      return <SimpleLoadingScreen />
   }

   return (
      <PageContainer>
         <PageContainerApp>
            <div style={{ padding: '0px 40px' }}>
               <PageTitle>Repositories</PageTitle>
               <PageSubTitle>
                  Authorize your Git repositories to allow COCREATE to automatically set up your applications.
               </PageSubTitle>
            </div>
            <Grid container spacing={6} style={{ padding: '1px 40px' }}>
               {Object.entries(listOfRepositoriesToDisplay).map(([key, { name, icon, description, availability }]) => {
                  return (
                     <Grid item xs={12} sm={12} md={6} lg={6} key={key} style={{ background: 'transparent' }}>
                        <RepositoryBox>
                           <img
                              src={icon}
                              alt={name}
                              style={{ width: 60, height: 60, opacity: availability ? '100%' : '50%' }}
                           />
                           <h5>{name}</h5>
                           <p>{description}</p>

                           <span>
                              <Button
                                 color="primary"
                                 variant="contained"
                                 disabled={!!configuredRepos[key] || !availability}
                                 onClick={() => handleSignIn(key as RepoType)}
                              >
                                 {availability
                                    ? !!configuredRepos[key]
                                       ? 'Configured'
                                       : 'Configure'
                                    : 'Coming Soon '}
                              </Button>
                           </span>
                        </RepositoryBox>
                     </Grid>
                  )
               })}
            </Grid>
            <div></div>
            {error && (
               <SnackbarNotification
                  open={error != null ? true : false}
                  autoHideDuration={6000}
                  onClose={() => setError(null)}
                  type="error"
                  alertTitle="Error"
                  alertText={error}
               />
            )}
         </PageContainerApp>
      </PageContainer>
   )
}

export default Repositories

const RepositoryBox = styled.div`
   border: 1px solid ${theme.colors.boxBorder};
   padding: 20px 25px 25px 25px;
   margin: 1px;
   text-align: center;
   border-radius: 8px;
   box-shadow: 0 1px 2px ${convertHexToRGBA(theme.colors.dark, 15)};
   height: 180px; // Fixed height for the box

   & h5 {
      font-size: 16px;
   }
   & p {
      margin-top: 1px;
      font-size: 13px;
      color: ${theme.colors.font2};
   }
   & button {
      margin-top: 4px;
      text-transform: none;
   }
`
