import { FC } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from '@mui/material'
import styled from 'styled-components'

export interface AlertProps {
   show: boolean
   message: string
   hideAlert: () => void
   callback?: () => void
   header?: string
   buttonText?: string
   extraInfo?: string
   linkClick?: () => void
}

const Alert: FC<AlertProps> = ({ show, message, hideAlert, callback, header, buttonText, extraInfo, linkClick }) => {
   const handleAgree = async () => {
      await hideAlert()

      if (callback) callback()
   }

   return (
      <Dialog
         open={show}
         onClose={handleAgree}
         PaperProps={{
            style: { width: '60vw', maxHeight: '70vh', borderRadius: 10 },
            component: 'form',
         }}
         scroll={'paper'}
         aria-labelledby="scroll-dialog-title"
         aria-describedby="scroll-dialog-description"
      >
         <DialogContent
            style={{ justifyContent: 'center', display: 'flex', flexDirection: 'column', alignContent: 'center' }}
         >
            <ErrorIcon src="static/images/error404.png" />
            <Typography variant="h6" gutterBottom style={{ textAlign: 'center' }}>
               {header?.substring(0, 270) || 'Something Went Wrong'}
            </Typography>
            <MessageText>{message === 'Error' ? '' : message?.substring(0, 300)}</MessageText>
            <MessageText2>{extraInfo?.substring(0, 380)}</MessageText2>
         </DialogContent>

         <DialogActions>
            <Grid container columnSpacing={2} sx={{ margin: '10px auto', justifyContent: 'center' }}>
               <Grid>
                  <Button variant="contained" color="primary" onClick={handleAgree}>
                     {buttonText || 'CLOSE'}
                  </Button>
               </Grid>
            </Grid>
         </DialogActions>
      </Dialog>
   )
}

export default Alert
const ErrorIcon = styled.img`
   //    height: 130px;
   width: 400px;
   margin: 10px auto;
`

const MessageText = styled(Typography)`
   margin-top: 10px;
   color: #333;
   font-size: 16px;
   text-align: center;
`

const MessageText2 = styled(Typography)`
   margin-top: 8.5px;
   color: #333;
   font-size: 15px;
   text-align: center;
`

const StyledButton = styled(Button)`
   margin-top: 20px;
   background-color: #e74c3c;
   color: #fff;
   &:hover {
      background-color: #c0392b;
   }
`
